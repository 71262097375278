// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';
import { 
    Table,
    Media,
} from 'reactstrap';
// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';


export default function AccountLayout(props) {
    const { match, location } = props;

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    const links = [
        { title: 'Dashboard', url: 'dashboard' },
        // { title: 'Edit Profile', url: 'profile' },
        { title: 'Histori Pemesanan', url: 'orders' },
        // { title: 'Order Details', url: 'orders/' },
        { title: 'Alamat', url: 'addresses' },
        // { title: 'Edit Address', url: 'addresses/' },
        { title: 'Password', url: 'password' },
        // { title: 'Logout', url: 'login' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url}>{link.title}</Link>
            </li>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                {/* <h4 className="account-nav__title">Navigation</h4> */}
                                {/* <ul>{links}</ul> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard} />
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/orders/:orderId`} component={AccountPageOrderDetails} />
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                <Route exact path={`${match.path}/addresses/:addressId`} component={AccountPageEditAddress} />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                            </Switch>
                        </div>
                    </div>
                    <Table className="align-items-center bg-white d-md-none d-lg-none d-xl-none d-lg-block d-none" responsive>
          {/* <thead className="thead-light">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead> */}
              <tbody>
                <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                    
                        <i class="fa fa-user fa-2x" aria-hidden="true"></i>
                        <a
                        class="nav-link"
                        href="/accounts/dashboard"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Akun 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                  <td className="text-right"><i className="fa fa-angle-right" />
                  </td>
                </tr>
                {/* <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                        <i class="fa fa-history fa-2x" aria-hidden="true"></i>
                      <a
                        class="nav-link"
                        href="/mobile/log-akses"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Komisi 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                  <td className="text-right">
                    <i className="fa fa-angle-right" />
                  </td>
                </tr> */}
                {/* <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                        <i className="fa fa-unlock-alt fa-2x" aria-hidden="true"></i>
                      <a
                        class="nav-link"
                        href="/mobile/profile/new-password"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Password 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                  <td className="text-right"><i className="fa fa-angle-right" />
                  </td>
                </tr> */}
                {/* <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                        <i class="fa fa-history fa-2x" aria-hidden="true"></i>
                      <a
                        class="nav-link"
                        href="/mobile/log-akses"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Pesanan 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                  <td className="text-right">
                    <i className="fa fa-angle-right" />
                  </td>
                </tr> */}
                {/* <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                        <i class="fa fa-history fa-2x" aria-hidden="true"></i>
                      <a
                        class="nav-link"
                        href="/mobile/log-akses"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Aplikator 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                  <td className="text-right">
                    <i className="fa fa-angle-right" />
                  </td>
                </tr>
                 */}
                {/* <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                      
                        <i class="fa fa-history fa-2x" aria-hidden="true"></i>
                      <a
                        class="nav-link"
                        href="/mobile/log-akses"
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Log Akses 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                  <td className="text-right">
                    <i className="fa fa-angle-right" />
                  </td>
                </tr> */}
                <tr>
                  <th scope="row">
                    <Media className="align-items-center">
                        <i className="fas fa-sign-out-alt fa-2x" />
                      <a
                        class="nav-link"
                        onClick={() => handleLogout()}
                      >
                      <Media>
                        <span className="mb-0 text-sm">
                          Keluar 
                        </span>
                      </Media>
                      </a>
                    </Media>
                  </th>
                  <td className="text-right"><i className="fa fa-angle-right" />
                  </td>
                </tr>
              </tbody>
            </Table>
                </div>
            </div>
        </React.Fragment>
    );
}
