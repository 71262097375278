// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, cartUpdateQuantities } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { url } from '../../services/utils';
import Collapse from '../shared/Collapse';


// data stubs
import theme from '../../data/theme';

import { toast } from 'react-toastify';
import { updateAction } from '../../store/auth/authActions';
function historyFunc(Component) {
    function historyFunc(props) {
        let history = useHistory()
        return <Component history={history} {...props} />
    }
    return historyFunc

}
function dispatchFunc(Component) {
    function dispatchFunc(props) {
        let dispatch = useDispatch()
        return <Component dispatch={dispatch} {...props} />
    }
    return dispatchFunc

}

class TransactionPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            cart: false,
            totalPrice: 0,
            cart_id: [],
            payment: "BANK_TRANSFER",
            loadingCO: false,
            paid: false,
            address_id: false,
            items: []
        };
    }


    checkoutHandler = async () => {
        const { token, history, dispatch, } = this.props;
        const user_code = localStorage.getItem('user_code');

        let param = {
            user_code: user_code,
            items: this.state.items
        }

        try {
            this.setState({ loadingCO: true });
            const result = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST_API}/transaction/payment`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: param,
            });
            this.setState({ loadingCO: false });
            console.log(result.data.response);
            localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
            localStorage.setItem('transaction_id', result.data.response.id);
            toast.success(`${result.data.message}`);
            this.setState({ paid: true });
            dispatch(updateAction(true));
            setTimeout(() => {
                dispatch(updateAction(false));
            }, 1000);
            history.push('/shop/payment');
        } catch (error) {
            this.setState({ loadingCO: false });
            console.log(error);
            toast.error(error.message);
        }

    }


    componentDidMount() {
        const { token, updateStatus } = this.props
        const getCart = async () => {
            const result = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_HOST_API}/sales-order/list`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(result.data?.response);
            this.setState({ cart: result.data?.response });
            this.setState({ cart_id: result.data?.response.map((item) => item.id) });
            console.log(result.data?.response.map((item) => item.id));
            const totalPrice = result.data?.response.map((item) => item.price_total);
            // console.log(totalPrice);
            let sum = 0;

            for (let i = 0; i < totalPrice.length; i++) {
                sum += totalPrice[i];
            }
            this.setState({ totalPrice: sum });
            const productQuantity = result.data?.response.map((item) => item.qty_total);

            let qty = 0;
            for (let i = 0; i < productQuantity.length; i++) {
                qty += productQuantity[i];
            }


            const listSO = result.data.response.map(({ id }) => {
                return { id: id }
            });

            this.setState({ quantities: qty });
            this.setState({ items: listSO });
        };
        if (token || updateStatus) {
            getCart();
        }
    }

    renderItems() {
        // const {  cartRemoveItem } = this.props;
        const { token, dispatch } = this.props

        const { cart } = this.state
        return cart.map((item) => {
            const formatRupiah = (money) => {
                return new Intl.NumberFormat('id-ID',
                    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
                ).format(money);
            }

            return (

                <tr key={item.id} className="cart-table__row">
                    <td colSpan={4}>
                        <h6>{item.toko_name} ({item.so_code})</h6>
                        <table width="100%">
                            {item.items.map(
                                column =>
                                    <tr className="cart-table__row">
                                        <td className="cart-table__column cart-table__column--product">
                                            <Link to={url.product(column.item_id)} target='_blank' className="cart-table__product-name">
                                                {column.item_name} - {column.item_code}
                                            </Link>
                                        </td>
                                        <td className="cart-table__column cart-table__column--price" data-title="Price">
                                            {formatRupiah(column.harga)}
                                        </td>
                                        <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                            {column.qty}
                                        </td>
                                        <td className="cart-table__column cart-table__column--total" data-title="Total">
                                            {formatRupiah(column.sub_total)}
                                        </td>
                                    </tr>
                            )
                            }

                        </table>
                    </td>
                </tr>
            );
        });
    }

    renderCart() {
        const { loadingCO } = this.state;

        const checkoutButton = (
            <AsyncAction
                action={
                    this.checkoutHandler}
                render={({ run, loading }) => {
                    const classes = classNames('btn btn-primary btn-xl btn-block cart__checkout-button', {
                        'btn-loading': loadingCO,
                    });

                    return (
                        <button type="button" onClick={this.checkoutHandler} className={classes} disabled={loadingCO}>
                            Payment
                        </button>
                    );
                }}
            />
        );
        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column"></th>
                                <th style={{ paddingRight: 2 }} className="cart-table__column cart-table__column--price">Harga Satuan</th>
                                <th style={{ paddingRight: 22 }} className="cart-table__column cart-table__column--quantity">Qty</th>
                                <th style={{ paddingRight: 18 }} className="cart-table__column cart-table__column--total">Total</th>
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>
                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Total Pembayaran</h3>
                                    <table className="cart__totals">
                                        {this.renderQty()}
                                        {this.renderTotals()}

                                    </table>
                                    {checkoutButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }



    renderTotals() {
        const { cart, totalPrice } = this.state;


        if (cart.length <= 0) {
            return null;
        }
        const formatRupiah = (money) => {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
        }
        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>{formatRupiah(totalPrice)}</td>
                    </tr>
                </thead>
                {/* <tbody className="cart__totals-body">
                    {extraLines}
                </tbody> */}
            </React.Fragment>
        );
    }

    renderQty() {
        const { cart, quantities } = this.state;


        if (cart.length <= 0) {
            return null;
        }
        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Total Qty</th>
                        <td>{quantities} produk</td>
                    </tr>
                </thead>
            </React.Fragment>
        );
    }

    render() {
        const { cart } = this.state;
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Transaction', url: '' },
        ];

        let content;

        if (cart.length > 0) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">Keranjangmu Kosong, yuk belanja dulu!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">Belanja</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Transaction — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Transaction" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    token: state.auth.token,
    updateStatus: state.auth.update
});

export default connect(mapStateToProps)(dispatchFunc(historyFunc(TransactionPage)));
