// react
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './ProductTabs';
import shopApi from '../../api/shop';
import { url } from '../../services/utils';

// blocks
import BlockLoader from '../blocks/BlockLoader';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// widgets
import WidgetCategories from '../widgets/WidgetCategories';
import WidgetProducts from '../widgets/WidgetProducts';

// data stubs
import categories from '../../data/shopWidgetCategories';
import theme from '../../data/theme';

function ShopPageProduct(props) {
    const { productSlug, layout, sidebarPosition , description} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);

    // Load product.
    useEffect(() => {
        // let canceled = false;

        setIsLoading(true);

        const getProductById = async ()=>{
            try {
                const result = await axios.get(`${process.env.REACT_APP_HOST_API}/items-searching/get/${productSlug}`)
                setProduct(result.data.response)
                // console.log(result.data.data);
                setIsLoading(false)
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }

        // shopApi.getProductBySlug(productSlug).then((product) => {
        //     if (canceled) {
        //         return;
        //     }

        //     setProduct(product);
        // });
        getProductById()
        // return () => {
        //     canceled = true;
        // };
    }, [productSlug, setIsLoading]);

    // Load related products.
    useEffect(() => {
        let canceled = false;

        shopApi.getRelatedProducts(productSlug, { limit: 8 }).then((products) => {
            if (canceled) {
                return;
            }
            // console.log(products);
            setRelatedProducts(products.data.response);
        });

        return () => {
            canceled = true;
        };
    }, [productSlug, setRelatedProducts]);

    // Load latest products.
    useEffect(() => {
        let canceled = false;

        if (layout !== 'sidebar') {
            setLatestProducts([]);
        } else {
            shopApi.getLatestProducts({ limit: 5 }).then((result) => {
                if (canceled) {
                    return;
                }
                setLatestProducts(result);
            });
        }

        return () => {
            canceled = true;
        };
    }, [layout]);

    if (isLoading) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: 'Home', url: url.home() },
        { title: 'Shop', url: url.catalog() },
        { title: product.item_name, url: url.product(product && product.id) },
    ];

    let content;

    if (layout === 'sidebar') {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={latestProducts} />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === 'start' && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={product} layout={layout} />
                            <ProductTabs description={product} withSidebar />
                        </div>

                        {relatedProducts.length > 0 && (
                            <BlockProductsCarousel
                                title="Related Products"
                                // layout="grid-4-sm"
                                layout="grid-5"
                                rows={2}
                                products={relatedProducts}
                                withSidebar
                            />
                        )}
                    </div>
                    {sidebarPosition === 'end' && sidebar} 
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product product={product} layout={layout} />
                        {/* <ProductTabs /> */}
                    </div>
                </div>

                {relatedProducts.length > 0 && (
                    <BlockProductsCarousel
                        title="Related Products"
                        // layout="grid-5"
                        layout="grid-4"
                        rows={2}
                        products={relatedProducts}
                    />
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product && product.item_name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ShopPageProduct.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

export default ShopPageProduct;
