export default [
    {
        type: 'link',
        label: 'Home',
        url: '/',
    },
    {
        type: 'link',
        label: 'Kategori',
        url: '',
        children: [
            {
                type: 'link',
                label: 'Peralatan',
                url: '',
            },
            {
                type: 'link',
                label: 'Kamar Mandi',
                url: '',
            },
            {
                type: 'link',
                label: 'Bahan Bangunan',
                url: '',
            },
            {
                type: 'link',
                label: 'Pintu & Jendela',
                url: '',
            },
            {
                type: 'link',
                label: 'Listrik & Penerangan',
                url: '',
            },
            {
                type: 'link',
                label: 'Lantai & Dinding',
                url: '',
            },
            {
                type: 'link',
                label: 'Hobi',
                url: '',
            },
            {
                type: 'link',
                label: 'Peralatan Rumah Tangga',
                url: '',
            },
            {
                type: 'link',
                label: 'Dapur',
                url: '',
            },
            {
                type: 'link',
                label: 'Cat',
                url: '',
            },
        ],
    },

    {
        type: 'link',
        label: 'Shop',
        url: '/shop/cart',
    },

    {
        type: 'link',
        label: 'Account',
        url: '/account/profile',
        children: [
            { type: 'link', label: 'Login', url: '/account/login' },
            { type: 'link', label: 'Dashboard', url: '/account/dashboard' },
            { type: 'link', label: 'Edit Profile', url: '/account/profile' },
            { type: 'link', label: 'Order History', url: '/account/orders' },
            { type: 'link', label: 'Order Details', url: '/account/orders/5' },
            { type: 'link', label: 'Address Book', url: '/account/addresses' },
            { type: 'link', label: 'Edit Address', url: '/account/addresses/5' },
            { type: 'link', label: 'Change Password', url: '/account/password' },
        ],
    },

    // {
    //     type: 'link',
    //     label: 'Blog',
    //     url: '/blog/category-classic',
    //     children: [
    //         { type: 'link', label: 'Blog Classic', url: '/blog/category-classic' },
    //         { type: 'link', label: 'Blog Grid', url: '/blog/category-grid' },
    //         { type: 'link', label: 'Blog List', url: '/blog/category-list' },
    //         { type: 'link', label: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
    //         { type: 'link', label: 'Post Page', url: '/blog/post-classic' },
    //         { type: 'link', label: 'Post Without Sidebar', url: '/blog/post-full' },
    //     ],
    // },

    // {
    //     type: 'link',
    //     label: 'Pages',
    //     url: '/site/about-us',
    //     children: [
    //         { type: 'link', label: 'About Us', url: '/site/about-us' },
    //         { type: 'link', label: 'Contact Us', url: '/site/contact-us' },
    //         { type: 'link', label: 'Contact Us Alt', url: '/site/contact-us-alt' },
    //         { type: 'link', label: '404', url: '/site/not-found' },
    //         { type: 'link', label: 'Terms And Conditions', url: '/site/terms' },
    //         { type: 'link', label: 'FAQ', url: '/site/faq' },
    //         { type: 'link', label: 'Components', url: '/site/components' },
    //         { type: 'link', label: 'Typography', url: '/site/typography' },
    //     ],
    // },

    // {
    //     type: 'button',
    //     label: 'Currency',
    //     children: [
    //         { type: 'button', label: '€ Euro', data: { type: 'currency', code: 'EUR' } },
    //         { type: 'button', label: '£ Pound Sterling', data: { type: 'currency', code: 'GBP' } },
    //         { type: 'button', label: '$ US Dollar', data: { type: 'currency', code: 'USD' } },
    //         { type: 'button', label: '₽ Russian Ruble', data: { type: 'currency', code: 'RUB' } },
    //     ],
    // },

    // {
    //     type: 'button',
    //     label: 'Language',
    //     children: [
    //         { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
    //         { type: 'button', label: 'Russian', data: { type: 'language', locale: 'ru' } },
    //     ],
    // },
];
