// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import { useEffect } from 'react';

export default function PengajuanKomisiSukses() {
    const[orderInfo, setInfo] = useState(false)
    const items = order.items.map((item) => {
        const options = (item.options || []).map((option) => (
            <li className="order-list__options-item">
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));
        useEffect(()=>{
            setInfo(JSON.parse(localStorage.getItem('CO-INFO')))
            
        },[])
        // console.log(orderInfo)
        return (
            <tr>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <Link to={url.product(item)} className="product-image__body">
                            <img className="product-image__img" src={item.image} alt="" />
                        </Link>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <Link to={url.product(item)}>{item.name}</Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {options}
                            </ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title="Qty:">{item.quantity}</td>
                <td className="order-list__column-total"><Currency value={item.total} /></td>
            </tr>
        );
    });

    const additionalLines = order.additionalLines.map((line) => (
        <tr>
            <th className="order-list__column-label" colSpan="3">{line.label}</th>
            <td className="order-list__column-total"><Currency value={line.total} /></td>
        </tr>
    ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        {/* <h1 className="order-success__title">Thank you</h1> */}
                        <div className="order-success__subtitle"><h4>{orderInfo.wd_code}</h4></div><br></br>
                        <Check100Svg className="order-success__icon" />
                        <br></br>
                        <div className="order-success__subtitle"><font color='black'><center>Pencairan komisi membutuhkan waktu <br></br> <b>2 x 24 jam</b> <br></br>mohon bersabar ya kak </center></font></div>
                      </div>

                    {/* <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Kode Transaksi:</span>
                                <span className="order-success__meta-value">{`${orderInfo?.transaction_code}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Tanggal:</span>
                                <span className="order-success__meta-value">{`${orderInfo?.transaction_date}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total:</span>
                                <span className="order-success__meta-value"><Currency value={orderInfo?.transaction_total} /></span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Pembayaran:</span>
                                <span className="order-success__meta-value">{`${orderInfo?.bank_name}`}</span>
                                <span className="order-success__meta-value">{`${orderInfo?.account_number}`}</span>
                            </li>
                        </ul>
                    </div> */}
                    <div className="order-success__meta">
                    <div className="order-success__actions">
                            <Link to="/komisi-user" className="btn btn-xs btn-danger">Cek Status Pengajuan Komisi</Link>
                        </div>
                        {/* <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-warning">Pesan Aplikator Lagi yuk</Link>
                        </div> */}
                    </div>
                    

                   
                </div>
            </div>
        </div>
    );
}
