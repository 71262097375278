// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs
import departments from '../../data/headerDepartments';
import axios from 'axios';
import {useState} from 'react';
import { useEffect } from 'react';

function DepartmentsLinks() {
    // const [toko, setToko] = useState([])
    // useEffect(async ()=>{
    //     const result = await axios.get(`${process.env.REACT_APP_HOST_API}/kategori/list`)
    //     setToko(result.data.response)
    // }, [])
    const toko = [
        {
            name : "Peralatan",
            id : 1
        },
        {
            name : "Kamar Mandi",
            id : 2
        },
        {
            name : "Bahan Bangunan",
            id : 3
        },
        {
            name : "Pintu & Jendela",
            id : 4
        },
        {
            name : "Listrik & Penerangan",
            id : 5
        },
        {
            name : "Lantai & Dinding",
            id : 6
        },
        {
            name : "Hobi",
            id : 7
        },
        {
            name : "Peralatan Rumah Tangga",
            id : 8
        },
        {
            name : "Dapur",
            id : 9
        }, 
        {
            name : "Cat",
            id : 10
        },
        {
            name : "Semua Kategori",
            id : ""
        },
    ]
    const linksList = toko.map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';

        // if (department.submenu) {
        //     arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        // }

        // if (department.submenu && department.submenu.type === 'menu') {
        //     itemClass = 'departments__item--menu';
        //     submenu = (
        //         <div className="departments__menu">
        //             <Menu items={department.submenu.menu} />
        //         </div>
        //     );
        // }

        // if (department.submenu && department.submenu.type === 'megamenu') {
        //     submenu = (
        //         <div className={`departments__megamenu departments__megamenu--${department.submenu.menu.size}`}>
        //             <Megamenu menu={department.submenu.menu} location="department" />
        //         </div>
        //     );
        // }

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={`${department.id}`}>
                    {department.name}
                    {arrow}
                </Link>
                {/* {submenu} */}
            </li>
        );
    });

    return (
        <ul className="departments__links">
            {linksList}
        </ul>
    );
}

export default DepartmentsLinks;
