/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import qs from 'query-string';
import axios from 'axios';
import CategoryFilterBuilder from '../fake-server/filters/category';
import CheckFilterBuilder from '../fake-server/filters/check';
import RadioFilterBuilder from '../fake-server/filters/range';
import ColorFilterBuilder from '../fake-server/filters/color';
import RangeFilterBuilder from '../fake-server/filters/price';

import { getCategories, getCategoryBySlug } from '../fake-server/endpoints/categories';
import {
    getDiscountedProducts,
    getFeaturedProducts,
    getLatestProducts,
    getPopularProducts,
    getProductBySlug,
    getProductsList,
    getRelatedProducts,
    getSuggestions, 
    getTopRatedProducts,
} from '../fake-server/endpoints/products';

const shopApi = {
    /**
     * Returns array of categories.
     *
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<Array<object>>}
     */
    getCategories: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories.json?depth=2
         *
         * where:
         * - 2 = options.depth
         */
        // return fetch(`https://example.com/api/categories.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());
        const result = await axios.post(`${process.env.REACT_APP_HOST_API}/warehouse/toko`, {
            page : 1,
            per_page : 10
        })
        if(result){
            return result.data.response
        }
        // This is for demonstration purposes only. Remove it and use the code above.
        // return getCategories(options);
    },
    /**
     * Returns category by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */
    getCategoryBySlug: async (categorySlug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/categories/power-tools.json?depth=2
         *
         * where:
         * - power-tools = slug
         * - 2           = options.depth
         */
        // return fetch(`https://example.com/api/categories/${slug}.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        const result = await axios.post(`${process.env.REACT_APP_HOST_API}/items-searching/page`, {
            page : 1,
            per_page : 100,
            kategori_id : categorySlug
        })
        return result.data.response
    },
    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @return {Promise<object>}
     */
    getProductBySlug: async (slug) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/screwdriver-a2017.json
         *
         * where:
         * - screwdriver-a2017 = slug
         */
        // return fetch(`https://example.com/api/products/${slug}.json`)
        //     .then((response) => response.json());

        // const product = 
        return await axios.get(`${process.env.REACT_APP_HOST_API}/items-searching/get/${slug}`)

        // return product.data.response
        // This is for demonstration purposes only. Remove it and use the code above.
        // return getProductBySlug(slug);
    },
    /**
     * Returns array of related products.
     *
     * @param {string}  slug
     * @param {object?} options
     * @param {number?} options.limit
     *
     * @return {Promise<Array<object>>}
     */
    getRelatedProducts: async (slug, options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
         *
         * where:
         * - screwdriver-a2017 = slug
         * - limit             = options.limit
         */
        // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());
        return await axios.post(`${process.env.REACT_APP_HOST_API}/items-searching/page`, {
            page : 1,
            per_page : 10
        })
        // This is for demonstration purposes only. Remove it and use the code above.
        // return getRelatedProducts(slug, options);
    },
    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */
    getProductsList: async ( categorySlug ,options = {}, filters = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
         *
         * where:
         * - page            = options.page
         * - limit           = options.limit
         * - sort            = options.sort
         * - filter_category = filters.category
         * - filter_price    = filters.price
         */
        // const params = { ...options };
        //
        // Object.keys(filters).forEach((slug) => {
        //     params[`filter_${slug}`] = filters[slug];
        // });
        //
        // return fetch(`https://example.com/api/products.json?${qs.stringify(params)}`)
        //     .then((response) => response.json());

        const result = await axios.post(`${process.env.REACT_APP_HOST_API}/items-searching/page`, {
            page : 1,
            per_page : 100,
            kategori_id : categorySlug
        })
        return result.data.response

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProductsList(options, filters);
    },
    /**
     * Returns array of featured products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getFeaturedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getFeaturedProducts(options);
    },
    /**
     * Returns array of latest products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getLatestProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/latest-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getLatestProducts(options);
    },
    /**
     * Returns an array of top rated products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
     getTopRatedProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        const result = await axios.post(`${process.env.REACT_APP_HOST_API}/price-detail/page`, {
            page : 1,
            per_page : 10
        })
        return getTopRatedProducts(options);
        // This is for demonstration purposes only. Remove it and use the code above.
        // return getDiscountedProducts(options);
    },
    /**
     * Returns an array of discounted products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getDiscountedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        return getDiscountedProducts(options);
    },
    /**
     * Returns an array of most popular products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getPopularProducts: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        const result = await axios.post(`${process.env.REACT_APP_HOST_API}/items-searching/page`, {
            page : 1,
            per_page : 150
        })
        return result.data.response

        // const result = await axios.get(`http://localhost:5000/product`)
        // return result.data.products

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getPopularProducts(options);
    },
    getBestsellerdown: async (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        const result = await axios.post(`${process.env.REACT_APP_HOST_API}/items-searching/page`, {
            page : 1,
            per_page : 3
        })
        return result.data.response

        // const result = await axios.get(`http://localhost:5000/product`)
        // return result.data.products

        // This is for demonstration purposes only. Remove it and use the code above.
        // return getPopularProducts(options);
    },
    /**
     * Returns search suggestions.
     *
     * @param {string}  query
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getSuggestions: async (query, warehouse_id, withFilter = false) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query    = query
         * - limit    = options.limit
         * - category = options.category
         */
        // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
        //     .then((response) => response.json());

        // This is for demonstration purposes only. Remove it and use the code above.
        const result = await axios.post(`${process.env.REACT_APP_HOST_API}/items-searching/page`, {
            page : 1,
            per_page : 10,
            item_name : query,
            warehouse_id: warehouse_id
        })

        if(withFilter)
        {
            const filters = [
                new CategoryFilterBuilder('category', 'Categories'),
                new RangeFilterBuilder('price', 'Price'),
                new CheckFilterBuilder('brand', 'Brand'),
                new RadioFilterBuilder('discount', 'Discount'),
                new ColorFilterBuilder('color', 'Color'),
            ];

            let filterValues = {}

            let items = result.data.response.slice(0);

            filters.forEach((filter) => filter.makeItems(items, filterValues[filter.slug]));

            // Calculate items count for filter values.
            filters.forEach((filter) => filter.calc(filters));
            
            const page = 1;
            const limit = result.data.total_item;
            const sort = 'default';
            const total = items.length;
            const pages = Math.ceil(total / limit);
            const from = (page - 1) * limit + 1;
            const to = Math.max(Math.min(page * limit, total), from);

            items = items.sort((a, b) => {
                if (['name_asc', 'name_desc'].includes(sort)) {
                    if (a.name === b.name) {
                        return 0;
                    }

                    return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
                }

                return 0;
            });

            const start = (page - 1) * limit;
            const end = start + limit;

            items = items.slice(start, end);
            let response = {
                page,
                limit,
                sort,
                total,
                pages,
                from,
                to,
                items,
                filters: filters.map((x) => x.build()),
            }


            return response;
        }
        else {
            return result.data.response
        }
        // const result = await axios.get(`http://localhost:5000/product`)
        // return result.data.products
        // return getSuggestions(query, options);
    }
};

export default shopApi;
