// react
import React from 'react';

// application
// import PropTypes from 'prop-types';
import MetodePembayaran from '../shared/MetodePembayaran';
import SocialLinks from '../shared/SocialLinks';

export default function FooterPembayaran(props) {
    const { title } = props;
    return (
        <div className="site-footer__widget footer-links">
            <h5 className="footer-links__title">{title}</h5>
            <MetodePembayaran shape="square" />
            {/* <ul className="footer-links__list">
                {linksList}
            </ul> */}
             <div className="footer-newsletter__text footer-newsletter__text--social">
                Ikuti Sosial Media Kami
            </div>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
        
        // <div className="site-footer__widget footer-newsletter">
        //     <div className="footer-newsletter__text footer-newsletter__text--social">
        //         Metode Pembayaran
        //     </div>

            
        // </div>
    );
}
