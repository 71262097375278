// react
import React,{useEffect, useState} from "react";

// third-party
import { Link } from 'react-router-dom';
import { url } from '../../services/utils';
import axios from 'axios';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import brands from '../../data/kategori';


export default function BlockKategori(props) {

	useEffect(() => {
		getBankList()
	}, [])
    const {  token } = props;
	const [listbank, setListBank] = useState([]) 
	
	const getBankList = async () => {
		const result = await axios({
			method: "GET",
			url: `${process.env.REACT_APP_HOST_API}/kategori/list`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		setListBank(result.data.response);
	};
// console.log(listbank);
	const {kategori} = props;
const categories = [
	{
		id : 1,
		name: "Alat Pertukangan",
		image: "https://i.imgur.com/L4lEefh.png"
	},
	{
		id : 7,
		name: "Kamar Mandi",
		image: "https://i.imgur.com/pqd1rT4.png",
	},
	{
		id : 2,
		name: "Bahan Bangunan",
		image: "https://i.imgur.com/5UUNJvv.png",
	},
	{
		id : 11,
		name: "Pintu & Jendela",
		image: "https://i.imgur.com/pwGuSK2.png",
	},
	{
		id : 9,
		name: "Listrik & Pencahayaan",
		image: "https://i.imgur.com/y8dx8Pi.png",
	},
	{
		id : 8,
		name: "Lantai & Dinding",
		image: "https://i.imgur.com/1f7Igja.png",
	},
	{
		id : 5,
		name: "Hardware",
		image: "https://i.imgur.com/Thp8H1P.png",
	},
	{
		id : 6,
		name: "Hobi",
		image: "https://i.imgur.com/YAxRd2X.png",
	},
	{
		id : 10,
		name: "Peralatan Rumah Tangga",
		image: "https://i.imgur.com/TrCkA76.png",
	},
	{
		id : 4,
		name: "Dapur",
		image: "https://i.imgur.com/ceWQOyW.png",
	},
	{
		id : 3,
		name: "Cat",
		image: "https://i.imgur.com/OShBtbL.png",
	},
	{
		id : 0,
		name: "All Category",
		image: "https://i.imgur.com/WOemlbH.png",
	},
];

const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 10,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 2000,
    cssEase: "ease-out",
    responsive: [        
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 100,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
};

    const brandsList = listbank.map((categories, index) => (
        
        <div key={index} className="block-brands__item"
		style={{
			alignItems: "center",
            fontSize: "12px",
			cursor: "pointer",
			rows:4,
			// border: "1px solid gainsboro",
			// display: "flex",
			// infinite: true,
			// cssEase: "linear",
			// flexDirection: "column",// size:"40px",
			// width: "100px",
			// height: "300px",
			// justifyContent: "space-between",
			// padding: "20px 10px",
			// width: "50px",
			// height: "50px",
			// border: "1px solid gainsboro",
			display: "flex",
			flexDirection: "column",
		}}
		>
		<Link to={url.category(categories.id)}>
				<img src={categories.gambar} alt="" width={70} height={70}
				/>
		</Link>
            <center>{categories.name}</center>
        </div>
    ));

	const brandsList2 = listbank.map((categories, index) => (
		<div class="col" style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			fontSize: "12px",
			cursor: "pointer",
		}}>
			<Link to={url.category(categories.id)}>
					<img src={categories.gambar} alt="" width={50} height={50}
					/>
			</Link>
		<font style={{
			fontSize:"10px"
		}}><center>{categories.name}</center></font>
		</div>


));

    return (
        <div className="block block-brands">
            <div className="container">
             <div style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "20px" }}>Kategori</div>
                <div className="block-brands__slider d-lg-block d-none">
                    <StroykaSlick {...slickSettings}>
						{brandsList}
                    </StroykaSlick>
                </div>
				<div class="row row-cols-5 d-md-none d-lg-none d-xl-none">
					{brandsList2}
				</div>
            </div>
        </div>
    );
}
