// react
import React, { useState } from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import { useDispatch, useSelector } from 'react-redux';
import { postLogin, resetAuth } from '../../store/auth/authActions';
import classNames from 'classnames';
import { useEffect } from 'react';
import axios from 'axios';

export default function IndicatorAccount() {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userData, setUserData] = useState(false)
  const dispatch = useDispatch();
  const { token, loading } = useSelector((state) => state.auth);
  const login = (e) => {
    e.preventDefault();
    const body = {
      usernameOrEmail,
      password,
    };
    dispatch(postLogin(body));
  };
  useEffect(()=>{
    const getUser = async () =>{
      try {
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_HOST_API}/auth/info`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(result.data?.response)
        localStorage.setItem('user_code', result.data?.response.user_code)
        localStorage.setItem('user_id', result.data?.response.id)
      } catch (error) {
        console.log(error);
        dispatch(resetAuth())
      }
    }
    if(token){
      getUser()
    }
  },[token])
  const dropdown = (
    <div className='account-menu'>
      {!token ? (
        <form className='account-menu__form' onSubmit={login}>
          <div className='account-menu__form-title'>Log In to Your Account</div>
          <div className='form-group'>
            <label htmlFor='header-signin-email' className='sr-only'>
              Email address
            </label>
            <input
              id='header-signin-email'
              type='text'
              className='form-control form-control-sm'
              placeholder='Email or Username'
              onChange={(e) => setUsernameOrEmail(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='header-signin-password' className='sr-only'>
              Password
            </label>
            <div className='account-menu__form-forgot'>
              <input
                id='header-signin-password'
                type='password'
                className='form-control form-control-sm'
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
              />
              <Link
                to='/account/login'
                className='account-menu__form-forgot-link'
              >
                Forgot?
              </Link>
            </div>
          </div>
          <div className='form-group account-menu__form-button'>
            <button
              type='submit'
              disabled={loading}
              className={classNames('btn btn-primary btn-sm', {
                'btn-loading': loading,
              })}
              onClick={login}
            >
              Login
            </button>
          </div>
          <div className='account-menu__form-link'>
            <Link to='/account/login'>Buat Account</Link>
          </div>
        </form>
      ) : (
        <>
          <div className='account-menu__divider' />
          <Link to='/account/dashboard' className='account-menu__user'>
            <div className='account-menu__user-avatar'>
              <img src={userData.user_image ? userData.user_image :'images/avatars/avatar-3.jpg'} alt='' />
            </div>
            <div className='account-menu__user-info'>
              <div className='account-menu__user-name'>{userData.name}</div>
              <div className='account-menu__user-email'>
                {userData.email}
              </div>
            </div>
          </Link>
          <div className='account-menu__divider' />
          <ul className='account-menu__links'>
            <li>
              <Link to='/account/dashboard'>Profile</Link>
            </li>
            <li>
              <Link to='/komisi-user'>Komisi</Link>
            </li>
            <li>
              <Link to='/accounts/order-produk'>Pesanan Produk</Link>
            </li>
            <li>
              <Link to='/aplikator/order-jasa'>Pesanan Jasa</Link>
            </li>
            <li>
              <Link to='/addendum/order-addendum'>Revisi Jasa</Link>
            </li>
            <li>
              <Link to='/account/addresses'>Alamat</Link>
            </li>
            <li>
              <Link to='/account/password'>Password</Link>
            </li>
          </ul>
          <div className='account-menu__divider' />
          <ul className='account-menu__links'>
            <li>
              <div
                onClick={() => dispatch(resetAuth())}
              >
                <p className='ml-4' style={{fontWeight: 'bold', cursor: 'pointer'}}>Logout</p>
              </div>
            </li>
          </ul>
        </>
      )}
    </div>
  );

  return (
    <Indicator url='/account' dropdown={dropdown} icon={<Person20Svg />} />
  );
}
