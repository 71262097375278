// react
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../../services/utils';
import { toast } from 'react-toastify';
import { Button } from "reactstrap";
// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';

export default function AccountPageAddresses() {
    const [addressData, setAddress] = useState(false)
    const {token} = useSelector((state) => state.auth)
    // console.log(addressData.length);

    const DeleteAddress = async ()=>{
        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/address/delete/${addressid}`,
                data:{
                    latitude : lat,
                     longitude : long, 
                     address : selectAddress, 
                     city : city, 
                     province : province, 
                     phone : phone,
                    // rv_state : centang === "" ? 3 : 5 ,
                    // account_number : bank
                    // warehouse_id: toko,
                    // code_aplikator : kode,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('Delete Address Sukses')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  History.push("/account/addresses")
            
        } catch (error) {
            toast.error('Delete Address Gagal')
            
        }
    }

    useEffect(()=>{
        const getAddress = async()=>{
            const user_id = localStorage.getItem('user_id')
            try {
                const result = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_HOST_API}/address/list`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                //   console.log(result.data?.response)
                  setAddress(result.data?.response)
            } catch (error) {
                console.log(error);
                toast.error('There was an error!')
            }
            
    } 
        getAddress()
    },[token])
    
    console.log(addressData);
    return (
        <div className="addresses-list">
            <Helmet>
                <title>{`Address List — ${theme.name}`}</title>
            </Helmet>

            {Array.isArray(addressData) && addressData.length <3 &&
            
            <Link to={Array.isArray(addressData) && addressData.length ===1 ? "/account/address-dua": addressData.length ===2 && "/account/address-ketiga" } className="addresses-list__item addresses-list__item--new">
                <div className="addresses-list__plus" />
                <div className="btn btn-secondary btn-sm">Tambah Alamat</div>
            </Link>
            }
            <div className="addresses-list__divider" />
            {Array.isArray(addressData) && addressData.map((address,idx) => (
        <React.Fragment key={idx}>
            <div className="addresses-list__item card address-card">
                {idx===0 && <div className="address-card__badge">Default</div>}

                <div className="address-card__body">
                    {/* <div className="address-card__name">{`${address.firstName} ${address.lastName}`}</div> */}
                    <div className="address-card__row">
                    {address.address_name}
                        
                        ,
                        { address.city_name}
                       ,
                        { address.province_name}
                    </div>
                    {/* <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{address.phone}</div>
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{address.email}</div>
                    </div> */}
                    <div className="address-card__footer">
                        <Link 
                        // to={`/account/addresses/${address.address_id}`}
                         to={url.addressid(address.address_id)}>Edit</Link>
                        &nbsp;&nbsp;
                        {/* <Button color="link" onClick={() => DeleteAddress(address.address_id)}>Remove</Button> */}
                    </div>
                </div>
            </div>
            <div className="addresses-list__divider" />
        </React.Fragment>
    ))}
        </div>
    );
}
