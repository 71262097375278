// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useLocation,useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

// application

// data stubs
import theme from '../../data/theme';

import classNames from 'classnames';
import { useEffect } from 'react';

export default function Registration() {
  let history = useHistory();
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [load, setLoad] = useState(false);
  const [gender, setGender] = useState(1);
  const [city, setCity] = useState(1101);
  const [province, setProvince] = useState(11);
  const [phone, setPhone] = useState('');
  const [refferal_code, setRefferalCode] = useState('');
  const [provinceData, setProvinceData] = useState('');
  const [cityData, setCityData] = useState([]);
  const { search } = useLocation();
  //   console.log(search.split('=')[1]);
  let key = search.split('=')[1];

  const regist = async (e) => {
    e.preventDefault();
    const body = {
      username,
      name,
      email,
      password,
      gender,
      address,
      city,
      province,
      phone,
      refferal_code,
    };
    console.log(body);
    try {
      if (
        !username ||
        !name ||
        !password ||
        !email ||
        !phone ||
        !address ||
        !gender ||
        !province ||
        !city
      ) {
        toast.error('Form cannot be empty!');
      } else {
        setLoad(true);

        // eslint-disable-next-line no-unused-vars
        const result = await axios.post(
          `${process.env.REACT_APP_HOST_API}/auth/register?key=${key}`,
          body
        );
        // console.log(result.data);
        setLoad(false);
        toast.success('Registration successful!');
        history.push('/account/login');
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
      let errorMsg =
        error.code === 'ERR_NETWORK'
          ? 'network error'
          : error.response
          ? error.response.data.message || error.response.data.error
          : error;
      toast.error(errorMsg);
    }
  };

  useEffect(() => {
    const getProvince = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_HOST_API}/province/list`
        );
        // console.table(result.data.response);
        setProvinceData(result.data.response);
      } catch (error) {
        console.log(error);
        let errorMsg =
          error.code === 'ERR_NETWORK'
            ? 'network error'
            : error.response
            ? error.response.data.message || error.response.data.error
            : error;
        toast.error(errorMsg);
      }
    };
    const city = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_HOST_API}/city/listing?province_id=${province}`
        );
        // console.table(result.data.response);
        setCityData(result.data.response);
      } catch (error) {
        console.log(error);
        let errorMsg =
          error.code === 'ERR_NETWORK'
            ? 'network error'
            : error.response
            ? error.response.data.message || error.response.data.error
            : error;
        toast.error(errorMsg);
      }
    };
    getProvince();
    if (province) {
      city();
    }
  }, [province]);

  useEffect(() => {
    getById()
  }, [])
  

  const getById = () => {
    axios
      .get(
        `${process.env.REACT_APP_HOST_API}/auth/email-info?key=${key}`
      )
      .then((data) => {
        setEmail(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Registration — ${theme.name}`}</title>
      </Helmet>

      <div className='block'>
        <div className='container'>
          <h3 className='card-title text-center mt-5'>Register</h3>
          <div className='row'>
            <div className='col-md-6 d-flex mt-4 mt-md-0'>
              <div className='card flex-grow-1 mb-0'>
                <div className='card-body'>
                  <form>
                    <div className='form-group'>
                      <label htmlFor='username'>Username</label>
                      <input
                        id='username'
                        type='text'
                        className='form-control'
                        placeholder='Masukan username'
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='name'>Nama</label>
                      <input
                        id='name'
                        type='text'
                        className='form-control'
                        placeholder='Masukan name'
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='register-email'>Email</label>
                      <input
                      disabled
                        id='register-email'
                        type='email'
                        className='form-control'
                        value={email}
                        placeholder='Masukan email address'
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='password'>Password</label>
                      <input
                        id='passwo'
                        type='password'
                        className='form-control'
                        placeholder='Masukan password'
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='phoneNumber'>Phone</label>
                      <input
                        id='phoneNumber'
                        type='tel'
                        className='form-control'
                        placeholder='Masukan phone number'
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='col-md-6 d-flex mt-4 mt-md-0'>
              <div className='card flex-grow-1 mb-0'>
                <div className='card-body'>
                  <form onSubmit={regist}>
                    <div className='form-group'>
                      <label htmlFor='gender'>Jenis Kelamin</label>
                      <select
                        id='gender'
                        className='form-control'
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value={1}>Laki-Laki</option>
                        <option value={2}>Perempuan</option>
                      </select>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='shippingAddress'>Alamat</label>
                      <input
                        id='shippingAddress'
                        type='text'
                        className='form-control'
                        placeholder='Masukan Alamat'
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='province'>provinsi</label>
                      <select
                        id='province'
                        className='form-control'
                        onChange={(e) => setProvince(e.target.value)}
                      > 
                      <option value="">Pilih Provinsi</option>
                        {Array.isArray(provinceData) &&
                          provinceData.map((province) => (
                            <option key={province.id} value={province.id}>
                              {province.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='city' >Kota</label>
                      <select id="city"
                        className='form-control'
                        onChange={(e) => setCity(e.target.value)}
                      >
                      <option value="">Pilih Kota</option>
                        {Array.isArray(cityData) &&
                          cityData.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='referral'>Referral Code</label>
                      <input
                        id='referral'
                        type='text'
                        className='form-control'
                        placeholder='Masukan referral code'
                        onChange={(e) => setRefferalCode(e.target.value)}
                      />
                    </div>

                    <button
                      onSubmit={regist}
                      type='submit'
                      disabled={load}
                      className={classNames(
                        'btn btn-primary mt-2 mt-md-3 mt-lg-4',
                        { 'btn-loading': load }
                      )}
                    >
                      Buat Account
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
