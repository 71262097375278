// import axios from "axios";
// // import { GET_ADDRESS_MAPS_URL } from "Constant";

// export const getAddressMap=(lat,lng)=>{
//   const headers = {
//     'Content-Type': 'application/json',
//   }
//  const getData = axios.get(`${process.env.REACT_APP_GET_ADDRESS_MAPS_URL}?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP}`, { headers });
//  const resData = getData.then((res) => res.data);

//   return resData;
// }

import axios from "axios";
import { GET_ADDRESS_MAPS_URL } from "./Constant";

export const getAddressMap=(lat,lng)=>{
  const headers = {
    'Content-Type': 'application/json',
  }
 const getData = axios.get(`${GET_ADDRESS_MAPS_URL}?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP}`, { headers });
 const resData = getData.then((res) => res.data);

  return resData;
}