// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import theme from '../../data/theme';
import { useDispatch, useSelector } from 'react-redux';
import { postLogin } from '../../store/auth/authActions';
import classNames from 'classnames';

export default function AccountPageCreate() {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const breadcrumb = [
    { title: 'Home', url: '' },
    { title: 'My Account', url: '' },
  ];
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const login = (e) => {
    e.preventDefault();
    if (!usernameOrEmail || !password) {
      toast.error('Email or password is required!');
    } else {
      const body = {
        usernameOrEmail,
        password,
      };
      dispatch(postLogin(body));
    }
  };

  const [email, setEmail] = useState('');
  const [load, setLoad] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        toast.error('Email is required!');
      } else {
        setLoad(true);
        const body = {
          email,
          command: 'REGISTRATION',
        };
        const result = await axios.post(
          `${process.env.REACT_APP_HOST_API}/auth/send-email`,
          body
        );
        //console.log(result.data);
        setLoad(false);
        toast.success('Email sent successfully');
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
      let errorMsg =
        error.code === 'ERR_NETWORK'
          ? 'network error'
          : error.response
          ? error.response.data.message || error.response.data.error
          : error;
      toast.error(errorMsg);
    }
  };
  const sendForgotEmail = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        toast.error('Email is required!');
      } else {
        setLoad(true);
        const body = {
          email: forgotPassword,
          command: 'RESET_PASSWORD',
        };
        const result = await axios.post(
          `${process.env.REACT_APP_HOST_API}/auth/send-email`,
          body
        );
        //console.log(result.data);
        setLoad(false);
        toast.success('Email sent successfully');
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
      let errorMsg =
        error.code === 'ERR_NETWORK'
          ? 'network error'
          : error.response
          ? error.response.data.message || error.response.data.error
          : error;
      toast.error(errorMsg);
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header='My Account' breadcrumb={breadcrumb} />

      <div className='block'>
        <div className='container'>
          <div className='row'>
            {/* <div className='col-md-6 d-flex'>
              <div className='card flex-grow-1 mb-md-0'>
                <div className='card-body'>
                  <h3 className='card-title'>Login</h3>
                  <form onSubmit={login}>
                    <div className='form-group'>
                      <label htmlFor='login-email'>Email address</label>
                      <input
                        id='login-email'
                        type='text'
                        className='form-control'
                        placeholder='Enter email or username'
                        onChange={(e) => setUsernameOrEmail(e.target.value)}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='login-password'>Password</label>
                      <input
                        id='login-password'
                        type='password'
                        className='form-control'
                        placeholder='Password'
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <small className='form-text text-muted'>
                        <Link to='/'>Forgotten Password</Link>
                      </small>
                    </div>
                    <div className='form-group'>
                      <div className='form-check'>
                        <span className='form-check-input input-check'>
                          <span className='input-check__body'>
                            <input
                              id='login-remember'
                              type='checkbox'
                              className='input-check__input'
                            />
                            <span className='input-check__box' />
                            <Check9x7Svg className='input-check__icon' />
                          </span>
                        </span>
                        <label
                          className='form-check-label'
                          htmlFor='login-remember'
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <button
                      type='submit'
                      disabled={loading}
                      className={classNames(
                        'btn btn-primary mt-2 mt-md-3 mt-lg-4',
                        { 'btn-loading': loading }
                      )}
                    >
                      Login
                    </button>
                    <button
                      type='submit'
                      className={classNames(
                        'btn btn-link mt-2 mt-md-3 mt-lg-4',
                      )}
                    >
                      <Link to='/account/sign-up'>Create An Account</Link>
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
            <div className='col-md-6 d-flex mt-4 mt-md-0'>
              <div className='card flex-grow-1 mb-0'>
                <div className='card-body'>
                  <h3 className='card-title'>Register</h3>
                  <form onSubmit={sendEmail}>
                    <div className='form-group'>
                      <label htmlFor='register-email'>Email address</label>
                      <input
                        id='register-email'
                        type='email'
                        className='form-control'
                        placeholder='Enter email'
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <button
                      type='submit'
                      disabled={load}
                      className={classNames(
                        'btn btn-primary mt-2 mt-md-3 mt-lg-4',
                        { 'btn-loading': load }
                      )}
                    >
                      Send email
                    </button>
                  </form>
                </div>
                {/* <div className='card-body'>
                  <h3 className='card-title'>Forgot Password</h3>
                  <form onSubmit={sendForgotEmail}>
                    <div className='form-group'>
                      <label htmlFor='register-email'>Email address</label>
                      <input
                        id='register-email'
                        type='email'
                        className='form-control'
                        placeholder='Enter email'
                        onChange={(e) => setForgotPassword(e.target.value)}
                      />
                    </div>

                    <button
                      type='submit'
                      disabled={load}
                      className={classNames(
                        'btn btn-primary mt-2 mt-md-3 mt-lg-4',
                        { 'btn-loading': load }
                      )}
                    >
                      Send email
                    </button>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
