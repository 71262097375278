import {
  POST_USER_LOGIN,
  DELETE_AUTH,
  POST_USER_LOGIN_SUCCESS,
  POST_USER_LOGIN_FAILED,
} from './authActionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';

export const postLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: POST_USER_LOGIN,
    });

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_HOST_API}/auth/login`,
      data: data,
    })
      .then((result) => {
        toast.success('Login success!');
        dispatch({
          type: POST_USER_LOGIN_SUCCESS,
          payload: {
            loading: false,
            token: result.data.response.token,
            errorMsg: false,
            success: true,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMsg =
          error.code === 'ERR_NETWORK'
            ? 'network error'
            : error.response
            ? error.response.data.message || error.response.data.error
            : error;
        toast.error(`Login failed! ${errorMsg} `);
        dispatch({
          type: POST_USER_LOGIN_FAILED,
          payload: {
            loading: false,
            errorMsg: errorMsg,
            success: false,
          },
        });
      });
  };
};

export const resetAuth = () => {
  localStorage.clear()
  return (dispatch) => {
    dispatch({
      type: DELETE_AUTH,
    });
    toast.success('You have been logged out')
  };
};

export const updateAction =(status)=>{
  return (dispatch) => {
    dispatch({
      type: "UPDATE_ACTION",
      payload: {
        update: status
    }});
  };

}
