import React from 'react';
import { useEffect } from 'react';
import { Input } from 'reactstrap';

const SearchLocation = ({
  address,
  // setItem,
  setLong,
  setLat
}) => {

  // useEffect(() => {
    // initAutocomplete()
  // }, [])
  

  const initAutocomplete = async () => {
    var input = document.getElementById('pac-input');
    var searchBox = new window.google.maps.places.SearchBox(input);

    // var input = document.getElementById("pac-input");
    // var searchBox = new google.maps.places.SearchBox(input);

    // var searchBox = new google.maps.places.SearchBox(
    //   document.getElementById("pac-input")
    // );

    var markers = [];
    searchBox.addListener('places_changed', function () {
      var places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      markers = [];

      var bounds = new window.google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(25, 25)
        };

        markers.push(new window.google.maps.Marker({
          icon: icon,
          title: place.name,
          position: place.geometry.location
        }));

        // console.log("MAKER ==>", markers, places, place.geometry.location.lat(), bounds);
        // setItem({
        //   lat: place.geometry.location.lat(),
        //   long: place.geometry.location.lng()
        // });

        setLat(place.geometry.location.lat())
        setLong(place.geometry.location.lng())

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

    });
  }

  initAutocomplete()

  return (
    <Input
      defaultValue={address}
      id="pac-input"
      className="controls"
      type="text"
      placeholder="Search Address"
    />
    // <div></div>
  );

}

export default SearchLocation;