// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
// import brands from '../../data/kategori';



export default function BlockMenu() {

	const menu = [
		{
			name: "Aplikator",
			image: "https://i.imgur.com/8dzv1AC.png",
			url : "/aplikator/pesan",
		},
		{
			name: "Cabang",
			image: "https://i.imgur.com/7w53ML4.png",
			url : "/site/not-found",
		},
		{
			name: "Iklan",
			image: "https://i.imgur.com/2tL5jT0.png",
			url : "/site/not-found",
		},
		{
			name: "Interior",
			image: "https://i.imgur.com/wG66um0.png",
			url : "/site/not-found",
		},
		{
			name: "Katalog",
			image: "https://i.imgur.com/KrSTZQS.png",
			url : "/site/not-found",
		},
		{
			name: "Konsultasi",
			image: "https://i.imgur.com/YZEReoG.png",
			url : "/site/not-found",
		},
		{
			name: "Live",
			image: "https://i.imgur.com/jgEL35f.png",
			url : "/site/not-found",
		},
		{
			name: "Ongkir",
			image: "https://i.imgur.com/yN9rpDW.png",
			url : "/site/not-found",
		},
		{
			name: "Perbandingan",
			image: "https://i.imgur.com/1wpdD5S.png",
			url : "/site/not-found",
		},
		{
			name: "Promo",
			image: "https://i.imgur.com/w8lPgUm.png",
			url : "/site/not-found",
		},
		// {
		// 	name: "Reseller",
		// 	image: "https://i.imgur.com/PmPKs8i.png",
		// 	url : "#",
		// },
		// {
		// 	name: "Riwayat",
		// 	image: "https://i.imgur.com/zCXyex5.png",
		// 	url : "#",
		// },
	];
	
	const slickSettings = {
		dots: false,
		arrows: false,
		infinite: true,
		slidesToShow: 10,
		slidesToScroll: 10,
		// autoplay: true,
		// speed: 2000,
		// autoplaySpeed: 2000,
		cssEase: "linear",
		responsive: [        
			{
			  breakpoint: 1000,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			  }
			},
			{
			  breakpoint: 100,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			  }
			}
		  ]
	};

    const brandsList = menu.map((menu, index) => (
        
        <div key={index} className="block-brands__item"
		style={{
			// width: "120px",
			// height: "150px",
			// border: "1px solid gainsboro",
			display: "flex",
			// infinite: true,
			// cssEase: "linear",
			flexDirection: "column",
			alignItems: "center",
            fontSize: "12px",
			// justifyContent: "space-between",
			// padding: "20px 10px",
			cursor: "pointer",
		}}
	>
            {/* <img src={menu.image} alt="" />
            <center>{menu.name}</center> */}
			<Link to={menu.url}>
				<img src={menu.image} alt="" width={70} height={70}
				/>
			</Link>
            <center>{menu.name}</center>
        </div>
    ));

	const brandsList2 = menu.map((menu, index) => (
				<div class="col" style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					fontSize: "12px",
					cursor: "pointer",
				}}>
					<Link to={menu.url}>
						<img src={menu.image} alt="" width={50} height={50}
						/>
					</Link>
				<font style={{
					fontSize:"10px"
				}}><center>{menu.name}</center></font>
				</div>
        
        
    ));

    return (
        <div className="block block-brands">
            <div className="container">
             {/* <div style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "20px" }}>Kategori</div> */}
                <div className="block-brands__slider d-lg-block d-none">
                    <StroykaSlick {...slickSettings}>
                        {brandsList}
                    </StroykaSlick>
                </div>
				<div class="row row-cols-5 d-md-none d-lg-none d-xl-none">
					{brandsList2}
				</div>
            </div>
			
        </div>
    );
}
