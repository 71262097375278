// react
import React, { Component, useRef } from 'react';

// third-party
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button,Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import axios from 'axios';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
// import { cartRemoveItem, cartUpdateQuantities } from '../../store/cart';
import { Check9x7Svg,Cross12Svg } from '../../svg';
import { url } from '../../services/utils';
import Collapse from '../shared/Collapse';


// data stubs
import theme from '../../data/theme';
import payments from '../../data/shopPayments';

import { toast } from 'react-toastify';
import { updateAction } from '../../store/auth/authActions';

function historyFunc(Component)  {
    function historyFunc(props){
        let history = useHistory()
        return <Component history={history} {...props}/>
    }
    return historyFunc
    
}
function dispatchFunc(Component)  {
    function dispatchFunc(props){
        let dispatch = useDispatch()
        return <Component dispatch={dispatch} {...props}/>
    }
    return dispatchFunc
    
}

class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);

        // this.state = {
        //     /** example: [{itemId: 8, value: 1}] */
        //     quantities: [],
        //     cart:false,
        //     totalPrice:0,
        //     cart_id:[],
        //     payment: "BANK_TRANSFER",
        //     loadingCO: false,
        //     paid: false,
        //     address_id: false,
        // };

        this.state = {
          listkurir:[],
          quantities: [],
          // cart: [],
          listItem: [],
          id:[],
          totalPrice: 0,
          totalOngkir: 0,
          cart_id: [],
          list_so: [],
          payment: "863.101.3333",
          loadingCO: false,
          paid: false,
          addressData: false,
          address_id: false,
          courier : [],
          per_kg: 0,
          open : false,
          listBank:[],
          show : false,
        };
        
    }

    
    componentDidMount(){
        const {token, updateStatus } =this.props
        // const getCart = async () => {
        //     const result = await axios({
        //       method: "GET",
        //       url: `${process.env.REACT_APP_HOST_API}/cart/list`,
        //       headers: {
        //         Authorization: `Bearer ${token}`,
        //       },
        //     });
        //     console.log(result.data?.response);
        //     this.setState({cart:result.data?.response});
        //     this.setState({cart_id:result.data?.response.map((item)=> item.id)});
        //     console.log(result.data?.response.map((item)=> item.id));
        //     const totalPrice = result.data?.response.map((item) => item.sub_total);
        //     // console.log(totalPrice);
        //     let sum = 0;
      
        //     for (let i = 0; i < totalPrice.length; i++) {
        //       sum += totalPrice[i];
        //     }
        //     this.setState({totalPrice: sum});
        //     const productQuantity=result.data?.response.map(({id, qty}) => {
        //         return {itemId:id,value:qty}
        //     })
        //     this.setState({quantities: productQuantity})
        //     console.log(this.state.quantities);
        //   };
        const getSo = async () => {
          const result = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_HOST_API}/so-commerce/list-by-user`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // console.log(result.data?.response);
          this.setState({ listItem: result.data.response});
          this.setState({ list_so: result.data.response.map((item) => item.id) });
          // console.log("list Item SO " + result.data.response.map((itemnya) => itemnya.items));
          // console.log("list so id " + result.data.response.map((item) => item.id));
          const totalPrice = result.data.response.map((item) => item.payment_total);
          const totalOngkir = result.data.response.map((item) => item.ongkir);
          // console.log(totalPrice);
          let sum = 0;
    
          for (let i = 0; i < totalPrice.length; i++) {
            sum += totalPrice[i];
          }
          this.setState({ totalPrice: sum });
          const kurircok=result.data.response.map(({id, kurir_id}) => {
            return {id:id, value:kurir_id}
        })
        this.setState({listkurir: kurircok})
        // console.log(this.state.listkurir);
    
          let sam = 0;
    
          for (let i = 0; i < totalOngkir.length; i++) {
            sam += totalOngkir[i];
          }
          this.setState({ totalOngkir: sam });
        };
    
        const getAddress = async () => {
          const user_id = localStorage.getItem('user_id');
          try {
            const result = await axios({
              method: 'GET',
              url: `${process.env.REACT_APP_HOST_API}/address/list/`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            this.setState({ addressData: result.data?.response });
            this.setState({ address_id: result.data?.response[0].address_id });
          } catch (error) {
            // console.log(error);
            toast.error('There was an error!');
          }
        };
    
        const getCourier = async ()=>{
            try {
                const result = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_HOST_API}/kurir/aktif`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  this.setState({ courier: result.data?.response });
                  this.setState({ per_kg: result.data?.response[0].per_kg });
            } catch (error) {
                // console.log(error);
                toast.error('There was an error!');
            }
        }
    
        const getBankList = async () => {
            const result = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_HOST_API}/bank/list`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            this.setState({ listBank: result.data?.response });
        };
          if(token ||updateStatus){
              // getCart();
              getSo();
              getAddress();
              getCourier();
              getBankList();
          }
    }
    componentDidUpdate(){
        const {token, updateStatus } =this.props
          const getSo = async () => {
            const result = await axios({
              method: 'GET',
              url: `${process.env.REACT_APP_HOST_API}/so-commerce/list-by-user`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            this.setState({ listItem: result.data.response});
            this.setState({ list_so: result.data.response.map((item) => item.id) });
            const totalPrice = result.data.response.map((item) => item.payment_total);
            const totalOngkir = result.data.response.map((item) => item.ongkir);
            let sum = 0;
      
            for (let i = 0; i < totalPrice.length; i++) {
              sum += totalPrice[i];
            }
            this.setState({ totalPrice: sum });
            const kurircok=result.data.response.map(({id, kurir_id}) => {
              return {id:id, value:kurir_id}
          })
          this.setState({listkurir: kurircok})
          // console.log(this.state.listkurir);
      
            let sam = 0;
      
            for (let i = 0; i < totalOngkir.length; i++) {
              sam += totalOngkir[i];
            }
            this.setState({ totalOngkir: sam });
          };
          if(updateStatus){
              getSo();
          }
    }

    checkoutHandler = async (body) => {
      const { token, history, dispatch, } = this.props;    
      const user_code = localStorage.getItem('user_code');
  
      let param = {
        user_code : user_code,
        account_number : body.bank_id,
        list_so : body.list_so
      }
  
      try {
        this.setState({ loadingCO: true });
        const result = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_HOST_API}/transaction/do-payment-product`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: param,
        });
        this.setState({ loadingCO: false });
        // console.log(result.data.response);
        localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
        toast.success(`${result.data.message}`);
        this.setState({ paid: true });
        dispatch(updateAction(true));
        setTimeout(() => {
          dispatch(updateAction(false));
        }, 100);
        history.push('/shop/checkout/success');
      } catch (error) {
        this.setState({ loadingCO: false });
        // console.log(error);
        toast.error(error.message);
      }
    };

    cartUpdateKurirCok =async(listkurir)=>{
      const { token, history, dispatch, } = this.props;   
      const PengirimanHandler = async (id,param) => {
        try {
          this.setState({ loadingCO: true });
          const result = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_HOST_API}/so-commerce/update-pengiriman/${id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: param,
          });
          this.setState({ loadingCO: false });
          dispatch(updateAction(true));
          setTimeout(() => {
            dispatch(updateAction(false));
          }, 100);
          // history.push('/shop/checkout');
        } catch (error) {
          this.setState({ loadingCO: false });
        }
      };
    
      listkurir.map(({id,value})=>{
        PengirimanHandler(id,{kurir_id: value,address_id: this.state.address_id,})
    })
    }

    handlePaymentChange = (event) => {
      if (event.target.checked) {
        this.setState({ payment: event.target.value });
      }
    };

    // renderTotals() {
    //   const { cart } = this.props;
  
    //   if (cart.extraLines.length <= 0) {
    //     return null;
    //   }
  
    //   const extraLines = cart.extraLines.map((extraLine, index) => (
    //     <tr key={index}>
    //       <th>{extraLine.title}</th>
    //       <td>
    //         <Currency value={extraLine.price} />
    //       </td>
    //     </tr>
    //   ));
  
    //   const formatRupiah = (money) => {
    //     return new Intl.NumberFormat('id-ID',
    //         { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    //     ).format(money);
    //   }
    //   return (
        
    //     <React.Fragment>
          
    //       <tbody className='checkout__totals-subtotals'>
    //         <tr>
    //           <th>Sub Total</th>
    //           <td>
    //             {formatRupiah(this.state.totalPrice)}
    //           </td>
    //         </tr>
    //         {/* <tr>
    //           <th>Biaya Pengiriman</th>
    //           <td>
    //             {formatRupiah(this.state.totalOngkir)}
    //           </td>
    //         </tr> */}
    //       </tbody>
    //     </React.Fragment>
    //   );
    // }
  
    renderTotals1() {
  
      const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }
      return (
        
        <React.Fragment>
            <tr>
              <th>Sub Total</th>
              <th></th>
              <td>
                <b>{formatRupiah(this.state.totalPrice)}</b>
              </td>
            </tr>
            <tr>
              <th>Ongkir</th>
              <th></th>
              <td>
                <b>{formatRupiah(this.state.per_kg)}</b>
              </td>
            </tr>
        </React.Fragment>
      );
    }

    getKurirasiu(item) {
      const { listkurir } = this.state;
      const kurir = listkurir.find((x) => x.id === item.id);
  
      return kurir ? kurir.value : item.kurir_id;
      
  }
  
    handleChangeKurir = (item, kurir) => {
      this.setState((state) => {
          const stateKurir = state.listkurir.find((x) => x.id === item.id);
  
          if (!stateKurir) {
              state.listkurir.push({ id: item.id, value: kurir });
          } else {
              stateKurir.value = kurir;
          }
          return {
              listkurir: state.listkurir,
          };
      });
    };
  
    renderCart1() {
      const { token, history, dispatch, } = this.props;   
      const { listkurir} = this.state;   
  
      const updateKarirCok = (
        <AsyncAction
            action={() => this.cartUpdateKurirCok(listkurir)}
            render={({ run, loading }) => {
                const classes = classNames('btn btn-primary cart__update-button', {
                    'btn-loading': loading,
                });
  
                return (
                    <button type="button" onClick={run} className={classes}>
                        Cek
                    </button>
                );
            }}
        />
    );
  
      const { listItem } = this.state;
  
      const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }
      
      const items = listItem.map((item,index) => {
        return (
            <tbody className='checkout__totals-products'>
            <tr key={index.id}>
                  <td colSpan={2}>
                      <div className='form-check'>
                        <span className='form-check-input input-check'>
                          <span className='input-check__body'>
                            <Check9x7Svg />
                          </span>
                        </span>
                        <label
                          className='form-check-label'
                          htmlFor='checkout-terms'
                        >
                          <b>{item.warehouse_name}</b><p></p>
                        </label>
                      </div>
                  </td>
                <td></td>
            </tr>
            {item.items.map((listItem, listIndex) => (
              <tr key={listIndex.id}>
                <td>
                              <img src={listItem.image_url} alt="" height="100px" width="100px" />
                </td>
                            <td>{listItem.item_name} x {listItem.qty}</td>
                            <td>{formatRupiah(listItem.sub_total)}</td>
                </tr>
              ))}
              <br></br>
              <tr>
                <th colSpan={2}>
                  <select className='form-control' value={this.getKurirasiu(item)} onChange={(kurir) => this.handleChangeKurir(item, kurir.target.value)} >
                    <option value={0}>Pilih Pengiriman</option>
                      {this.state.courier.map((res,idx)=>
                    <option key={res.id} value={res.id}>{res.name}</option>) }
                  </select>
                </th>
                <th>{updateKarirCok}</th>
              </tr>
              <tr>
                <th>Sub Total</th>
                <th></th>
                <td>
                  <b>{formatRupiah(item.sub_total_so)}</b>
                </td>
              </tr>
              <tr>
                <th>Ongkir</th>
                <th></th>
                <td>
                  <b>{formatRupiah(item.ongkir)}</b>
                </td>
              </tr>
             <tr>
               <th>Total</th>
               <th></th>
               <td>
                 <b>{formatRupiah(item.payment_total)}</b>
               </td>
             </tr>
            </tbody>
        
        )
        });
  
      return (
        <table className='checkout__totals'>
          <thead className='checkout__totals-header'>
            {/* <tr>
              <th>PESANAN</th>
              <th></th>
            </tr> */}
          </thead>
          {items}
        </table>
      );
    }

    renderPaymentsList() {
      const { payment: currentPayment } = this.state;
      // const payments = this.payments.map((payment) => {
        // console.log(listBank);
      const Manok = this.state.listBank.map((id) => {
        const renderPayment = ({ setItemRef, setContentRef }) => (
          <li className='payment-methods__item' ref={setItemRef}>
            <label className='payment-methods__item-header'>
              <span className='payment-methods__item-radio input-radio'>
                <span className='input-radio__body'>
                  <input
                    type='radio'
                    className='input-radio__input'
                    name='checkout_payment_method'
                    value={id.account_number}
                    checked={currentPayment === id.account_number}
                    onChange={this.handlePaymentChange}
                  />
                  <span className='input-radio__circle' />
                </span>
              </span>
              <span className='payment-methods__item-title'>{id.bank_name}</span>
            </label>
            <div className='payment-methods__item-container' ref={setContentRef}>
              <div className='payment-methods__item-description text-muted'>
                {id.account_number} a/n {id.account_name}
              </div>
            </div>
          </li>
        );
  
        return (
          <Collapse
            key={id.account_number}
            open={currentPayment === id.account_number}
            toggleClass='payment-methods__item--active'
            render={renderPayment}
          />
        );
      });
  
      return (
        <div className='payment-methods'>
          <ul className='payment-methods__list'>{Manok}</ul>
        </div>
      );
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    // renderPaymentsList() {
    //     const { payment: currentPayment } = this.state;

    //     const payments = this.payments.map((payment) => {
    //         const renderPayment = ({ setItemRef, setContentRef }) => (
    //             <li className="payment-methods__item" ref={setItemRef}>
    //                 <label className="payment-methods__item-header">
    //                     <span className="payment-methods__item-radio input-radio">
    //                         <span className="input-radio__body">
    //                             <input
    //                                 type="radio"
    //                                 className="input-radio__input"
    //                                 name="checkout_payment_method"
    //                                 value={payment.key}
    //                                 checked={currentPayment === payment.key}
    //                                 onChange={this.handlePaymentChange}
    //                             />
    //                             <span className="input-radio__circle" />
    //                         </span>
    //                     </span>
    //                     <span className="payment-methods__item-title">{payment.title}</span>
    //                 </label>
    //                 <div className="payment-methods__item-container" ref={setContentRef}>
    //                     <div className="payment-methods__item-description text-muted">{payment.description}</div>
    //                 </div>
    //             </li>
    //         );

    //         return (
    //             <Collapse
    //                 key={payment.key}
    //                 open={currentPayment === payment.key}
    //                 toggleClass="payment-methods__item--active"
    //                 render={renderPayment}
    //             />
    //         );
    //     });

    //     return (
    //         <div className="payment-methods">
    //             <ul className="payment-methods__list">
    //                 {payments}
    //             </ul>
    //         </div>
    //     );
    // }

    renderTotals() {
        const { cart, totalPrice } = this.state;
        

        if (cart.length <= 0) {
            return null;
        }

        const formatRupiah = (money) => {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
          }

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>{formatRupiah(totalPrice)}</td>
                    </tr>
                </thead>
            </React.Fragment>
        );
    }

    renderCart2() {
      const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

      return (
        <table className='checkout__totals'>
          <tfoot className='checkout__totals-footer'>
            <tr>
              <th>Total Harga</th>
              <td>
                <b>{formatRupiah(this.state.totalPrice)}</b>
              </td>
            </tr>
          </tfoot>
        </table>
      );
    }
    
    render() {
  
      const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Keranjang', url: '/shop/cart' },
        { title: 'Checkout', url: '' },
      ];
  
      return (
        <React.Fragment>
          <Helmet>
            <title>{`Checkout — ${theme.name}`}</title>
          </Helmet>
  
          <PageHeader header='Checkout' breadcrumb={breadcrumb} />
  
          <div className='checkout block'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-lg-6 col-xl-7'>
                  <div className='card mb-lg-0'>
                    <div className='card-body'>
                      <h3 className='card-title'>Alamat Pengiriman</h3>
                      <div className='d-flex'>
  
                      {Array.isArray(this.state.addressData) &&
                        this.state.addressData.map((address, idx) => (
                          <React.Fragment key={idx}>
                            <div className='addresses-list__item card address-card'>
                              {idx === 0 && (
                                <div className='address-card__badge'>Default</div>
                              )}
  
                              <div className='address-card__body' style={{minHeight: '182px', position: 'relative'}}>
                                {/* <div className="address-card__name">{`${address.firstName} ${address.lastName}`}</div> */}
                                <div className='address-card__row'>
                                  {address.address_name},{address.city_name},
                                  {address.province_name}
                                </div>
                                <div className='address-card__footer d-flex justify-content-center ' style={{position: 'absolute', bottom: '17px', left: '68px'}}>
                                  <Link
                                    to={`/account/addresses/${address.address_id}`}
                                  >
                                    Edit
                                  </Link>
                                  &nbsp;&nbsp;
                                  <input
                                    type='radio'
                                    value={address.address_id}
                                    defaultChecked={
                                      address.address_id === this.state.address_id
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        address_id: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <p></p>
                      <h3 className='card-title'>Pesanan</h3>
                      {this.renderCart1()}
                       
                    </div>
                  </div>
                </div>
  
                <div className='col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0'>
                  <div className='card mb-0'>
                    <div className='card-body'>
                      <h3 className='card-title'>Ringkasan Belanja </h3>
  
                      {this.renderCart2()}

                       <button
                        type='submit'
                        className={classNames('btn btn-primary btn-xl btn-block')}
                        onClick={e => { 
                          this.setState({show:true}) 
                        }}
                      >
                        PAYMENT
                      </button>
                      <Modal isOpen={this.state.show} toggle={!this.state.show} fade={false} style={{ minWidth: "40%", top: "5%" }}>
                        <ModalHeader>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3> Metode Pembayaran</h3> 
                            <div style={{ textAlign: 'right' }}>
                              <Button  
                                  onClick={e => { 
                                  this.setState({show:false}) 
                                  }} >
                                    X
                              </Button>
                            </div>
                          </div>
                        </ModalHeader>
                        <ModalBody
                          cssModule={{
                            alignText: "center",
                          }}>
                            {this.renderPaymentsList()}
                        </ModalBody>
                        <ModalFooter>
                         
                    <button
                        type='submit'
                        disabled={this.state.loadingCO}
                        className={classNames('btn btn-primary btn-xl btn-block',{'btn-loading': this.state.loadingCO})}
                        onClick={() => {this.checkoutHandler({
                          bank_id: this.state.payment,
                          address_id: this.state.address_id,
                          list_so: this.state.list_so,
                        });
                        }}
                      >
                        ORDER
                      </button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
}

const mapStateToProps = (state) => ({
    // cart: state.cart,
    // cart: state.cart,
    token: state.auth.token,
    updateStatus: state.auth.update
});

const mapDispatchToProps = {
    // cartRemoveItem,
    // cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(dispatchFunc(historyFunc(ShopPageCheckout)));
