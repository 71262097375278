// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, cartUpdateQuantities } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { url } from '../../services/utils';
import Collapse from '../shared/Collapse';


// data stubs
import theme from '../../data/theme';

import { toast } from 'react-toastify';
import { updateAction } from '../../store/auth/authActions';
function historyFunc(Component) {
    function historyFunc(props) {
        let history = useHistory()
        return <Component history={history} {...props} />
    }
    return historyFunc

}
function dispatchFunc(Component) {
    function dispatchFunc(props) {
        let dispatch = useDispatch()
        return <Component dispatch={dispatch} {...props} />
    }
    return dispatchFunc

}

class PaymentPage extends Component {
    constructor(props) {
        super(props);
        const id = localStorage.getItem('transaction_id');

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            listBank: [],
            currentPayment: "BCA",
            cart: false,
            id: id,
            file: null,
            bank_id: 0
        };
    }


    componentDidMount() {
        const { token, updateStatus } = this.props;
        const getCart = async () => {
            const result = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_HOST_API}/transaction/get/` + this.state.id,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            this.setState({ cart: result.data?.response });
        };

        const getBankList = async () => {
            const result = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_HOST_API}/bank/list`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            this.setState({ listBank: result.data?.response });
        };

        if (token || updateStatus) {
            getCart();
            getBankList();
        }
    }


    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ currentPayment: event.target.value });
        }
    };

    renderPaymentsList() {
        const payments = this.state.listBank.map((pay) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className='payment-methods__item' ref={setItemRef}>
                    <label className='payment-methods__item-header'>
                        <span className='payment-methods__item-radio input-radio'>
                            <span className='input-radio__body'>
                                <input
                                    type='radio'
                                    className='input-radio__input'
                                    name='checkout_payment_method'
                                    value={pay.bank_name}
                                    checked={this.state.currentPayment === pay.bank_name}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className='input-radio__circle' />
                            </span>
                        </span>
                        <span className='payment-methods__item-title'>{pay.bank_name}</span>
                    </label>
                    <div className='payment-methods__item-container' ref={setContentRef}>
                        <div className='payment-methods__item-description text-muted'>
                            {pay.account_number} a/n {pay.account_name}
                        </div>
                    </div>
                </li>
            );
            return (
                <Collapse
                    key={pay.id}
                    open={this.state.currentPayment === pay.bank_name}
                    toggleClass='payment-methods__item--active'
                    render={renderPayment}
                />
            );
        });

        return (
            <div className='payment-methods'>
                <ul className='payment-methods__list'>{payments}</ul>
            </div>
        );
    }

    FileUploadPage() {

        const changeHandler = (event) => {
            this.setState({ file: event.target.files[0] });
        };

        const handleSubmission = () => {
        };

        return (
            <label className="btn btn-default">
                <input type="file" onChange={changeHandler} />
            </label>
        )
    }


    renderItems() {
        // const {  cartRemoveItem } = this.props;
        const { token, dispatch } = this.props

        const { cart } = this.state
        const formatRupiah = (money) => {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
        }

        return (
            <tr className="cart-table__row">
                <td className="cart-table__column cart-table__column--product">
                    {cart.transaction_code}
                </td>
                <td className="cart-table__column cart-table__column--price" data-title="Price">
                    {cart.created_at}
                </td>
                <td className="cart-table__column cart-table__column--total" data-title="Total">
                    {formatRupiah(cart.total_transaction)}
                </td>
            </tr>
        );
    }


    checkoutHandler = async () => {
        const { token, history, dispatch, } = this.props;
        const user_code = localStorage.getItem('user_code');
        let bank_id = this.state.listBank.find(x => x.bank_name == this.state.currentPayment).id;

        if(!this.state.file){
            toast.error('Please upload file');
            return;
        }

        const formData = new FormData();
        formData.append('nota', this.state.file);

        let obj = {bank_id:bank_id};
        const json = JSON.stringify(obj);

        formData.append('body', json);

        try {
            this.setState({ loadingCO: true });
            const result = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST_API}/transaction/transfer/`+ this.state.id,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: formData,
            });
            this.setState({ loadingCO: false });
            console.log(result.data.response);

            localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
            localStorage.removeItem('transaction_id');

            toast.success(`${result.data.message}`);
            this.setState({ paid: true });
            dispatch(updateAction(true));
            setTimeout(() => {
                dispatch(updateAction(false));
            }, 1000);
            history.push('/shop/checkout/success');
        } catch (error) {
            this.setState({ loadingCO: false });
            console.log(error);
            toast.error(error.message);
        }

    }


    renderCart() {
        const { loadingCO } = this.state;

        const checkoutButton = (
            <AsyncAction
                action={
                    this.checkoutHandler}
                render={({ run, loading }) => {
                    const classes = classNames('btn btn-primary btn-xl btn-block cart__checkout-button', {
                        'btn-loading': loadingCO,
                    });

                    return (
                        <button type="button" onClick={this.checkoutHandler} className={classes} disabled={loadingCO}>
                            Payment
                        </button>
                    );
                }}
            />
        );

        return (
            <div className="cart block">
                <div className="container">
                    <div className="row justify-content-content">
                        <div className="col-12 col-md-9 col-lg-8 col-xl-7">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Daftar Transaksi</h3>
                                    <table className="cart__table cart-table">
                                        <thead className="cart-table__head">
                                            <tr className="cart-table__row">
                                                <th className="cart-table__column">Number</th>
                                                <th style={{ paddingRight: 2 }} className="cart-table__column cart-table__column--price">Date</th>
                                                <th style={{ paddingRight: 18 }} className="cart-table__column cart-table__column--total">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="cart-table__body">
                                            {this.renderItems()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Total Pembayaran</h3>

                                    {this.renderPaymentsList()}
                                    {this.FileUploadPage()}
                                    {checkoutButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }

    render() {
        const { cart } = this.state;
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Transaction', url: '' },
        ];

        let content;

        if (cart) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">Keranjangmu Kosong, yuk belanja dulu!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">Belanja</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Payment — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Payment" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    token: state.auth.token,
    updateStatus: state.auth.update
});

export default connect(mapStateToProps)(dispatchFunc(historyFunc(PaymentPage)));
