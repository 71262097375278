// react
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Collapse,
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';
import { Check9x7Svg } from '../../svg';

import { updateAction } from '../../store/auth/authActions';
// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import SelectFile from '../../store/selectfile/Index';

export default function AplikatorBuktiPembayaranDp(props) {
    let history = useHistory()
    const   { buktibayardp} = props;
    const   [image, setImage] = useState(null)
    const   {token, update}= useSelector((state) => state.auth)
    const   [isLoading, setIsLoading] = useState(true);
    const   [orderInfo, setInfo] = useState(false)
    const   [transactionid, setTransactionId] = useState("")
    const   [kode, setKode] = useState("")
    const   [alamat, setAlamat] = useState("")
    const   [tanggal, setTanggal] = useState("")
    const   [bank, setBank] = useState("")
    const   [banks, setBanks] = useState([])
    const   [centang, setCentang] = useState("")
    const   [Total, setTotal] = useState("")
    // const   [Bank, setBank] = useState("")
    const   dispatch= useDispatch()

    useEffect(() => {
        getCourier()
        renderPaymentsList()
    }, [])
    

    const getCourier = async ()=>{
        try {
            const result = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_HOST_API}/bank/list`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              setBanks(result.data.response)
        } catch (error) {
            // console.log(error);
            toast.error('There was an error!');
        }
    }

    const renderPaymentsList = () => {
        const Manok = banks.map((id) => {
          const renderPayment = ({ setItemRef, setContentRef }) => (
            <li className='payment-methods__item' ref={setItemRef}>
              <label className='payment-methods__item-header'>
                <span className='payment-methods__item-radio input-radio'>
                  <span className='input-radio__body'>
                    <input
                      type='radio'
                      className='input-radio__input'
                      name='checkout_payment_method'
                      value={id.account_number}
                      checked={bank === id.account_number}
                      onChange={e => setBank(e.target.value)}
                    />
                    <span className='input-radio__circle' />
                  </span>
                </span>
                <span className='payment-methods__item-title'>{id.bank_name}</span>
              </label>
              <div className='payment-methods__item-container' ref={setContentRef}>
                <div className='payment-methods__item-description text-muted'>
                  {id.account_number} a/n {id.account_name}
                </div>
              </div>
            </li>
          );
    
          return (
            <Collapse
              key={id.account_number}
              open={bank === id.account_number}
              toggleClass='payment-methods__item--active'
              render={renderPayment}
            />
          );
        });
    
        return (
          <div className='payment-methods'>
            <ul className='payment-methods__list'>{Manok}</ul>
          </div>
        );
      }

    const   updateHandler = async ()=>{

        try {
            dispatch(updateAction(true))
                const headers = {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                };
                let data = new FormData();
                data.append("bukti",image)
                axios
                  .post(`${process.env.REACT_APP_HOST_API}/transaction/bukti-um/${transactionid}`, data, { headers })
                  .then(function (response) {
                    toast.success('Bukti Transfer Sukses')
                    dispatch(updateAction(false))
                    // localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                    history.push("/aplikator/order-jasa")
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast.error('Bukti Transfer Gagal')
                    });
            //   toast.success('Pembayaran Selesai')
            //       dispatch(updateAction(false))
            //       localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
            //       history.push("/aplikator/order-jasa")
            
        } catch (error) {
            console.log(error);
            toast.error('Mohon Pilih Metode Pembayaran')
            
        }
    }

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }
    const items = order.items.map((item) => {
        const options = (item.options || []).map((option) => (
            <li className="order-list__options-item">
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));
        // useEffect(()=>{
        //     setInfo(JSON.parse(localStorage.getItem('CO-INFO')))
            
        // },[])

        useEffect(() => {
            // let canceled = false;
    
            setIsLoading(true);

            const getUserId = (id) =>{
                axios({
                 method: "get",
                 url: `${process.env.REACT_APP_HOST_API}/order-aplikator/payment/${buktibayardp}`,
                 headers: {
                   Authorization: `Bearer ${token}`,
                 },
               })
               .then(data => {
                setKode(data.data.response)
                setTransactionId(data.data.response.id)
             })
               .catch(function (error) {
               })
            }
    
            // const getProductById = async ()=>{
            //     try {
            //         const result = await axios.get(`${process.env.REACT_APP_HOST_API}/transaction/get/${buktibayardp}`, {headers: {
            //             Authorization: `Bearer ${token}`,
            //           },})
            //         // setProduct(result.data.response)
            //         setKode(result.data.response)
            //         setTanggal(result.data.response)
            //         setTotal(result.data.response)
            //         setBank(result.data.response)
            //         // console.log(result.data.data);
            //         setIsLoading(false)
            //     } catch (error) {
            //         console.log(error);
            //         setIsLoading(false);
            //     }
            // }
    
            // shopApi.getProductBySlug(productSlug).then((product) => {
            //     if (canceled) {
            //         return;
            //     }
    
            //     setProduct(product);
            // });
            getUserId()
        }, [buktibayardp, setIsLoading]);

        
        // console.log(orderInfo)
        return (
            <tr>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <Link to={url.product(item)} className="product-image__body">
                            <img className="product-image__img" src={item.image} alt="" />
                        </Link>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <Link to={url.product(item)}>{item.name}</Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {options}
                            </ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title="Qty:">{item.quantity}</td>
                <td className="order-list__column-total"><Currency value={item.total} /></td>
            </tr>
        );
    });

    const additionalLines = order.additionalLines.map((line) => (
        <tr>
            <th className="order-list__column-label" colSpan="3">{line.label}</th>
            <td className="order-list__column-total"><Currency value={line.total} /></td>
        </tr>
    ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Bukti Bayar — ${theme.name}`}</title>
            </Helmet>
            <br></br>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/aplikator/order-jasa">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div>
            </div>
            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        {/* <Check100Svg className="order-success__icon" /> */}
                        <div className="order-success__subtitle"><h4>Detail Pembayaran DP</h4></div><br></br>
                        <div className="order-success__subtitle"><h2><font color='blue'>{formatRupiah(kode.transaction_um)}</font></h2></div><br></br><br></br>
                        {/* <div>Pembayaran DP sebesar {formatRupiah(kode.transaction_um)} untuk mempermudah kami menyelesaikan project dengan cepat</div> */}
                    
                    </div>
                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Tanggal Transaksi:</span>
                                <span className="order-success__meta-value">{kode.transaction_date}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Bank:</span>
                                <span className="order-success__meta-value">{kode.bank_name}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Kode Pembayaran:</span>
                                <span className="order-success__meta-value">{kode.transaction_code}</span>
                            </li>
                           
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Rekening:</span>
                                <span className="order-success__meta-value">{kode.account_number}</span>
                            </li>
                           
                            <li className="order-success__meta-item">
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Pemilik:</span>
                                <span className="order-success__meta-value">{kode.account_name}</span>
                            </li>
                            <li className="order-success__meta-item">
                            </li>
                            <li className='order-success__meta-item'>
                                {/* <label htmlFor="formId" >
                                <input
                                hidden
                                id="formId"
                                name="file"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                    setImage(event.target.files[0]);
                                }}
                                />
                                <SelectFile
                                onclick={() => { }}
                                imageSrc={image != "" && image != undefined ? URL.createObjectURL(image) : null}
                                />
                            </label> */}
                            </li>
                            <li className='order-success__meta-item'>
                                <label htmlFor="formId" >
                                <input
                                hidden
                                id="formId"
                                name="file"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                    setImage(event.target.files[0]);
                                }}
                                />
                                <SelectFile
                                onclick={() => { }}
                                imageSrc={image != "" && image != undefined ? URL.createObjectURL(image) : null}
                                />
                            </label>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="col-12 col-lg-7 col-xl-6">
                    <div className='form-check'>
                        <span className='form-check-input input-check'>
                          <span className='input-check__body'>
                            <input
                              className='input-check__input'
                              type='checkbox'
                              id='checkout-terms'
                              value={centang === 5}
                              onChange={e => setCentang(5)}
                            />
                            <span className='input-check__box' />
                            <Check9x7Svg className='input-check__icon' />
                          </span>
                        </span>
                        <label
                          className='form-check-label'
                          htmlFor='checkout-terms'
                        >
                         Setuju dengan Pembayaran.<p></p>
                        </label>
                      </div>
                      </div> */}
                      {/* <div className="form-group">
                            <label htmlFor="formId" >
                            <input
                              hidden
                              id="formId"
                              name="file"
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                setImage(event.target.files[0]);
                              }}
                            />
                            <SelectFile
                              onclick={() => { }}
                              imageSrc={image != "" && image != undefined ? URL.createObjectURL(image) : null}
                            />
                          </label>
                        </div> */}
                        {/* {this.renderPaymentsList()} */}
                        {/* <Collapse
                            key={bank.account_number}
                            open={bank === bank.account_number}
                            toggleClass='payment-methods__item--active'
                            render={renderPayment}
                            /> */}
                    <div className="order-success__meta">
                        {/* <div className="order-success__actions">
                            <Link to="/accounts/order-produk" className="btn btn-xs btn-warning">Kembali</Link>
                        </div> */}
                        {/* <div className="order-success__actions"> */}
                        <div className='cart__buttons'>
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Kirim</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
