// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';


// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import AccountPageCreate from './account/AccountPageCreate';
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import BlogPageCategory from './blog/BlogPageCategory';
import BlogPagePost from './blog/BlogPagePost';
import PageCart from './shop/ShopPageCart';
import TransactionPage from './shop/ShopPageTransaction';
import PaymentPage from './shop/ShopPagePayment';
import PageCheckout from './shop/ShopPageCheckout';
import PageCompare from './shop/ShopPageCompare';
import PageWishlist from './shop/ShopPageWishlist';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageProduct from './shop/ShopPageProduct';
import ShopPageTrackOrder from './shop/ShopPageTrackOrder';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageComponents from './site/SitePageComponents';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageContactUsAlt from './site/SitePageContactUsAlt';
import SitePageFaq from './site/SitePageFaq';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import SitePageTypography from './site/SitePageTypography';

// data stubs
import theme from '../data/theme';
import Registration from './auth/Registration';
import ResetPassword from './auth/Reset-Password';
import AccountPageDashboard from './account/AccountPageDashboard';
import AccountPageProfile from './account/AccountPageProfile';
import AccountLayoutMobile from './account/AccountLayoutMobile';
import accountOrders from '../data/accountOrders';
import AccountPageOrders from './account/AccountPageOrders';
import ShopPageOrderGetById from './shop/ShopPageOrderGetById';
import AplikatorPage from './aplikator/Aplikator';
import AplikatorPageSukses from './aplikator/AplikatorPageSukses';
import AplikatorPageOrders from './aplikator/AplikatorPageOrders';
import AplikatorInputAlamatSurvey from './aplikator/AplikatorInputAlamatSurvey';
import AplikatorDetailObeservasi from './aplikator/AplikatorDetailObservasi';
import AplikatorDetailPembayaranDp from './aplikator/AplikatorBayarDp';
import AplikatorBuktiPembayaranDp from './aplikator/AplikatorBuktiBayarDp';
import AplikatorInputEmailKlien from './aplikator/AplikatorInputEmail';
import AplikatorPelunasan from './aplikator/AplikatorPelunasan';
import AddendumPageOrders from './addendum/AddendumPageOrders';
import AddendumCreatePage from './addendum/Addendum';
import AddendumPageSukses from './addendum/AddendumPageSukses';
import AddendumDetailRevisi from './addendum/AddendumDetailRevisi';
import AddendumBuktiPembayaran from './addendum/AddendumBuktiBayar';
import AddendumDetail from './addendum/AddendumDetail';
import AplikatorDetailObeservasicuak from './aplikator/AplikatorDetail';
import AccountPageEditAddress from './account/AccountPageEditAddress';
import AccountAddressDua from './account/AccountAddressDua';
import AccountAddressTiga from './account/Account AddressTiga';
import AplikatorValidasiRab from './aplikator/AplikatorValidasiRab';
import komisipage from './komisi/komisipage';
import PengajuanKomisi from './komisi/PengajuanKomisi';
import PengajuanKomisiSukses from './komisi/PengajuanKomisiSukses';

const categoryLayouts = [
    ['/shop/category-grid-3-columns-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'start' }],
    ['/shop/category-grid-4-columns-full', { columns: 4, viewMode: 'grid' }],
    ['/shop/category-grid-5-columns-full', { columns: 5, viewMode: 'grid' }],
    ['/shop/category-list', { columns: 3, viewMode: 'list', sidebarPosition: 'start' }],
    ['/shop/category-right-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'end' }],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageCategory {...props} {...options} categorySlug="power-tools" />}
    />
));

const productLayouts = [
    ['/shop/product-standard', { layout: 'standard' }],
    ['/shop/product-columnar', { layout: 'columnar' }],
    ['/shop/product-sidebar', { layout: 'sidebar' }],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageProduct {...props} {...options} productSlug="brandix-screwdriver-screw1500acc" />}
    />
));

function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    const {token} =useSelector(state=> state.auth)
    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        {/*
                        // Shop
                        */}
                        <Redirect exact from="/shop" to="/shop/category" />
                        <Route
                            exact
                            path="/shop/category"
                            render={(props) => (
                                <ShopPageCategory {...props} columns={5} viewMode="grid" 
                                // sidebarPosition="start" 
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/category/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={5}
                                    viewMode="grid"
                                    // sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                />
                            )}
                        />
                        {/* Following category layouts only for demonstration. */}
                        {/* {categoryLayouts} */}

                        <Route
                            exact
                            path="/shop/products/:productSlug"
                            render={(props) => (
                                <ShopPageProduct
                                    {...props}
                                    layout="standard"
                                    productSlug={props.match.params.productSlug}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/accounts/order-produk/:pesananslug"
                            render={(props) => (
                                <ShopPageOrderGetById
                                    {...props}
                                    layout="standard"
                                    pesananslug={props.match.params.pesananslug}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/aplikator/alamat-survey/:alamatsurvey"
                            render={(props) => (
                                <AplikatorInputAlamatSurvey
                                    {...props}
                                    layout="standard"
                                    alamatsurvey={props.match.params.alamatsurvey}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/aplikator/detail-observasi/:detailobservasi"
                            render={(props) => (
                                <AplikatorDetailObeservasi
                                    {...props}
                                    layout="standard"
                                    detailobservasi={props.match.params.detailobservasi}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/aplikator/bayar-dp/:bayardp"
                            render={(props) => (
                                <AplikatorDetailPembayaranDp
                                    {...props}
                                    layout="standard"
                                    bayardp={props.match.params.bayardp}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/aplikator/bukti-bayar-dp/:buktibayardp"
                            render={(props) => (
                                <AplikatorBuktiPembayaranDp
                                    {...props}
                                    layout="standard"
                                    buktibayardp={props.match.params.buktibayardp}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/aplikator/email-akses-klien/:emailakses"
                            render={(props) => (
                                <AplikatorInputEmailKlien
                                    {...props}
                                    layout="standard"
                                    emailakses={props.match.params.emailakses}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/aplikator/pelunasan/:buktipelunasan"
                            render={(props) => (
                                <AplikatorPelunasan
                                    {...props}
                                    layout="standard"
                                    buktipelunasan={props.match.params.buktipelunasan}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/addendum/create/:revisiaplikator"
                            render={(props) => (
                                <AddendumCreatePage
                                    {...props}
                                    layout="standard"
                                    revisiaplikator={props.match.params.revisiaplikator}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/addendum/hasil-revisi/:cekdetailrevisi"
                            render={(props) => (
                                <AddendumDetailRevisi
                                    {...props}
                                    layout="standard"
                                    cekdetailrevisi={props.match.params.cekdetailrevisi}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/addendum/bukti-bayar/:buktibayaraddendum"
                            render={(props) => (
                                <AddendumBuktiPembayaran
                                    {...props}
                                    layout="standard"
                                    buktibayaraddendum={props.match.params.buktibayaraddendum}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/addendum/detail-revisi/:addendumdetail"
                            render={(props) => (
                                <AddendumDetail
                                    {...props}
                                    layout="standard"
                                    addendumdetail={props.match.params.addendumdetail}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/aplikator/detail-transaksi/:detailtransaksiaplikator"
                            render={(props) => (
                                <AplikatorDetailObeservasicuak
                                    {...props}
                                    layout="standard"
                                    detailtransaksiaplikator={props.match.params.detailtransaksiaplikator}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/account/addresses/:addressid"
                            render={(props) => (
                                <AccountPageEditAddress
                                    {...props}
                                    layout="standard"
                                    addressid={props.match.params.addressid}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/aplikator/validasi-rab/:validasirab"
                            render={(props) => (
                                <AplikatorValidasiRab
                                    {...props}
                                    layout="standard"
                                    validasirab={props.match.params.validasirab}
                                />
                            )}
                        />

                        {/* Following product layouts only for demonstration. */}
                        {productLayouts}

                      

                        {!token ? <Redirect from='/aplikator/pesan' to='/account/login'/> :
                        <Route exact path="/aplikator/pesan" component={AplikatorPage} />
                        }

                        {!token ? <Redirect from='/komisi-user/ajukan' to='/account/login'/> :
                        <Route exact path="/komisi-user/ajukan" component={PengajuanKomisi} />
                        }

                        {!token ? <Redirect from='/komisi-user' to='/account/login'/> :
                        <Route exact path="/komisi-user" component={komisipage} />
                        }

                        <Route exact path="/addendum/sukses-pesan" component={AddendumPageSukses} />
                        <Route exact path="/account/address-dua" component={AccountAddressDua} />
                        <Route exact path="/account/address-ketiga" component={AccountAddressTiga} />

                        {/* <Route exact path="/aplikator/pesan" component={AplikatorPage} /> */}
                        <Route exact path="/aplikator/sukses-pesan" component={AplikatorPageSukses} />
                        <Route exact path="/shop/cart" component={PageCart} />
                        <Route exact path="/shop/transaction" component={TransactionPage} />
                        <Route exact path="/shop/checkout" component={PageCheckout} />
                        <Route exact path="/shop/checkout/success" component={ShopPageOrderSuccess} />
                        <Route exact path="/shop/wishlist" component={PageWishlist} />
                        <Route exact path="/shop/compare" component={PageCompare} />
                        <Route exact path="/shop/track-order" component={ShopPageTrackOrder} />
                        <Route exact path="/shop/payment" component={PaymentPage} />
                        <Route exact path="/komisi-user/sukses" component={PengajuanKomisiSukses} />
                        {/*
                        // Blog
                        */}
                        <Redirect exact from="/blog" to="/blog/category-classic" />
                        <Route
                            exact
                            path="/blog/category-classic"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-grid"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-list"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="list" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/category-left-sidebar"
                            render={(props) => (
                                <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-classic"
                            render={(props) => (
                                <BlogPagePost {...props} layout="classic" sidebarPosition="end" />
                            )}
                        />
                        <Route
                            exact
                            path="/blog/post-full"
                            render={(props) => (
                                <BlogPagePost {...props} layout="full" />
                            )}
                        />

                        {/*
                        // Account
                        */}
                         { token? <Redirect from='/account/login'to='/'/>:
                        <Route exact path="/account/sign-up" component={AccountPageCreate} />
                        }
                        { token? <Redirect from='/account/login'to='/'/>:
                        <Route exact path="/account/login" component={AccountPageLogin} />
                        }
                        {!token ? <Redirect from='/account' to='/account/login'/> :
                        <Route path="/account" component={AccountLayout} />
                        }
                        {!token ? <Redirect from='/accounts' to='/account/login'/> :
                        <Route exact path="/accounts" component={AccountLayoutMobile} />
                        }
                        {!token ? <Redirect from='/accounts/dashboard' to='/account/login'/> :
                        <Route exact path="/accounts/dashboard" component={AccountPageDashboard} />
                        }
                        {!token ? <Redirect from='/accounts/profile' to='/account/login'/> :
                        <Route exact path="/accounts/profile" component={AccountPageProfile} />
                        }
                        {!token ? <Redirect from='/accounts/order-produk' to='/account/login'/> :
                        <Route exact path="/accounts/order-produk" component={AccountPageOrders} />
                        }
                        {!token ? <Redirect from='/aplikator/order-jasa' to='/account/login'/> :
                        <Route exact path="/aplikator/order-jasa" component={AplikatorPageOrders} />
                        }

                        {!token ? <Redirect from='/addendum/order-addendum' to='/account/login'/> :
                        <Route exact path="/addendum/order-addendum" component={AddendumPageOrders} />
                        }

                        {/* {!token ? <Redirect from='/aplikator/bayar-dp' to='/account/login'/> :
                        <Route exact path="/aplikator/bayar-dp" component={AplikatorDetailPembayaranDp} />
                        } */}

                        {/*
                        // Site
                        */}
                        <Redirect exact from="/site" to="/site/about-us" />
                        <Route exact path="/site/about-us" component={SitePageAboutUs} />
                        <Route exact path="/site/components" component={SitePageComponents} />
                        <Route exact path="/site/contact-us" component={SitePageContactUs} />
                        <Route exact path="/site/contact-us-alt" component={SitePageContactUsAlt} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/faq" component={SitePageFaq} />
                        <Route exact path="/site/terms" component={SitePageTerms} />
                        <Route exact path="/site/typography" component={SitePageTypography} />
                        {/* 
                        // Auth 
                        */}
                        <Route exact path='/auth/registration' component={Registration}/>
                        <Route exact path='/auth/reset-password' component={ResetPassword}/>

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                        
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
