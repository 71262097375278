export default {
    name: 'E-commerce Toko Bangunan Paling Murah Se Indonesia',
    fullName: 'Diskon sampai 90% .Ayo Buruan Belanja, Banyak Promo Menarik di sini.',
    url: 'https://www.hokkybangunan.co.id/',
    author: {
        name: 'Admin',
        profile_url: 'https://www.hokkybangunan.co.id/',
    },
    contacts: {
        address: 'Jl. Raya Menganti Karangan No.38, Babatan, Kec. Wiyung, Surabaya, Jawa Timur 60227',
        email: 'hokkybangunan@gmail.com',
        phone: '081-217-853-300',
    },
};
