// react
import React from 'react';
import { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
// data stubs
import theme from '../../data/theme';
import classNames from 'classnames';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confrimPassword, setConfirmPassword] = useState('');
  const [load, setLoad] = useState(false);

  const { search } = useLocation();
  //   console.log(search.split('=')[1]);
  let key = search.split('=')[1];

  const resetPass = async (e) => {
    e.preventDefault();
    let body = password;
    try {
      if (!password || !confrimPassword) {
        toast.error('Please enter your password!');
      } else if (password !== confrimPassword) {
        toast.error('Passwords do not match!');
      } else {
        setLoad(true);

        // eslint-disable-next-line no-unused-vars
        const result = await axios.post(
          `${process.env.REACT_APP_HOST_API}/auth/reset-password?key=${key}`,
          body
        );
        // console.log(result.data);
        setLoad(false);
        toast.success('Password have been reset!');
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
      let errorMsg =
        error.code === 'ERR_NETWORK'
          ? 'network error'
          : error.response
          ? error.response.data.message || error.response.data.error
          : error;
      toast.error(errorMsg);
    }
  };

  return (
    <div className='block'>
      <Helmet>
        <title>{`Reset Password — ${theme.name}`}</title>
      </Helmet>

      <div className='container'>
        <div className='not-found'>
          <div className='not-found__content'>
            <h1 className='not-found__title mt-5'>Buat password baru</h1>

            <p className='not-found__text'>
              Klik untuk membuat password baru
            </p>
            <div className='row'>
              <div className='col d-flex mt-4 mt-md-0'>
                <div className='card flex-grow-1 mb-0'>
                  <div className='card-body'>
                    <form onSubmit={resetPass}>
                      <div className='form-group'>
                        <input
                          type='password'
                          className='form-control'
                          placeholder='New Password'
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className='form-group'>
                        <input
                          type='password'
                          className='form-control'
                          placeholder='Confirm New Password'
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                      <button
                        disabled={load}
                        type='submit'
                        className={classNames(
                          'not-found__search-button btn btn-primary',
                          { 'btn-loading': load }
                        )}
                      >
                        Buat
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
