// react
import React,{Component} from "react";


// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy'
import axios from 'axios';


//db
import { getBanner } from "../../fake-server/database/axios";


// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');
    state = {
        slides : [],
        videoweb: "",
        videomobile: "",
        poster1 : "",
        poster2 : "",

    }

    slider = async () => {
        try {
            const result = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST_API}/iklan-ecommerce/page`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                data:{
                    page:this.state.page,
                    per_page: 10
                }
                
              });
           this.setState({videoweb : result.data.video_web })
           this.setState({videomobile : result.data.video_web })
           this.setState({poster1 : result.data.poster1_web })
           this.setState({poster2 : result.data.poster2_web })
        } catch (error) {
            console.log(error);
        }
    }
    // [
    //     {
    //         title: 'Big choice of<br>Plumbing products',
    //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
    //         image_classic: {
    //             ltr: 'images/slides/slide-1-ltr.jpg',
    //             rtl: 'images/slides/slide-1-rtl.jpg',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/slide-1-full-ltr.jpg',
    //             rtl: 'images/slides/slide-1-full-rtl.jpg',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/slide-1-mobile.jpg',
    //             rtl: 'images/slides/slide-1-mobile.jpg',
    //         },
    //     },
    //     {
    //         title: 'Screwdrivers<br>Professional Tools',
    //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
    //         image_classic: {
    //             ltr: 'images/slides/slide-2-ltr.jpg',
    //             rtl: 'images/slides/slide-2-rtl.jpg',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/slide-2-full-ltr.jpg',
    //             rtl: 'images/slides/slide-2-full-rtl.jpg',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/slide-2-mobile.jpg',
    //             rtl: 'images/slides/slide-2-mobile.jpg',
    //         },
    //     },
    //     {
    //         title: 'One more<br>Unique header',
    //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
    //         image_classic: {
    //             ltr: 'images/slides/slide-3-ltr.jpg',
    //             rtl: 'images/slides/slide-3-rtl.jpg',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/slide-3-full-ltr.jpg',
    //             rtl: 'images/slides/slide-3-full-rtl.jpg',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/slide-3-mobile.jpg',
    //             rtl: 'images/slides/slide-3-mobile.jpg',
    //         },
    //     },
    // ];

    componentDidMount() {
        const getOnGoing= async () => {
            try {
                const result = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_HOST_API}/iklan-ecommerce/page`,
                    
                    data:{
                        page:this.state.page,
                        per_page: 10
                    }
                    
                  });
               this.setState({videoweb : result.data.response.video_web })
               this.setState({videomobile : result.data.response.video_web })
               this.setState({poster1 : result.data.response.poster1_web })
               this.setState({poster2 : result.data.response.poster2_web })
            } catch (error) {
                console.log(error);
            }
        }
            getOnGoing()
        // this.slider()
        // if (this.media.addEventListener) {
        //     this.media.addEventListener('change', this.onChangeMedia);
        // } else {
        //     // noinspection JSDeprecatedSymbols
        //     this.media.addListener(this.onChangeMedia);
        // }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {

        const { videoweb, videomobile, poster1,poster2} = this.state;

        // useEffect(() => {
        //     getLinkIklan()
        // }, [])
        // const {  token } = props;
        // const [videoweb, setVideoWeb] = useState("") 
        // const [videoweb1, setVideoWeb1] = useState("") 
        // const [posterweb1, setPosterWeb1] = useState("") 
        // const [posterweb2, setPosterWeb2] = useState("") 
        
        // const getLinkIklan = async () => {
        //     const result = await axios({
        //         method: "POST",
        //         url: `${process.env.REACT_APP_HOST_API}/iklan-ecommerce/page`,
        //         headers: {
        //             Authorization: `Bearer ${token}`,
        //         },
        //     });
        //     setListBank(result.data.response);
        //     setVideoWeb(result.data.response.video_web)
        // };

        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-7': !withDepartments,
                'col-lg-7': withDepartments,
            },
        );

        const layoutClassess = classNames(
            'col-12',
            {
                'col-lg-5': !withDepartments,
                'col-lg-5': withDepartments,
            },
        );

        const slides = this.state.slides && this.state.slides.map((slide, index) => {
            // console.log(slide.image_url);
            const image = (withDepartments ? slide.image_url : slide.image_full)[direction];

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${slide.image_url})`,
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.image_url[direction]})`,
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        <div className={layoutClasses}>
                            <div className=" col-8 block-slideshow__body d-lg-block d-none">
                                    <ReactPlayer 
                                        url={videoweb}
                                        pip={true}
                                        muted={true}
                                        loop={true}
                                        playing={true}
                                    />
                            </div>
                        </div>
                        {/* {withDepartments && ( */}
                        <div className={layoutClassess}>
                            &nbsp; <div className="col-4 d-lg-block d-none" >
                            <img src={poster1} alt="" width={430} height={165}></img>
                            &nbsp;
                            <img src={poster2} alt="" width={430} height={165}></img>
                            </div>
                        </div>
                        {/* )} */}
                        
                        {/* <div className="block-slideshow__body  d-lg-block d-none"
                        style={{ backgroundImage: 'url("images/banners/bannertok.png")' }}
                        /> */}
                    </div>
                    {/* warna kuning */}
                    {/* <div className="mobile-header__panelse d-md-none d-lg-none d-xl-none">
                            <div className="mobile-header__bodysse"></div>
                    </div> */}
                    <div className="mobile-header__panels d-md-none d-lg-none d-xl-none" >
                            <div className="mobile-header__bodyss">
                                <ReactPlayer 
                                    url={videomobile}
                                    pip={true}
                                    muted={true}
                                    loop={true}
                                    playing={true}
                                    width='100%'
                                    height='100%'
                                />
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
