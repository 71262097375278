// react
import React, { useEffect, useState } from 'react';

// third-party
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem } from '../../store/cart';
import { url } from '../../services/utils';
import axios from 'axios';
import { updateAction } from '../../store/auth/authActions';
import { toast } from 'react-toastify';

function IndicatorCart(props) {
    const {  token, updateStatus } = props;
    const [cart, setProduct] = useState([]);
    const [listso, setProducts] = useState([]);
    const [productId, setProductId] = useState(false);
    const [cartTotal, setCartTotal] = useState(0);
    const [priceTotal, setPriceTotal] = useState(0)
    const [loadingCO, setLoadingCO] = useState(false);
    let dropdown;
    let totals;
    
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const getCart = async () => {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_HOST_API}/cart/list`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setProduct(result.data?.response);
          setProductId(result.data?.response.map((item) => item.id));
          const totalQty = result.data?.response.map((item) => item.qty);
          // console.log(totalQty);
          let sum = 0;
    
          for (let i = 0; i < totalQty.length; i++) {
            sum += totalQty[i];
          }
          setCartTotal(sum);
          const totalPrice = result.data?.response.map((item) => item.sub_total);
            // console.log(totalPrice);
            let sumPrice = 0;
      
            for (let i = 0; i < totalPrice.length; i++) {
                sumPrice += totalPrice[i];
            }
          
          setPriceTotal(sumPrice);
        };
        const getSo = async () => {
            const result = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_HOST_API}/so-commerce/list-by-user`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setProducts(result.data.response);
          };
        if(token){

            getCart();
            getSo();
        }
        if (updateStatus) {
            const getCart = async () => {
                const result = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_HOST_API}/cart/list`,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                setProduct(result.data?.response);
                setProductId(result.data?.response.map((item) => item.id));
                const totalQty = result.data?.response.map((item) => item.qty);
                // console.log(totalQty);
                let sum = 0;
          
                for (let i = 0; i < totalQty.length; i++) {
                  sum += totalQty[i];
                }
                setCartTotal(sum);
                const totalPrice = result.data?.response.map((item) => item.sub_total);
            // console.log(totalPrice);
            let sumPrice = 0;
      
            for (let i = 0; i < totalPrice.length; i++) {
                sumPrice += totalPrice[i];
            }
            setPriceTotal(sumPrice);
              };

            const getSo = async () => {
                const result = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_HOST_API}/so-commerce/list-by-user`,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                setProducts(result.data.response);
              };
          getCart();
          getSo();
        }
      }, [token, updateStatus]);

      const cartRemoveItem = async (id,body)=>{
        try {
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/cart/delete/${id}`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                data: body,
              });
              toast.success('Product removed')
              dispatch(updateAction(true))
              setTimeout(()=>{
                dispatch(updateAction(false))
              },1000)
        } catch (error) {
            console.log(error);
            toast.error('There was an error removing the product')
        }
    }

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

    // const checkoutHandler= ()=>{
        
    //           history.push('/shop/checkout')
        
    // }

    // const checkoutHandler = async (body) => {
    //     const { token, history, dispatch, } = this.props;    
    //     const user_code = localStorage.getItem('user_code');
    //     // let bank_id = this.state.listBank.find(x => x.bank_name == this.state.currentPayment).id;
    
    
    //     let param = {
    //       user_code : user_code,
    //       list_cart : body.cart_id
    //     }
    
    //     try {
    //       this.setState({ loadingCO: true });
    //       const result = await axios({
    //         method: 'POST',
    //         url: `${process.env.REACT_APP_HOST_API}/so-commerce/do-checkout`,
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //         data: param,
    //       });
    //       this.setState({ loadingCO: false });
    //       console.log(result.data.response);
    //       localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
    //       toast.success(`${result.data.response}`);
    //       this.setState({ paid: true });
    //       dispatch(updateAction(true));
    //       setTimeout(() => {
    //         dispatch(updateAction(false));
    //       }, 1000);
    //       history.push('/shop/checkout');
    //     //   this.props.history.push('/shop/checkout')
    //     } catch (error) {
    //       this.setState({ loadingCO: false });
    //       console.log(error);
    //       toast.error(error.message);
    //     }
    //   };
    
    // if (cart.extraLines.length > 0) {
    //     const extraLines = cart.extraLines.map((extraLine, index) => (
    //         <tr key={index}>
    //             <th>{extraLine.title}</th>
    //             <td><Currency value={extraLine.price} /></td>
    //         </tr>
    //     ));

        totals = (
            
            <React.Fragment>
                <tr>
                    <th>Sub Total</th>
                    <td>{formatRupiah(priceTotal)}</td>
                </tr>
                {/* {extraLines} */}
            </React.Fragment>
        );
    // }

    const items = cart.map((item) => {
        let options;
        let image;

        // if (item.options) {
        //     options = (
        //         <ul className="dropcart__product-options">
        //             {item.options.map((option, index) => (
        //                 <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
        //             ))}
        //         </ul>
        //     );
        // }

        if (item) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item.item_id)} className="product-image__body">
                        <img className="product-image__img" src={item.image_url} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id,{qty:0})}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item.item_id)}>{item.item_name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.qty}</span>
                        {' × '}
                        <span className="dropcart__product-price">{formatRupiah(item.price_6)}</span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    // const formatRupiah = (money) => {
    //     return new Intl.NumberFormat('id-ID',
    //         { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    //     ).format(money);
    //   }

    if (cart.length>0) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th>Total</th>
                                <td>{formatRupiah(priceTotal)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart">Keranjang</Link>
                    {/* <div className={classNames("btn btn-primary",{'btn-loading': loadingCO})} onClick={() => {
                            checkoutHandler();
                          }}>Checkout</div> */}
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                Keranjangmu Kosong, yuk belanja dulu!
                </div>
            </div>
        );
    }

    return (
        <Indicator url="/shop/cart" dropdown={dropdown} value={cartTotal} icon={<Cart20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    listso : state.listso,
    token: state.auth.token,
    updateStatus: state.auth.update
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
