// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { updateAction } from '../../store/auth/authActions';

function ProductCardKategori(props) {
    const {
        produkkategori,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

    let badges = [];
    let image;
    let price;
    let features;

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(produkkategori.id)} className="product-image__body">
                    <img className="product-image__img" src={produkkategori.image_url} alt="" />
                </Link>
            </div>
        );

        price = (
            <div className="product-card__prices">
                {/* <Currency value={product.price_6 || 200000} /> */}
                {formatRupiah(produkkategori.price_6)}
            </div>
        );
    // }

    const [qty, setQty] = useState(1)
    const [loadingCart, setLA]=useState(false)
    const [update, setUpdate]=useState(false)

    const {token} = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const add2Cart = async (body) => {
        try {
          if (!token) {
            // dispatch(setModalAction());
            console.log('no token')
            toast.error('You need to login first')
           return <Redirect to='/account/login'/>;
          } else {
            setLA(true);
            const result = await axios({
              method: "POST",
              url: `${process.env.REACT_APP_HOST_API}/cart/save`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: body,
            });
            setLA(false);
            setUpdate(true);
            setTimeout(() => {
              setUpdate(false);
            }, 500);
            console.log(result);
            toast.success('Product successfully added')
            dispatch(updateAction(true))
              setTimeout(()=>{
                dispatch(updateAction(false))
              },1000)
          }
        } catch (error) {
          setLA(false);
          console.log(error);
          toast.error('There was an error saving your cart')
        }
      };

    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(produkkategori.id)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__rating">
                    {/* <Rating value={product.rating} /> */}
                    <div><span className="text-success" style={{fontSize:"12px"}}>{produkkategori.warehouse_name}</span></div>
                </div>
                <div className="product-card__name">
                    {/* <Link to={url.product(produkkategori.item_id)}>{produkkategori.item_name}</Link> */}
                    <Link to={url.product(produkkategori.item_id)}>
                        <span style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 2,
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                        }} >
                            {produkkategori.item_name}
                        </span>
                    </Link>
                </div>
                {/* <div className="product-card__rating">
                    <Rating value={produkkategori.rating} />
                    <div className=" product-card__rating-legend">{`${produkkategori.reviews || ""} Reviews`}</div>
                </div> */}
                {/* {features} */}
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    Availability:
                    <span className="text-success">In Stock</span>
                </div>
                {price}
                <div className="product-card__buttons">
                    <AsyncAction
                        action={() => add2Cart({
                            item_id: produkkategori.item_id,
                            qty: qty,
                            warehouse_id : produkkategori.warehouse_id,
                            type : produkkategori.type,
                            price_6 : produkkategori.price_6
                          })}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                {/* <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Beli
                                </button> */}
                                {/* <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    Beli
                                </button> */}
                            </React.Fragment>
                        )}
                    />
                    {/* <AsyncAction
                        action={() => wishlistAddItem(produkkategori)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    <AsyncAction
                        action={() => compareAddItem(produkkategori)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    /> */}
                </div>
            </div>
        </div>
    );
}

ProductCardKategori.propTypes = {
    /**
     * product object
     */
    produkkategori: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCardKategori);
