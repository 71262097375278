// react
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,Modal, ModalHeader, ModalBody, ModalFooter
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';
import { Check9x7Svg } from '../../svg';

import { updateAction } from '../../store/auth/authActions';
// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';

export default function AddendumDetail(props) {
    let history = useHistory()
    const [modal, setModal] = useState(false);
  	const toggle = () => setModal(!modal);
    const   { addendumdetail} = props;
    const   {token, update}= useSelector((state) => state.auth)
    const   [isLoading, setIsLoading] = useState(true);
    const   [orderInfo, setInfo] = useState(false)
    const   [kode, setKode] = useState("")
    const   [alamat, setAlamat] = useState("")
    const   [tanggal, setTanggal] = useState("")
    const   [centang, setCentang] = useState("")
    const   [bank, setBank] = useState("")
    const   [banks, setBanks] = useState([])
    const   [Total, setTotal] = useState("")
    const   dispatch= useDispatch()

    useEffect(() => {
        getBank()
    }, [])

    const getBank = async ()=>{
        try {
            const result = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_HOST_API}/bank/list`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              setBanks(result.data.response)
        } catch (error) {
            // console.log(error);
            toast.error('There was an error!');
        }
    }

    const   updateHandler = async ()=>{

        // function CreateData() {
        //     let dataItems = [];
        //         savedItems.map((dataItem) => dataItems = [...dataItems, 
        //             { 
        //               item_id: dataItem.item_id, 
        //               code_rak : dataItem.code_rak,
        //               item_name:dataItem.item_name,
        //               qty: dataItem.qty, 
        //               reason:dataItem.reason,
        //               ket:dataItem.ket,
        //             }]);
        //     let data = {
        //       status_d : 3,
        //       warehouse_id : parseInt(warehouse),
        //       username : username,
        //       keterangan: keterangan ,
        //       items : dataItems
        //     };
        //     axios
        //         .post(
        //           `${process.env.REACT_APP_API_BASE_URL}/adjustment/update/${userid}`,
        //           data,
        //           {
        //             headers: {
        //               Authorization: `Bearer ${token}`,
        //             },
        //           }
        //         )
        //         .then(function (response) {
        //           setSuccessAlert(response.data.message);
        //           setTimeout(() => (history.push("/admin/stock-adjustment/page")), 2000);
        //           })
        //           .catch(function (error) {
        //             setGagalAlert(error.response.data.message);
        //           });
        //     }

        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/oa-revisi/update-tagihan-by-klien/${addendumdetail}`,
                data:{
                    rv_state : centang === "" ? 3 : 5 ,
                    account_number : bank
                    // warehouse_id: toko,
                    // code_aplikator : kode,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('Pembayaran Sukses')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  history.push("/addendum/order-addendum")
            
        } catch (error) {
            console.log(error);
            toast.error('Pembayaran Gagal')
            
        }
    }

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }
    const items = order.items.map((item) => {
        const options = (item.options || []).map((option) => (
            <li className="order-list__options-item">
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));
        // useEffect(()=>{
        //     setInfo(JSON.parse(localStorage.getItem('CO-INFO')))
            
        // },[])

        useEffect(() => {
            // let canceled = false;
    
            setIsLoading(true);

            const getUserId = (id) =>{
                axios({
                 method: "get",
                 url: `${process.env.REACT_APP_HOST_API}/oa-revisi/get/${addendumdetail}`,
                 headers: {
                   Authorization: `Bearer ${token}`,
                 },
               })
               .then(data => {
                setKode(data.data.response)
             })
               .catch(function (error) {
               })
            }
    
            // const getProductById = async ()=>{
            //     try {
            //         const result = await axios.get(`${process.env.REACT_APP_HOST_API}/transaction/get/${addendumdetail}`, {headers: {
            //             Authorization: `Bearer ${token}`,
            //           },})
            //         // setProduct(result.data.response)
            //         setKode(result.data.response)
            //         setTanggal(result.data.response)
            //         setTotal(result.data.response)
            //         setBank(result.data.response)
            //         // console.log(result.data.data);
            //         setIsLoading(false)
            //     } catch (error) {
            //         console.log(error);
            //         setIsLoading(false);
            //     }
            // }
    
            // shopApi.getProductBySlug(productSlug).then((product) => {
            //     if (canceled) {
            //         return;
            //     }
    
            //     setProduct(product);
            // });
            getUserId()
        }, [addendumdetail, setIsLoading]);

        
        // console.log(orderInfo)
        return (
            <tr>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <Link to={url.product(item)} className="product-image__body">
                            <img className="product-image__img" src={item.image} alt="" />
                        </Link>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <Link to={url.product(item)}>{item.name}</Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {options}
                            </ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title="Qty:">{item.quantity}</td>
                <td className="order-list__column-total"><Currency value={item.total} /></td>
            </tr>
        );
    });

    const additionalLines = order.additionalLines.map((line) => (
        <tr>
            <th className="order-list__column-label" colSpan="3">{line.label}</th>
            <td className="order-list__column-total"><Currency value={line.total} /></td>
        </tr>
    ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet><br></br>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/addendum/order-addendum">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div>
            </div>
            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        {/* <Check100Svg className="order-success__icon" /> */}
                        <div className="order-success__subtitle"><h4>Detail Revisi</h4></div>
                        <div className="order-success__subtitle"><font size='5px' color='red'>{kode.oa_code}</font></div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Tanggal Transaksi</span>
                                <span className="order-success__meta-value">{kode.transaction_date}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Ongkir</span>
                                <span className="order-success__meta-value">{formatRupiah(kode.ongkir)}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Durasi Pengerjaan</span>
                                <span className="order-success__meta-value">{kode.durasi_pengerjaan} Hari</span>
                            </li>
                           
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Upah Gambar</span>
                                <span className="order-success__meta-value">{formatRupiah(kode.upah_gambar)}</span>
                            </li>
                           
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Keterangan</span>
                                <span className="order-success__meta-value">{kode.keterangan_admin}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Harga Jasa</span>
                                <span className="order-success__meta-value">{formatRupiah(kode.upah_jasa)}</span>
                            </li>
                            <li className="order-success__meta-item">
                                {/* <span className="order-success__meta-title">Total:</span>
                                <span className="order-success__meta-value">{formatRupiah(kode.payment_total)}</span> */}
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total</span>
                                <span className="order-success__meta-value">{formatRupiah(kode.payment_total)}</span>
                            </li>
                            {/* <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Pembayaran:</span>
                                <span className="order-success__meta-value">{kode.bank_name}</span>
                                <span className="order-success__meta-value">{kode.account_number}</span>
                            </li> */}
                        </ul>
                    </div>
                    {/* <div className="order-success__meta">
                        <div className="order-success__actions">
                            <Link to="/accounts/order-produk" className="btn btn-xs btn-warning">Kembali</Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
