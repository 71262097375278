import React from "react";
import './file-styles.css'
import { Col, Row } from "reactstrap";
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
// import ImageIcon from '@mui/icons-material/Image';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const SelectFile = ({
  onclick,
  imageSrc,
  htmlFor
}) => {
  return (
    <Row>
      <Col>
        <div class="dashed" onClick={() => onclick()} htmlFor={htmlFor}>
          {
            imageSrc != null ?
            <img
              src={imageSrc}
            />
            :
            <div>
              {/* <AddPhotoAlternateIcon color="success" fontSize="large" /> */}
              <p>Bukti Transfer</p>
            </div>
          }
        </div>
      </Col>
    </Row>

  )
}

export default SelectFile;