// react
import axios from 'axios';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// data stubs
import theme from '../../data/theme';
import { updateAction } from '../../store/auth/authActions';

export default function AddendumCreatePage(props) {
    let history = useHistory()
    const   { revisiaplikator} = props;
    const   [isLoading, setIsLoading] = useState(true);
    const userid = localStorage.user_id
    const [picture, setPicture] = useState(false)
    const [toko, setToko] = useState('')
    const [tokos, setTokos] = useState([])
    const [kode, setKode] = useState("")
    const [kodes, setKodes] = useState([])
    const [harga, setHarga] = useState("");
    const Estimasi = harga+100000
    const [gender, setGender] = useState('')
    const {token, update}= useSelector((state) => state.auth)
    console.log(userid);
    // console.log(picture)
    const dispatch= useDispatch()

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

    useEffect(() => {
        // GetToko()
        // getAplikatorList()
    }, [])
    

    const GetToko = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        axios
          .get(
            `${process.env.REACT_APP_HOST_API}/warehouse/cabang-toko`,{headers}
          )
          .then((data) => {
            setTokos(data.data.response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const getAplikatorList = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        axios
          .get(
            `${process.env.REACT_APP_HOST_API}/aplikator/list`,{headers}
          )
          .then((data) => {
            setKodes(data.data.response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const getAplikator = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        axios
          .get(
            `${process.env.REACT_APP_HOST_API}/aplikator/get-by-code/${id}`,{headers}
          )
          .then((data) => {
            // setKodes(data.data.response);
            setHarga(data.data.response.upah_jasa)
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      useEffect(() => {
        // let canceled = false;

        setIsLoading(true);

        const getUserId = (id) =>{
            axios({
             method: "get",
             url: `${process.env.REACT_APP_HOST_API}/order-aplikator/get/${revisiaplikator}`,
             headers: {
               Authorization: `Bearer ${token}`,
             },
           })
           .then(data => {
            setKode(data.data.response.oa_code)
         })
           .catch(function (error) {
           })
        }

        // const getProductById = async ()=>{
        //     try {
        //         const result = await axios.get(`${process.env.REACT_APP_HOST_API}/transaction/get/${revisiaplikator}`, {headers: {
        //             Authorization: `Bearer ${token}`,
        //           },})
        //         // setProduct(result.data.response)
        //         setKode(result.data.response)
        //         setTanggal(result.data.response)
        //         setTotal(result.data.response)
        //         setBank(result.data.response)
        //         // console.log(result.data.data);
        //         setIsLoading(false)
        //     } catch (error) {
        //         console.log(error);
        //         setIsLoading(false);
        //     }
        // }

        // shopApi.getProductBySlug(productSlug).then((product) => {
        //     if (canceled) {
        //         return;
        //     }

        //     setProduct(product);
        // });
        getUserId()
    }, [revisiaplikator, setIsLoading]);

    

    const updateHandler = async ()=>{
        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/oa-revisi/save`,
                data:{keterangan_user: toko,
                    code_oa : kode,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('Revisi Aplikator Terbuat')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  history.push("/addendum/sukses-pesan")
            
        } catch (error) {
            console.log(error);
            toast.error('Revisi Aplikator Gagal Terbuat')
            
        }
    }
    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>
            <div className="container">
            <div className="card-header">
                {/* <h5>Edit Profile</h5> */} 
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/accounts/dashboard">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div> */}
                <h3><center>Revisi Project </center></h3>

                <div style={{ textAlign: 'right' }}>
                    <h3></h3>
                </div>
            </div>
            </div>
            {/* <div className="card-divider" /> */}
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Keterangan Revisi</label>
                            <Input
                                id="profile-first-name"
                                type="textarea"
                                rows = "5"
                                className="form-control"
                                placeholder="Masukan apa yang harus di revisi"
                                value={toko}
                                onChange={e => setToko(e.target.value)}
                            >
                                 <option value=""  selected>Pilih Toko</option>
                                  {tokos.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.id}>
                                          {toko.name} 
                                        </option>
                                    );
                                  })}
                                </Input>
                        </div> 
                        {/* <div className="form-group">
                            <label htmlFor="profile-last-name">Aplikator</label>
                            <Input
                                id="profile-last-name"
                                type="select"
                                className="form-control"
                                placeholder="Last Name"
                                value={kode}
                                onChange={e => {
                                  setKode(e.target.value);
                                  getAplikator(e.target.value)
                                }
                              }
                            >
                                 <option value=""  selected>Pilih Aplikator</option>
                                  {kodes.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.aplikator_code}>
                                          {toko.name} 
                                        </option>
                                    );
                                  })}
                            </Input>
                        </div> */}
                        {/* <div className="form-group">
                            {kode === "" ? "" : <label htmlFor="profile-last-name">Estimasi harga Jasa adalah {formatRupiah(harga)} - {formatRupiah(Estimasi)}</label>}
                        </div> */}
                        <div className="form-group mt-5 mb-0">
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}
