// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import brands from '../../data/shopBrands';

const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 6000,
    cssEase: "linear",
    responsive: [        
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
};

export default function BlockBrands() {

    const brandsList = brands.map((brand, index) => (
        
        <div key={index} className="block-brands__item">
            <img src={brand.image} alt="" />
            <center>{brand.name}</center>
        </div>
    ));

    return (
        <div className="block block-brands">
            <div className="container">
                <div className="block-brands__slider">
                    <StroykaSlick {...slickSettings}>
                        {brandsList}
                    </StroykaSlick>
                </div>
            </div>
        </div>
    );
}
