// react
import React, { Component, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy'

// application
import Indicator from '../header/Indicator';
import {
    LogoSmallSvg,
    Search20Svg,
    Heart20Svg,
    MobilePersonSvg,
    MobileCartSvg,
    MobileKategoriSvg,
    MobileWhatsappSvg,
    MobileHomeSvg,
    BellSvg,
    Cart20Svg,
    Menu18x14Svg,
    Cart20Svgs,

} from '../../svg';
import { mobileMenuOpen } from '../../store/mobile-menu';
import Search from '../header/Search';
import FloatingWhatsApp from 'react-floating-whatsapp';
import 'react-floating-whatsapp-button/dist/index.css'

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
            cartTotal : 0,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    componentDidMount(){
        const {token, updateStatus } =this.props
        const {cartTotal} = this.state
        const getCart = async () => {
            const result = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_HOST_API}/cart/list`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const cartTotal = result.data?.response.map((item) => item.qty);
            // console.log(cartTotal);
            let sum = 0;
      
            for (let i = 0; i < cartTotal.length; i++) {
              sum += cartTotal[i];
            }
            this.setState({cartTotal: sum});
            // console.log();
            // console.log(this.state.cartTotal);
          };
          if(token ||updateStatus){
              getCart();
          }
    }
    

    render() {
        const { openMobileMenu, wishlist, cart,updateStatus,token } = this.props;
        const { searchOpen, cartTotal } = this.state;
        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--open': searchOpen,
        });
        
        // console.log(cartTotal);
        // useEffect(() => {
        //     const getCart = async () => {
        //       const result = await axios({
        //         method: "GET",
        //         url: `${process.env.REACT_APP_HOST_API}/cart/list`,
        //         headers: {
        //           Authorization: `Bearer ${token}`,
        //         },
        //       });
        //       const totalQty = result.data?.response.map((item) => item.qty);
        //       // console.log(totalQty);
        //       let sum = 0;
        
        //       for (let i = 0; i < totalQty.length; i++) {
        //         sum += totalQty[i];
        //       }
        //       this.setState({cartTotal: sum});
        //     };
        //     if(token){
    
        //         getCart();
        //     }
        //     if (updateStatus) {
        //         const getCart = async () => {
        //             const result = await axios({
        //               method: "GET",
        //               url: `${process.env.REACT_APP_HOST_API}/cart/list`,
        //               headers: {
        //                 Authorization: `Bearer ${token}`,
        //               },
        //             });
        //             const totalQty = result.data?.response.map((item) => item.qty);
        //             let sum = 0;
              
        //             for (let i = 0; i < totalQty.length; i++) {
        //               sum += totalQty[i];
        //             }
        //             this.setState({cartTotal: sum});
        //           };
    
        //       getCart();
        //     }
        //   }, [token, updateStatus]);
    

        // console.log(cart);
        // console.log("cartotal" + cartTotal);
        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <Link to="/" className="mobile-header__logo"><LogoSmallSvg /></Link>
                            <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            />
                            <div className="mobile-header__indicators">
                                <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile d-sm-flex d-none"
                                    url="/shop/wishlist"
                                    value={wishlist.length}
                                    icon={<Heart20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile"
                                    url="/shop/cart"
                                    value={cartTotal}
                                    icon={<MobileCartSvg width="20" height="20" />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-header__panels">
                    <div className="container">
                        <div className="mobile-header__bodys">
                        </div>
                    </div>
                </div>

                {/* <div className="mobile-header__panelss">
                    <div className="container">
                        <div className="mobile-header__bodyss">
                            <ReactPlayer 
                                url='https://hbs-sby.s3.ap-southeast-1.amazonaws.com/HOKKY+BANGUNAN+PROFILE+VIDEO.mp4'
                                pip={true}
                                muted={true}
                                loop={true}
                                playing={true}
                            />
                        </div>
                    </div>
                </div> */}

                {/* whatsapp */}

                {/* <FloatingWhatsApp
                    className="d-md-block d-lg-block d-xl-block d-none"
                    phoneNumber="081217853300"
                    accountName="Hokky Bangunan"
                    statusMessage= "Online 5 menit yang lalu"
                    chatMessage = "Selamat datang di Hokky Bangunan. Ada yang bisa saya Bantu ?"
                    avatar = "https://hbs-sby.s3.ap-southeast-1.amazonaws.com/logo_hokky.png"
                    allowClickAway
                /> */}

                {/* navigasi melayang */}
                <center>
                <nav className="nav-bawah-mobile navbar navbar-dark navbar-expand text-align-center d-md-none d-lg-none d-xl-none fixed-bottom ">
                    <ul className="navbar-nav nav-justified w-100">
                        {/* home */}
                        <li className="nav-item">
                            <a href="/" className="nav-link">
                                <button type="button" className="mobile-header__menu-button">
                                <MobileHomeSvg width="25" height="25" />
                                </button>
                            </a>
                        </li>
                        {/* kategori */}
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link">
                                <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                                <MobileKategoriSvg width="25" height="25" />
                                </button>
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a href="/shop/category" className="nav-link">
                                <button type="button" className="mobile-header__menu-button">
                                <MobileKategoriSvg width="25" height="25" />
                                </button>
                            </a>
                        </li>
                        {/* cart */}
                        <li className="nav-item">
                            <a href="/shop/cart" className="nav-link">
                                <button type="button" className="mobile-header__menu-button">
                                <MobileCartSvg width="25" height="25" />
                                </button>
                            </a>
                        </li>
                        {/* whatsapp */}
                        <li className="nav-item">
                            <a href="https://api.whatsapp.com/send/?phone=+6281217853300&text=Hallo%20Hokky%20Bangunan%20saya%20mau%20tanya." className="nav-link">
                                <button type="button" className="mobile-header__menu-button">
                                <MobileWhatsappSvg width="25" height="25" />
                                </button>
                            </a>
                        </li>
                        {/* Account */}
                        <li className="nav-item">
                            <a href="/accounts" className="nav-link">
                                <button type="button" className="mobile-header__menu-button">
                                <MobilePersonSvg width="25" height="25" />
                                </button>
                            </a>
                        </li>
                    </ul>
                </nav>
                </center>
            </div>
        );
        
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileHeader);
