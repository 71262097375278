// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { getBanner } from "../../fake-server/database/axios";

// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockBannerSlideShow1 extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');
    state = {
        slides : []
    }

    componentDidMount() {
        const getOnGoing= async () => {
            try {
                const result = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_HOST_API}/iklan-ecommerce/carousel2`,
                    
                    data:{
                        page:this.state.page,
                        per_page: 10
                    }
                    
                  });
               this.setState({slides : result.data.response })
              
            } catch (error) {
                console.log(error);
            }
        }
            getOnGoing()
        // this.slider()
        // if (this.media.addEventListener) {
        //     this.media.addEventListener('change', this.onChangeMedia);
        // } else {
        //     // noinspection JSDeprecatedSymbols
        //     this.media.addListener(this.onChangeMedia);
        // }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];
        const { slides } = this.state;

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--fulls': !withDepartments,
                'block-slideshow--layout--with-departmentss': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        const slidess = this.state.slides.map((slide, index) => {
            // const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];

            return (
                <div key={index} className="block-slideshow__slides">
                    <div
                        className="block-banner__image block-banner__image--desktop d-lg-block d-none"
                        style={{
                            backgroundImage: `url(${slide.url_web})`,
                        }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile d-lg-none d-xl-none"
                        style={{
                            backgroundImage: `url(${slide.url_mobile})`,
                        }}
                    />
                    <div className="block-slideshow__slide-contents">
                        <div
                            className="block-slideshow__slide-titles"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <div
                            className="block-slideshow__slide-texts"
                            dangerouslySetInnerHTML={{ __html: slide.text }}
                        />
                        <div className="block-slideshow__slide-buttons">
                            {/* <Link to="/" className="btn btn-primary btn-lg">Shop Now</Link> */}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__bodys">
                                <StroykaSlick {...slickSettings}>
                                    {slidess}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockBannerSlideShow1.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockBannerSlideShow1.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockBannerSlideShow1);
