// react
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import PageHeader from '../shared/PageHeader';
import { toast } from 'react-toastify';
// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

import { updateAction } from '../../store/auth/authActions';
// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';

export default function AplikatorInputAlamatSurvey(props) {
    let history = useHistory()
    const   { alamatsurvey} = props;
    const   {token, update}= useSelector((state) => state.auth)
    const   [isLoading, setIsLoading] = useState(true);
    const   [orderInfo, setInfo] = useState(false)
    const   [kode, setKode] = useState("")
    const   [alamat, setAlamat] = useState("")
    const   [alamats, setAlamats] = useState([])
    const   [tanggal, setTanggal] = useState("")
    const   [Total, setTotal] = useState("")
    const   [Bank, setBank] = useState("")
    const   [address, setAddress] = useState("")
    const   [kurir, setKurir] = useState("")
    const   [kurirs, setKurirs] = useState([])
    const   dispatch= useDispatch()
    const   updateHandler = async ()=>{

        // function CreateData() {
        //     let dataItems = [];
        //         savedItems.map((dataItem) => dataItems = [...dataItems, 
        //             { 
        //               item_id: dataItem.item_id, 
        //               code_rak : dataItem.code_rak,
        //               item_name:dataItem.item_name,
        //               qty: dataItem.qty, 
        //               reason:dataItem.reason,
        //               ket:dataItem.ket,
        //             }]);
        //     let data = {
        //       status_d : 3,
        //       warehouse_id : parseInt(warehouse),
        //       username : username,
        //       keterangan: keterangan ,
        //       items : dataItems
        //     };
        //     axios
        //         .post(
        //           `${process.env.REACT_APP_API_BASE_URL}/adjustment/update/${userid}`,
        //           data,
        //           {
        //             headers: {
        //               Authorization: `Bearer ${token}`,
        //             },
        //           }
        //         )
        //         .then(function (response) {
        //           setSuccessAlert(response.data.message);
        //           setTimeout(() => (history.push("/admin/stock-adjustment/page")), 2000);
        //           })
        //           .catch(function (error) {
        //             setGagalAlert(error.response.data.message);
        //           });
        //     }

        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/order-aplikator/update-lokasi-survey/${alamatsurvey}`,
                data:{
                    address_id : address,
                    kurir_id : kurir
                    // warehouse_id: toko,
                    // code_aplikator : kode,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('Tambah Alamat Sukses')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  history.push("/aplikator/order-jasa")
            
        } catch (error) {
            console.log(error);
            toast.error('Tambah Alamat Gagal')
            
        }
    }

    useEffect(() => {
        getUser()
        getKurir()
        getAddress()
    }, [])
    

    const getUser = () =>{
        axios({
         method: "GET",
         url: `${process.env.REACT_APP_HOST_API}/auth/info`,
         headers: {
           Authorization: `Bearer ${token}`,
         },
       })
       .then(data => {
        setAddress(data.data.response.address_id)
         // setUserData(data.data.response);
         // setUsername(data.data.response.username)
        //  getUserCok(data.data.response.username)
     })
       .catch(function (error) {
       })
       
   }

   const getKurir = () =>{
    axios({
     method: "GET",
     url: `${process.env.REACT_APP_HOST_API}/kurir/aktif`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
    setKurirs(data.data.response)
     // setUserData(data.data.response);
     // setUsername(data.data.response.username)
    //  getUserCok(data.data.response.username)
 })
   .catch(function (error) {
   })
   
}

const getAddress = () =>{
    axios({
     method: "GET",
     url: `${process.env.REACT_APP_HOST_API}/address/list`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
    setAlamats(data.data.response)
     // setUserData(data.data.response);
     // setUsername(data.data.response.username)
    //  getUserCok(data.data.response.username)
 })
   .catch(function (error) {
   })
   
}

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

    const items = order.items.map((item) => {
        const options = (item.options || []).map((option) => (
            <li className="order-list__options-item">
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));
        // useEffect(()=>{
        //     setInfo(JSON.parse(localStorage.getItem('CO-INFO')))
            
        // },[])

        useEffect(() => {
            // let canceled = false;
    
            setIsLoading(true);

            const getUserId = (id) =>{
                axios({
                 method: "get",
                 url: `${process.env.REACT_APP_HOST_API}/order-aplikator/get/${alamatsurvey}`,
                 headers: {
                   Authorization: `Bearer ${token}`,
                 },
               })
               .then(data => {
                setKode(data.data.response)
             })
               .catch(function (error) {
               })
            }
    
            // const getProductById = async ()=>{
            //     try {
            //         const result = await axios.get(`${process.env.REACT_APP_HOST_API}/transaction/get/${alamatsurvey}`, {headers: {
            //             Authorization: `Bearer ${token}`,
            //           },})
            //         // setProduct(result.data.response)
            //         setKode(result.data.response)
            //         setTanggal(result.data.response)
            //         setTotal(result.data.response)
            //         setBank(result.data.response)
            //         // console.log(result.data.data);
            //         setIsLoading(false)
            //     } catch (error) {
            //         console.log(error);
            //         setIsLoading(false);
            //     }
            // }
    
            // shopApi.getProductBySlug(productSlug).then((product) => {
            //     if (canceled) {
            //         return;
            //     }
    
            //     setProduct(product);
            // });
            getUserId()
        }, [alamatsurvey, setIsLoading]);

        
        // console.log(orderInfo)
        return (
            <tr>
                <td className="order-list__column-image">
                    <div className="product-image">
                        <Link to={url.product(item)} className="product-image__body">
                            <img className="product-image__img" src={item.image} alt="" />
                        </Link>
                    </div>
                </td>
                <td className="order-list__column-product">
                    <Link to={url.product(item)}>{item.name}</Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">
                                {options}
                            </ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title="Qty:">{item.quantity}</td>
                <td className="order-list__column-total"><Currency value={item.total} /></td>
            </tr>
        );
    });

    const additionalLines = order.additionalLines.map((line) => (
        <tr>
            <th className="order-list__column-label" colSpan="3">{line.label}</th>
            <td className="order-list__column-total"><Currency value={line.total} /></td>
        </tr>
    ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Masukan Alamat Survey — ${theme.name}`}</title>
            </Helmet><br></br>
            
            {/* <PageHeader header="Masukan Alamat Survey" /> */}
            {/* <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <div className="order-success__subtitle"><h4>Selesaikan pembayaran dalam</h4></div>
                        <div className="order-success__subtitle"><font color='red'>00:00:00</font></div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Kode Transaksi:</span>
                                <span className="order-success__meta-value">{kode.transaction_code}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Tanggal:</span>
                                <span className="order-success__meta-value">{kode.transaction_date}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total:</span>
                                <span className="order-success__meta-value">{formatRupiah(kode.transaction_total)}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Pembayaran:</span>
                                <span className="order-success__meta-value">{kode.bank_name}</span>
                                <span className="order-success__meta-value">{kode.account_number}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="order-success__meta">
                        <div className="order-success__actions">
                            <Link to="/accounts/order-produk" className="btn btn-xs btn-warning">Kembali</Link>
                        </div>
                    </div>
                    

                   
                </div>
            </div> */}
            <div className="card">
            <div className="container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/aplikator/order-jasa">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div>
                {/* <h3><center> cek</center></h3> */}
                {/* <div style={{ textAlign: 'right' }}>
                    <h5>Cek cek</h5>
                </div> */}
            </div>
            <div className="card-header">
                <h5>Masukan Alamat Survey</h5> 
           
            </div>
            {/* <div className="card-divider" /> */}
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        {/* <div className="form-group">
                            <label htmlFor="profile-first-name">Alamat</label>
                            <Input
                                id="profile-first-name"
                                type="textarea"
                                row = "20"
                                className="form-control"
                                placeholder="Masukan Alamat"
                                value={alamat}
                                onChange={e => setAlamat(e.target.value)}
                            />
                        </div>  */}
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Alamat</label>
                            <Input
                                id="profile-last-name"
                                type="select"
                                className="form-control"
                                placeholder="Kurir"
                                value={alamat}
                                onChange={e => {
                                  setAlamat(e.target.value);
                                }
                              }
                            >
                                 <option value=""  selected>Pilih Alamat</option>
                                  {alamats.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.address_id}>
                                          {toko.address_name} 
                                        </option>
                                    );
                                  })}
                            </Input>
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="profile-last-name">Kurir</label>
                            <Input
                                id="profile-last-name"
                                type="select"
                                className="form-control"
                                placeholder="Kurir"
                                value={kurir}
                                onChange={e => {
                                  setKurir(e.target.value);
                                }
                              }
                            >
                                 <option value=""  selected>Pilih Kurir</option>
                                  {kurirs.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.id}>
                                          {toko.name} 
                                        </option>
                                    );
                                  })}
                            </Input>
                        </div> */}
                        {/* <div className="form-group">
                            {kode === "" ? "" : <label htmlFor="profile-last-name">Estimasi harga Jasa adalah {formatRupiah(harga)} - {formatRupiah(Estimasi)}</label>}
                        </div> */}
                        {/* <div className="form-group mt-5 mb-0"> */}
                        <div className='form-group d-lg-block d-none'>
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Kirim</button>
                        </div>
                        <div className='cart__buttons d-md-none d-lg-none d-xl-none'>
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Kirim</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    );
}
