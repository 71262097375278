export default [
    { image: 'images/logos/A1.png' },
    { image: 'images/logos/A2.png' },
    { image: 'images/logos/A3.png' },
    { image: 'images/logos/A4.png' },
    { image: 'images/logos/A5.png' },
    { image: 'images/logos/A6.png' },
    { image: 'images/logos/A7.png' },
    { image: 'images/logos/A8.png' },
    { image: 'images/logos/B1.png' },
    { image: 'images/logos/B2.png' },
    { image: 'images/logos/B3.png' },
    { image: 'images/logos/D1.png' },
    { image: 'images/logos/D2.png' },
    { image: 'images/logos/H1.png' },
    { image: 'images/logos/H2.png' },
    { image: 'images/logos/I1.png' },
    { image: 'images/logos/L1.png' },
    { image: 'images/logos/M1.png' },
    { image: 'images/logos/M2.png' },
    { image: 'images/logos/N1.png' },
    { image: 'images/logos/O1.png' },
    { image: 'images/logos/P1.png' },
    { image: 'images/logos/S1.png' },
    { image: 'images/logos/T1.png' },
    
];
