// react
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import PageHeader from '../shared/PageHeader';
import { toast } from 'react-toastify';
// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

import { updateAction } from '../../store/auth/authActions';
// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';

export default function PengajuanKomisi() {
    let history = useHistory()
    const   {token, update}= useSelector((state) => state.auth)
    const   [bankname, setBankName ] = useState("")
    const   [banknames, setBankNames ] = useState([])
    const   [accountname, setAccountName] = useState("");
    const   [accountnumber, setAccountNumber] = useState("")
    const   [nominal, setNominal] = useState("")
    const   dispatch= useDispatch()

    const   updateHandler = async ()=>{

        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/user-withdraw/save`,
                data:{
                    bank_name : bankname,
                    account_name : accountname,
                    account_number: accountnumber,
                    nominal : nominal,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('sukses cok')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  history.push("/komisi-user/sukses")
            
        } catch (error) {
            console.log(error);
            toast.error('Pengajuan Gagal')
            
        }
    }

    useEffect(() => {
        getbank()
    }, [])
    



   const getbank = () =>{
    axios({
     method: "GET",
     url: `${process.env.REACT_APP_HOST_API}/daftar-bank/list`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
    setBankNames(data.data.response)
 })
   .catch(function (error) {
   })
   
}

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }


    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Masukan Alamat Survey — ${theme.name}`}</title>
            </Helmet><br></br>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/aplikator/order-jasa">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div>
            </div>
            {/* <PageHeader header="Masukan Alamat Survey" /> */}
            {/* <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <div className="order-success__subtitle"><h4>Selesaikan pembayaran dalam</h4></div>
                        <div className="order-success__subtitle"><font color='red'>00:00:00</font></div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Kode Transaksi:</span>
                                <span className="order-success__meta-value">{kode.transaction_code}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Tanggal:</span>
                                <span className="order-success__meta-value">{kode.transaction_date}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total:</span>
                                <span className="order-success__meta-value">{formatRupiah(kode.transaction_total)}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Pembayaran:</span>
                                <span className="order-success__meta-value">{kode.bank_name}</span>
                                <span className="order-success__meta-value">{kode.account_number}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="order-success__meta">
                        <div className="order-success__actions">
                            <Link to="/accounts/order-produk" className="btn btn-xs btn-warning">Kembali</Link>
                        </div>
                    </div>
                    

                   
                </div>
            </div> */}
            <div className="card">
            <div className="container">
            <div className="card-header">
                <h5></h5> 
           
            </div>
            {/* <div className="card-divider" /> */}
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        {/* <div className="form-group">
                            <label htmlFor="profile-first-name">Alamat</label>
                            <Input
                                id="profile-first-name"
                                type="textarea"
                                row = "20"
                                className="form-control"
                                placeholder="Masukan Alamat"
                                value={alamat}
                                onChange={e => setAlamat(e.target.value)}
                            />
                        </div>  */}
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Bank</label>
                            <Input
                                id="profile-last-name"
                                type="select"
                                className="form-control"
                                placeholder="Kurir"
                                value={bankname}
                                onChange={e => {
                                  setBankName(e.target.value);
                                }
                              }
                            >
                                 <option value=""  selected>Pilih Bank</option>
                                  {banknames.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.bank_name}>
                                          {toko.bank_name} 
                                        </option>
                                    );
                                  })}
                            </Input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Rekening</label>
                            <Input
                                id="profile-first-name"
                                type="text"
                                className="form-control"
                                placeholder="Masukan Alamat"
                                value={accountnumber}
                                onChange={e => setAccountNumber(e.target.value)}
                            />
                        </div> 
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Pemilik</label>
                            <Input
                                id="profile-first-name"
                                type="text"
                                className="form-control"
                                placeholder="Masukan Nama Pemilik"
                                value={accountname}
                                onChange={e => setAccountName(e.target.value)}
                            />
                        </div> 
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Nominal</label>
                            <Input
                                id="profile-first-name"
                                type="number"
                                className="form-control"
                                placeholder="Masukan Nominal"
                                value={nominal}
                                onChange={e => setNominal(e.target.value)}
                            />
                        </div> 
                        {/* <div className="form-group">
                            <label htmlFor="profile-last-name">Kurir</label>
                            <Input
                                id="profile-last-name"
                                type="select"
                                className="form-control"
                                placeholder="Kurir"
                                value={kurir}
                                onChange={e => {
                                  setKurir(e.target.value);
                                }
                              }
                            >
                                 <option value=""  selected>Pilih Kurir</option>
                                  {kurirs.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.id}>
                                          {toko.name} 
                                        </option>
                                    );
                                  })}
                            </Input>
                        </div> */}
                        {/* <div className="form-group">
                            {kode === "" ? "" : <label htmlFor="profile-last-name">Estimasi harga Jasa adalah {formatRupiah(harga)} - {formatRupiah(Estimasi)}</label>}
                        </div> */}
                        {/* <div className="form-group mt-5 mb-0"> */}
                        <div className='form-group d-lg-block d-none'>
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Kirim</button>
                        </div>
                        <div className='cart__buttons d-md-none d-lg-none d-xl-none'>
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Kirim</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    );
}
