// react
import React, { Component, useRef } from 'react';

// third-party
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import InputNumber from '../shared/InputNumber';
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, cartUpdateQuantities } from '../../store/cart';
import { Cross12Svg } from '../../svg';
import { url } from '../../services/utils';
import Collapse from '../shared/Collapse';


// data stubs
import theme from '../../data/theme';
import payments from '../../data/shopPayments';

import { toast } from 'react-toastify';
import { updateAction } from '../../store/auth/authActions';

function historyFunc(Component)  {
    function historyFunc(props){
        let history = useHistory()
        return <Component history={history} {...props}/>
    }
    return historyFunc
    
}
function dispatchFunc(Component)  {
    function dispatchFunc(props){
        let dispatch = useDispatch()
        return <Component dispatch={dispatch} {...props}/>
    }
    return dispatchFunc
    
}

class ShopPageCart extends Component {
    payments = payments;

    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            cart:false,
            totalPrice:0,
            cart_id:[],
            payment: "BANK_TRANSFER",
            loadingCO: false,
            paid: false,
            address_id: false,
        };

        
    }

    
    componentDidMount(){
        const {token, updateStatus } =this.props
        const getCart = async () => {
            const result = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_HOST_API}/cart/list`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            console.log(result.data?.response);
            this.setState({cart:result.data?.response});
            this.setState({cart_id:result.data?.response.map((item)=> item.id)});
            console.log(result.data?.response.map((item)=> item.id));
            const totalPrice = result.data?.response.map((item) => item.sub_total);
            // console.log(totalPrice);
            let sum = 0;
      
            for (let i = 0; i < totalPrice.length; i++) {
              sum += totalPrice[i];
            }
            this.setState({totalPrice: sum});
            const productQuantity=result.data?.response.map(({id, qty}) => {
                return {itemId:id,value:qty}
            })
            this.setState({quantities: productQuantity})
            console.log(this.state.quantities);
          };
          if(token ||updateStatus){
              getCart();

          }
    }
    componentDidUpdate(){
        const {token, updateStatus } =this.props
        const getCart = async () => {
            const result = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_HOST_API}/cart/list`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            console.log(result.data?.response);
            this.setState({cart:result.data?.response});
            this.setState({cart_id:result.data?.response.map((item)=> item.id)});
            console.log(result.data?.response.map((item)=> item.id));
            const totalPrice = result.data?.response.map((item) => item.sub_total);
            // console.log(totalPrice);
            let sum = 0;
      
            for (let i = 0; i < totalPrice.length; i++) {
              sum += totalPrice[i];
            }
            this.setState({totalPrice: sum});
            const productQuantity=result.data?.response.map(({id, qty}) => {
                return {itemId:id,value:qty}
            })
            this.setState({quantities: productQuantity})
          };
          if(updateStatus){
              getCart();

          }
    }
    
    
    // checkoutHandler= ()=>{
        
    //           this.props.history.push('/shop/checkout')
        
    // }

    checkoutHandler = async (body) => {
        const { token, history, dispatch, } = this.props;    
        const user_code = localStorage.getItem('user_code');
        // let bank_id = this.state.listBank.find(x => x.bank_name == this.state.currentPayment).id;
    
    
        let param = {
          user_code : user_code,
          list_cart : body.cart_id
        }
    
        try {
          this.setState({ loadingCO: true });
          const result = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_HOST_API}/so-commerce/do-checkout`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: param,
          });
          this.setState({ loadingCO: false });
          console.log(result.data.response);
          localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
          toast.success(`${result.data.response}`);
          this.setState({ paid: true });
        //   dispatch(updateAction(true));
        //   setTimeout(() => {
        //     dispatch(updateAction(false));
        //   }, 1000);
          history.push('/shop/checkout');
        //   this.props.history.push('/shop/checkout')
        } catch (error) {
          this.setState({ loadingCO: false });
          console.log(error);
          toast.error(error.message);
        }
      };

    cartUpdateQuantities =async(quantities)=>{
            
            const {token, dispatch} =this.props

            const cartUpdateItem = async (id,body)=>{
                try {
                    const result = await axios({
                        method: "POST",
                        url: `${process.env.REACT_APP_HOST_API}/cart/update/${id}`,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                        data: body,
                      });
                      toast.success('Product Updated')
                      dispatch(updateAction(true))
                        setTimeout(()=>{
                            dispatch(updateAction(false))
                        },100)
                      
                } catch (error) {
                    console.log(error);
                    toast.error('There was an error updating the product')
                }
            }
        quantities.map(({itemId,value})=>{
            cartUpdateItem(itemId,{qty: value})
        })
        
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.qty;
        

    }

    handleChangeQuantity = (item, quantity) => {
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
    };

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    renderPaymentsList() {
        const { payment: currentPayment } = this.state;

        const payments = this.payments.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.state;

        return quantities.filter((x) => {
            const item = cart.find((item) => item.id === x.itemId);

            return item && item.qty !== x.value && x.value !== '';
        }).length > 0;
    }

    renderItems() {
        // const {  cartRemoveItem } = this.props;
        const {token, dispatch} =this.props
        const cartRemoveItem = async (id,body)=>{
            try {
                const result = await axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_HOST_API}/cart/delete/${id}`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    data: body,
                  });
                  toast.success('Product removed')
                  dispatch(updateAction(true))
              setTimeout(()=>{
                dispatch(updateAction(false))
              },100)
            } catch (error) {
                console.log(error);
                toast.error('There was an error removing the product')
            }
        }
        const{cart} =this.state
        return cart.map((item) => {
            let image;
            let options;

            if (item) {
                image = (
                    <div className="product-image">
                        <Link to={url.product(item.item_id)} className="product-image__body">
                            <img className="product-image__img" src={item.image_url} alt="" />
                        </Link>
                    </div>
                );
            }

            // if (item) {
            //     options = (
            //         <ul className="cart-table__options">
            //             {item.options.map((option, index) => (
            //                 <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
            //             ))}
            //         </ul>
            //     );
            // }

            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.id,{qty:0})}
                    render={({ run, loading }) => {
                        const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                            'btn-loading': loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            // const cekupdate = async (e) => {setTimeout(handleClick, 1000);};
            // function handleClick() {this.cekUpdate.current.onClick();}
            // // const cekUpdate = React.useRef();
            // this.cekUpdate = React.createRef();

            const formatRupiah = (money) => {
                return new Intl.NumberFormat('id-ID',
                    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
                ).format(money);
              }

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={url.product(item.item_id)} className="cart-table__product-name">
                            {item.item_name}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        {/* <Currency value={item.price} /> */}
                        
                            {/* <Currency value={product.price_6 || 200000} /> */}
                            {formatRupiah(item.price_6)}
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            // onClick={this.cartUpdateQuantities(quantities)}
                            onChange={(quantity) => (this.handleChangeQuantity(item, quantity)
                                // , cekupdate()
                                )}
                            value={this.getItemQuantity(item)}
                            min={1}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--satuan" data-title="Satuan">
                            {item.satuan}
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        {/* <Currency value={item.sub_total} /> */}
                        {formatRupiah(item.sub_total)}
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                        {removeButton}
                    </td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart, totalPrice } = this.state;
        

        if (cart.length <= 0) {
            return null;
        }

        // const extraLines = cart.extraLines.map((extraLine, index) => {
        //     let calcShippingLink;

        //     if (extraLine.type === 'shipping') {
        //         calcShippingLink = <div className="cart__calc-shipping"><Link to="/">Calculate Shipping</Link></div>;
        //     }

        //     return (
        //         <tr key={index}>
        //             <th>{extraLine.title}</th>
        //             <td>
        //                 <Currency value={extraLine.price} />
        //                 {calcShippingLink}
        //             </td>
        //         </tr>
        //     );
        // });
        const formatRupiah = (money) => {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
          }

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        {/* <td><Currency value={totalPrice} /></td> */}
                        <td>{formatRupiah(totalPrice)}</td>
                    </tr>
                </thead>
                {/* <tbody className="cart__totals-body">
                    {extraLines}
                </tbody> */}
            </React.Fragment>
        );
    }

    

    renderCart() {
        // const { cart, cartUpdateQuantities } = this.props;
        const { quantities, payment, cart_id, loadingCO} = this.state;

        const updateCartButton = (
            <AsyncAction
                action={() => this.cartUpdateQuantities(quantities)}
                render={({ run, loading }) => {
                    const classes = classNames('btn btn-primary cart__update-button', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes} disabled={!this.cartNeedUpdate()}>
                            Update Cart
                        </button>
                    );
                }}
            />
        );
        // const checkoutButton1 = (
        //     // <AsyncAction
        //     //     action={
        //     //         this.checkoutHandler}
        //     //     render={({ run, loading }) => {
        //     //         const classes = classNames('btn btn-primary btn-xl btn-block cart__checkout-button', {
        //     //             'btn-loading': loadingCO,
        //     //         });

        //     //         return (
        //     //             // <button type="button" onClick={this.checkoutHandler} className={classes} disabled={loadingCO}>
        //     //              <button type="button" onClick={ this.checkoutHandler({
        //     //                 // payment_method: 'BANK_TRANSFER',
        //     //                 // bank_name: 'BCA',
        //     //                 // address_id: this.state.address_id,
        //     //                 cart_id: this.state.cart_id,
        //     //               })} className={classes} disabled={loadingCO}> 
        //     //               Checkout
        //     //             </button>
        //     //         );
        //     //     }}
        //     // />
        // );

        const formatRupiah = (money) => {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
          }

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Image</th>
                                <th className="cart-table__column cart-table__column--product">Product</th>
                                <th className="cart-table__column cart-table__column--price">Harga</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--satuan">Satuan</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>
                    <div className="cart__actions">
                        <form className="cart__coupon-form">
                            {/* <label htmlFor="input-coupon-code" className="sr-only">Password</label>
                            <input type="text" className="form-control" id="input-coupon-code" placeholder="Coupon Code" />
                            <button type="submit" className="btn btn-primary">Apply Coupon</button> */}
                        </form>
                        <div className="cart__buttons">
                            {/* <Link to="/" className="btn btn-light">Belanja</Link> */}
                            {updateCartButton}
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Total Pembayaran</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                {/* <td><Currency value={this.state.totalPrice} /> */}
                                                <td>{formatRupiah(this.state.totalPrice)}</td>
                                                {/* </td> */}
                                            </tr>
                                        </tfoot>
                                    </table>
                                    {/* {this.renderPaymentsList()} */}
                                    {/* {this.checkoutButton} */}
                                    <button
                                        type='submit'
                                        disabled={this.state.loadingCO}
                                        className={classNames('btn btn-primary btn-xl btn-block',{'btn-loading': this.state.loadingCO})}
                                        onClick={() => {
                                            this.checkoutHandler({
                                            cart_id: this.state.cart_id,
                                            })
                                        }
                                        }
                                    >
                                        Checkout
                                    </button>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.state;
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Keranjang', url: '' },
        ];

        let content;

        if (cart.length>0) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            {/* <div className="block-empty__message">Your shopping cart is empty!</div> */}
                            <div className="block-empty__message">Keranjangmu Kosong, yuk belanja dulu!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">Belanja</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
// console.log(this.state.quantities)
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Keranjang — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Keranjang" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    token: state.auth.token,
    updateStatus: state.auth.update
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(dispatchFunc(historyFunc(ShopPageCart)));
