// react
import React from 'react';

function ProductTabDescription() {

    return (
        <div className="typography">
            <h3>Deskripsi</h3>
            <p>
            
            </p>
        </div>
    );
}

export default ProductTabDescription;
