// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';
import { toast } from 'react-toastify';
import axios from 'axios';
import { updateAction } from '../../store/auth/authActions';


function dispatchFunc(Component)  {
    function dispatchFunc(props){
        let dispatch = useDispatch()
        return <Component dispatch={dispatch} {...props}/>
    }
    return dispatchFunc
    
}
class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
            loadingAdd:false,
            update: false,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    

    render() {
        const {
            product,
            layout,
            wishlistAddItem,
            compareAddItem,
            cartAddItem,
            auth:{token},
            dispatch
        } = this.props;
        const { quantity } = this.state;
        let prices;
        // console.log(product);
        // if (product.compareAtPrice) {
        //     prices = (
        //         <React.Fragment>
        //             <span className="product__new-price"><Currency value={product.price} /></span>
        //             {' '}
        //             <span className="product__old-price"><Currency value={product.compareAtPrice} /></span>
        //         </React.Fragment>
        //     );
        // } else {
            prices = <Currency value={product && product.price_6} />;
        // }

    // const [qty, setQty] = useState(1)
    // const[loadingCart, setLA]=useState(false)
    // const[update, setUpdate]=useState(false)

    // const {token} = useSelector((state) => state.auth)
    // console.log(token)
   const add2Cart = async (body) => {
        try {
          if (!token) {
            // dispatch(setModalAction());
            console.log('no token')
            toast.error('You need to login first')
           return <Redirect push to='/account/login'/>;
          } else {
            // setLA(true);
            this.setState({loadingAdd:true})
            const result = await axios({
              method: "POST",
              url: `${process.env.REACT_APP_HOST_API}/cart/save`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: body,
            });
            this.setState({loadingAdd: true, update: true})
            toast.success('Product successfully added')
            setTimeout(() => {
              this.setState({update:false})
            }, 500);
            dispatch(updateAction(true))
              setTimeout(()=>{
                dispatch(updateAction(false))
              },1000)
            // console.log(result);
          }
        } catch (error) {
          this.setState({loadingAdd:false})
        //   console.log(error);
          toast.error('There was an error saving your cart')
        }
      };

      const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery layout={layout} images={product && [product.image_1]} />
                    <ul className="product__meta">
                            <li>
                                <span className="text-success"> {product.warehouse_name}</span>
                            </li>
                           
                        </ul>
                    <div className="product__info">
                        {/* <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div> */}
                        <h1 className="product__name">{product && product.item_name}</h1>
                        {/* <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating value={4} />
                            </div>
                            <div className="product__rating-legend">
                                <Link to="/">{`10 Reviews`}</Link>
                                <span>/</span>
                                <Link to="/">Write A Review</Link>
                            </div>
                        </div> */}
                        <div className="product__description">
                            {product && product.item_description}
                        </div>
                        <ul className="product__meta">
                            <li>
                                Status:
                                <span className="text-danger"> {product.status}</span>
                            </li>
                            {/* <li>SKU: 83690/32</li> */}
                            <li className="product__meta-availability">
                                Stock:
                                {' '}
                                <span className="text-danger"> {product.stok}</span>
                            </li>
                           
                        </ul>
                    </div>

                    <div className="product__sidebar">
                        {/* <div className="product__availability">
                            Availability:
                            {' '}
                            <span className="text-success">In Stock</span>
                        </div> */}

                        {/* <div className="product__prices">
                            {prices}
                        </div> */}
                        
                        {/* Merubah Format Rupiah */}
                        <div className="product__prices">
                            {/* <Currency value={product.price_6 || 200000} /> */}
                            {formatRupiah(product.price_6)}
                        </div>

                        <form className="product__options">
                            <div className="form-group product__option">
                                <label htmlFor="product-quantity" className="product__option-label">Quantity</label>
                                <div className="product__actions">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={1}
                                            value={quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <AsyncAction
                                            action={() => add2Cart({
                                                item_id: product.item_id,
                                                qty: quantity,
                                                warehouse_id : product.warehouse_id,
                                                price_6 : product.price_6,
                                                type : product.type,
                                              })}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    disabled={!quantity}
                                                    className={classNames('btn btn-primary btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    Beli
                                                </button>
                                            )}
                                        />
                                    </div>
                                    {/* <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => wishlistAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--compare">
                                        <AsyncAction
                                            action={() => compareAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Compare"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </form>
                    </div>
{/* 
                    <div className="product__footer">
                        <div className="product__tags tags">
                            <div className="tags__list">
                                <Link to="/">Mounts</Link>
                                <Link to="/">Electrodes</Link>
                                <Link to="/">Chainsaws</Link>
                            </div>
                        </div>

                        <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item share-links__item--type--like"><Link to="/">Like</Link></li>
                                <li className="share-links__item share-links__item--type--tweet"><Link to="/">Tweet</Link></li>
                                <li className="share-links__item share-links__item--type--pin"><Link to="/">Pin It</Link></li>
                                <li className="share-links__item share-links__item--type--counter"><Link to="/">4K</Link></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

const mapStateToProps = (state) => ({ auth: state.auth})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(dispatchFunc(Product));
