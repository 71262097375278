// react
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';
import { 
    Table,
    Media,
    Card,
    Badge,
    Row,
    CardTitle,
    Form,
    Col,
    CardBody,
    CardHeader,
} from 'reactstrap';
// application
import PageHeader from '../shared/PageHeader';
import { resetAuth } from '../../store/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';


export default function AccountLayoutMobile() {
    // const { match, location } = props;
    // const token = localStorage.token
    const usercode = localStorage.user_code
    const [username , setUsername] = useState("")
    const [komisi, setKomisi] = useState(0);
    const [saldo, setSaldo] = useState(0);
    const [voucher, setVoucher] = useState(0)
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    const [userData, setUserData] = useState(false)
    const { token, loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

    // useEffect(()=>{
    //     const getUser = async () =>{
    //       try {
    //         const result = await axios({
    //           method: "GET",
    //           url: `${process.env.REACT_APP_HOST_API}/auth/info`,
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         });
    //         setUserData(result.data?.response)
    //       } catch (error) {
    //         console.log(error);
    //         // dispatch(resetAuth())
    //       }
    //     }
        
    //     if(token){
    //       getUser()
    //     }
    //   },[token])

      useEffect(() => {
        getUser()
        // getAsset()
        // getUserCok()
      }, [])

      const getAsset = ( ) => {
        let filter = { 
          username : "faisal"
        }
        const data = filter;
        const headers = {
            Authorization: `Bearer ${token}`
          }
        axios.post(`${process.env.REACT_APP_HOST_API}/users/page`, { headers : {Authorization: `Bearer ${token}`}
        })
        .then(data => {
            setUserData(data.data.response);
        })
          .catch(function (error) {
          })
      }

      const getUserCok = (id) =>{
        axios({
         method: "POST",
         url: `${process.env.REACT_APP_HOST_API}/users/page`,
         headers: {
           Authorization: `Bearer ${token}`,
         },
         data : {
            username : id
         }
       })
       .then(data => {
         setUserData(data.data.response[0]);
         getUserId(data.data.response[0].id)
         getUserKomisi(data.data.response[0].id)
     })
       .catch(function (error) {
       })
       
   }

   const getUserId = (id) =>{
    axios({
     method: "get",
     url: `${process.env.REACT_APP_HOST_API}/users/get/${id}`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
     setSaldo(data.data.response.wallet);
     setVoucher(data.data.response.voucher);
 })
   .catch(function (error) {
   })
   
}

const getUserKomisi = (id) =>{
    axios({
     method: "get",
     url: `${process.env.REACT_APP_HOST_API}/user-withdraw/transaksi-turunan/${id}`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
     setKomisi(data.data.response);
 })
   .catch(function (error) {
   })
   
}
      

      const getUser = () =>{
           axios({
            method: "GET",
            url: `${process.env.REACT_APP_HOST_API}/auth/info`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(data => {
            // setUserData(data.data.response);
            // setUsername(data.data.response.username)
            getUserCok(data.data.response.username)
        })
          .catch(function (error) {
          })
          
      }

    // const links = [
    //     { title: 'Dashboard', url: 'dashboard' },
    //     // { title: 'Edit Profile', url: 'profile' },
    //     { title: 'Histori Pemesanan', url: 'orders' },
    //     // { title: 'Order Details', url: 'orders/' },
    //     { title: 'Alamat', url: 'addresses' },
    //     // { title: 'Edit Address', url: 'addresses/' },
    //     { title: 'Password', url: 'password' },
    //     // { title: 'Logout', url: 'login' },
    // ].map((link) => {
    //     const url = `${match.url}/${link.url}`;
    //     const isActive = matchPath(location.pathname, { path: url, exact: true });
    //     const classes = classNames('account-nav__item', {
    //         'account-nav__item--active': isActive,
    //     });

    //     return (
    //         <li key={link.url} className={classes}>
    //             <Link to={url}>{link.title}</Link>
    //         </li>
    //     );
    // });

    return (
        <React.Fragment>
            <PageHeader />
            <div className="block">
                <div className="container">
                {/*  */}
                {/* <Table className="align-items-center bg-white d-md-none d-lg-none d-xl-none" responsive> */}
                    {/* <tr> */}
                            <th scope="row">
                                <Media className="align-items-center">
                                <div className="profile-card__avatar">
                                <img src={userData.user_image ? userData.user_image :" images/avatars/avatar-3.jpg"} alt="" />
                            </div>
                                <a
                                    class="nav-link"
                                    href="/accounts/dashboard"
                                >
                                <div className="profile-card__name" style={{color:"black"}}>{userData.name}</div>
                            {/* <div className="profile-card__email">Hidayatullah@gmail.com</div> */}
                            <div className="profile-card__edit">
                                {/* <Link to="#" className="btn btn-secondary btn-sm">{userData.member_level}</Link> */}
                                {userData.member_level === "REGULER" ? <Badge color="info" pill>{userData.member_level}</Badge> 
                                : userData.member_level === "SILVER" ? <Badge color="secondary" pill>{userData.member_level} </Badge> 
                                : userData.member_level === "GOLD" ? <Badge color="warning" pill> {userData.member_level} </Badge> 
                                : <Badge color="danger" pill> {userData.member_level} </Badge>}
                                
                            </div>
                                </a>
                                </Media>
                            </th>
                            {/* </tr> */}
                    {/* <div className="dashboard__profile card profile-card">
                        <div className="card-body profile-card__body">
                            <div className="profile-card__avatar">
                                <img src={" images/avatars/avatar-3.jpg"} alt="" />
                            </div>
                            <div className="profile-card__name">Faisal</div>
                            <div className="profile-card__email">Hidayatullah@gmail.com</div>
                            <div className="profile-card__edit">
                                <Link to="profile" className="btn btn-secondary btn-sm">Edit Profile</Link>
                            </div>
                        </div>
                    </div> */}
                {/* </Table> */}
                <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{}}>
                            <th scope="row">
                                <Media className="align-items-center">
                                    <img src={" images/menuprofile/pesanan_produk.png"} alt="" width="30px" height="30px" />

                                <a
                                    class="nav-link"
                                    href="#"
                                >
                                <div className="profile-card__name" style={{color:"black"}}>Pesanan</div>
                                </a>
                                </Media>
                            </th>
                            <hr></hr>
                            <tbody>
                                <tr>
                                    <th className="text-center">
                                    <a href="/accounts/order-produk">
                                        <div className="" style={{width:"100px", height:"60px", textAlign:"center"}}> 
                                            <img src={" images/menuprofile/pesanan_produk.png"} alt="" width="50px" height="50px" />
                                        </div>
                                        <div className="profile-card__name" style={{fontSize:"13px" ,color:"black"}}>Pesanan Produk</div>
                                    </a>
                                    </th>
                                    {/* <th className="text-center">
                                        <div className="profile-card__avatar">
                                        </div>
                                    </th> */}
                                    <th className="text-center">
                                    <a href="/aplikator/order-jasa">
                                        <div className="" style={{width:"100px", height:"60px", textAlign:"center"}}>
                                        <img src={" images/menuprofile/pesanan_jasa.png"} alt="" width="50px" height="50px" />
                                        </div>
                                        <div className="profile-card__name" style={{fontSize:"13px" ,color:"black"}}>Pesanan Jasa</div>
                                    </a>
                                    </th>
                                    <th className="text-center">
                                    <a href="/addendum/order-addendum">
                                        <div className="" style={{width:"100px", height:"60px", textAlign:"center"}}>
                                        <img src={" images/menuprofile/pesanan_jasa.png"} alt="" width="50px" height="50px" />
                                        </div>
                                        <div className="profile-card__name" style={{fontSize:"13px" ,color:"black"}}>Revisi</div>
                                    </a>
                                    </th>
                                </tr>
                            </tbody>
                  </div>
                </CardHeader>
                </Card>
                <br></br>
                <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{}}>
                    <th scope="row">
                        <Media className="align-items-center">
                                <img src={" images/menuprofile/dompet.png"} alt="" width="30px" height="30px" />
                            <a
                                class="nav-link"
                                href="#"
                            >
                                <div className="profile-card__name" style={{color:"black"}}>Dompet</div>
                            </a>
                        </Media>
                    </th>
                    <hr></hr>
                    <tbody>
                            <tr>
                                <th className="text-center" >
                                {/* <a href="/accounts"> */}
                                    <div className="" style={{width:"100px", height:"60px", textAlign:"center"}}>
                                        <img src={" images/menuprofile/saldo.png"} alt="" width="50px" height="50px" />
                                    </div>
                                    <div className="profile-card__name" style={{fontSize:"13px" ,color:"black"}}>Saldo</div>
                                    <div className="profile-card__name" style={{fontSize:"13px" ,color:"blue"}}>{formatRupiah(saldo)}</div>
                                {/* </a> */}
                                </th>
                                <th className="text-center">
                                <a href="/komisi-user">
                                <div className="" style={{width:"100px", height:"60px", textAlign:"center"}}>
                                    <img src={" images/menuprofile/komisi.png"} alt="" width="50px" height="50px" />
                                </div>
                                    <div className="profile-card__name" style={{fontSize:"13px" ,color:"black"}}>Komisi</div>
                                    <div className="profile-card__name" style={{fontSize:"13px" ,color:"blue"}}>{formatRupiah(komisi)}</div>
                                </a>
                                </th>
                                <th className="text-center">
                                {/* <a href="/accounts"> */}
                                <div className="" style={{width:"100px", height:"60px", textAlign:"center"}}>
                                     <img src={" images/menuprofile/voucher.png"} alt="" width="50px" height="50px" />
                                </div>
                                    <div className="profile-card__name" style={{fontSize:"13px" ,color:"black"}}>Voucher</div>
                                    <div className="profile-card__name" style={{fontSize:"13px" ,color:"blue"}}>{formatRupiah(voucher)}</div>
                                {/* </a> */}
                                </th>
                            </tr>
                    </tbody>
                  </div>
                </CardHeader>
                </Card>
                    <br></br>
                <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{}}>
                  {/* <Table className="align-items-center bg-white d-md-none d-lg-none d-xl-none" responsive> */}
                        <tbody>
                            <tr>
                            <th scope="row">
                                <Media className="align-items-center">
                                    <i className="fas fa-sign-out-alt fa-2x" />
                                <a
                                    class="nav-link"
                                    onClick={() =>  dispatch(resetAuth())}
                                >
                                <Media>
                                    <span className="mb-0 text-sm" style={{color:"black"}}>
                                    Keluar 
                                    </span>
                                </Media>
                                </a>
                                </Media>
                            </th>
                            </tr>
                        </tbody>
                    {/* </Table> */}
                  </div>
                </CardHeader>
                </Card>
                </div>
            </div>
        </React.Fragment>
    );
}
