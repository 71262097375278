// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { getBanner } from "../../fake-server/database/axios";

// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');
    state = {
        slides : []
    }

    slider = async () => {
        try {
           const newSlide = await getBanner()
           //console.log(newSlide);
           this.setState({slides : newSlide.data.response})
        } catch (error) {
            console.log(error);
        }
    }


    // untuk bgnya ambil dari api bg yang sudah masnya buat. jadi nanti dia pakai LTR ya. ini saya pakai manual.
    // start 
    // slides = [
    //     {
    //        image_classic: {
    //             ltr: 'images/slides/banner1.png',
    //             rtl: 'images/slides/banner1.png',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/bannersatu.png',
    //             rtl: 'images/slides/bannersatu.png',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/bannerpertama.png',
    //             rtl: 'images/slides/bannerpertama.png',
    //         },
    //     },
    //     {
    //         image_classic: {
    //             ltr: 'images/slides/banner2.png',
    //             rtl: 'images/slides/banner2.png',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/bannerdua.png',
    //             rtl: 'images/slides/bannerdua.png',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/bannerkedua.png',
    //             rtl: 'images/slides/bannerkedua.png',
    //         },
    //     },
    //     {
    //         image_classic: {
    //             ltr: 'images/slides/banner2.png',
    //             rtl: 'images/slides/banner2.png',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/bannertiga.png',
    //             rtl: 'images/slides/bannertiga.png',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/bannerketiga.png',
    //             rtl: 'images/slides/bannerketiga.png',
    //         },
    //     },
    //     {
    //         image_classic: {
    //             ltr: 'images/slides/banner2.png',
    //             rtl: 'images/slides/banner2.png',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/bannerempat.png',
    //             rtl: 'images/slides/bannerempat.png',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/bannerkeempat.png',
    //             rtl: 'images/slides/bannerkeempat.png',
    //         },
    //     },
    //     {
    //         image_classic: {
    //             ltr: 'images/slides/banner2.png',
    //             rtl: 'images/slides/banner2.png',
    //         },
    //         image_full: {
    //             ltr: 'images/slides/bannerlima.png',
    //             rtl: 'images/slides/bannerlima.png',
    //         },
    //         image_mobile: {
    //             ltr: 'images/slides/bannerkelima.png',
    //             rtl: 'images/slides/bannerkelima.png',
    //         },
    //     },
        // {
        //     image_classic: {
        //         ltr: 'images/slides/banner2.png',
        //         rtl: 'images/slides/banner2.png',
        //     },
        //     image_full: {
        //         ltr: 'images/slides/bannerenam.png',
        //         rtl: 'images/slides/bannerenam.png',
        //     },
        //     image_mobile: {
        //         ltr: 'images/slides/bannerkeenam.png',
        //         rtl: 'images/slides/bannerkeenam.png',
        //     },
        // },
        // {
        //     image_classic: {
        //         ltr: 'images/slides/banner2.png',
        //         rtl: 'images/slides/banner2.png',
        //     },
        //     image_full: {
        //         ltr: 'images/slides/bannertujuh.png',
        //         rtl: 'images/slides/bannertujuh.png',
        //     },
        //     image_mobile: {
        //         ltr: 'images/slides/bannerketujuh.png',
        //         rtl: 'images/slides/bannerketujuh.png',
        //     },
        // },
        
        // finish 
        
        // {
        //     image_classic: {
        //         ltr: 'images/slides/bannersatu.png',
        //         rtl: 'images/slides/bannerdua.png',
        //     },
        //     image_full: {
        //         ltr: 'images/slides/bannersatu.png',
        //         rtl: 'images/slides/bannerdua.png',
        //     },
        //     image_mobile: {
        //         ltr: 'images/slides/slide-3-mobile.jpg',
        //         rtl: 'images/slides/slide-3-mobile.jpg',
        //     },
        // },
    // ];

    componentDidMount() {
        const getOnGoing= async () => {
            try {
                const result = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_HOST_API}/iklan-ecommerce/carousel1`,
                    
                    data:{
                        page:this.state.page,
                        per_page: 10
                    }
                    
                  });
               this.setState({slides : result.data.response })
              
            } catch (error) {
                console.log(error);
            }
        }
            getOnGoing()
        // this.slider()
        // if (this.media.addEventListener) {
        //     this.media.addEventListener('change', this.onChangeMedia);
        // } else {
        //     // noinspection JSDeprecatedSymbols
        //     this.media.addListener(this.onChangeMedia);
        // }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];
        const { slides } = this.state;

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        const slidess = this.state.slides.map((slide, index) => {
            // const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${slide.url_web})`,
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.url_mobile})`,
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <div
                            className="block-slideshow__slide-text"
                            dangerouslySetInnerHTML={{ __html: slide.text }}
                        />
                        <div className="block-slideshow__slide-button">
                            {/* <Link to="/" className="btn btn-primary btn-lg">Shop Now</Link> */}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {slidess}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
