// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Hubungi Kami</h5>
                {/* Kepuasan Anda, Prioritas Kami
            <div className="footer-contacts__text">
                
            </div> */}

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    Jl. Raya Menganti Karangan No.38, Babatan, Kec. Wiyung, Kota SBY, Jawa Timur 60227 
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    hokkybangunan@gmail.com
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    081-217-853-300
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    Sen-Min 08:00pm - 10:00pm
                </li>
            </ul>
        </div>
    );
}
