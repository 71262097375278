export const url = {
    home: () => '/',

    catalog: () => '/shop/category',

    category: (category) => `/shop/category/${category}`,

    product: (product) => `/shop/products/${product}`,

    kategori: (kategori) => `/shop/kategori/${kategori}`,

    Pembayaran: (pembayaran) => `/accounts/order-produk/${pembayaran}`,

    alamatsurvey: (alamatsurvey) => `/aplikator/alamat-survey/${alamatsurvey}`,
    
    detailobservasi: (detailobservasi) => `/aplikator/detail-observasi/${detailobservasi}`,
    
    bayardp: (bayardp) => `/aplikator/bayar-dp/${bayardp}`,

    buktibayardp: (buktibayardp) => `/aplikator/bukti-bayar-dp/${buktibayardp}`,

    emailakses: (emailakses) => `/aplikator/email-akses-klien/${emailakses}`,

    buktipelunasan: (buktipelunasan) => `/aplikator/pelunasan/${buktipelunasan}`,

    detailtransaksiaplikator: (detailtransaksiaplikator) => `/aplikator/detail-transaksi/${detailtransaksiaplikator}`,

    revisiaplikator: (revisiaplikator) => `/addendum/create/${revisiaplikator}`,

    cekdetailrevisi: (cekdetailrevisi) => `/addendum/hasil-revisi/${cekdetailrevisi}`,

    buktibayaraddendum: (buktibayaraddendum) => `/addendum/bukti-bayar/${buktibayaraddendum}`,

    addendumdetail: (addendumdetail) => `/addendum/detail-revisi/${addendumdetail}`,

    addressid: (addressid) => `/account/addresses/${addressid}`,

    validasirab: (validasirab) => `/aplikator/validasi-rab/${validasirab}`,



};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}
