// react
import axios from 'axios';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// data stubs
import theme from '../../data/theme';
import { updateAction } from '../../store/auth/authActions';

export default function AplikatorPage() {
    let history = useHistory()
    const userid = localStorage.user_id
    const [picture, setPicture] = useState(false)
    const [toko, setToko] = useState('')
    const [tokos, setTokos] = useState([])
    const [kode, setKode] = useState("")
    const [kodes, setKodes] = useState([])
    const [harga, setHarga] = useState("");
    const Estimasi = harga+100000
    const [gender, setGender] = useState('')
    const {token, update}= useSelector((state) => state.auth)
    // console.log(userid);
    // console.log(picture)
    const dispatch= useDispatch()

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

    useEffect(() => {
        GetToko()
        getAplikatorList()
    }, [])
    

    const GetToko = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        axios
          .get(
            `${process.env.REACT_APP_HOST_API}/warehouse/cabang-toko`,{headers}
          )
          .then((data) => {
            setTokos(data.data.response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const getAplikatorList = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        axios
          .get(
            `${process.env.REACT_APP_HOST_API}/aplikator/list`,{headers}
          )
          .then((data) => {
            setKodes(data.data.response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const getAplikator = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        axios
          .get(
            `${process.env.REACT_APP_HOST_API}/aplikator/get-by-code/${id}`,{headers}
          )
          .then((data) => {
            // setKodes(data.data.response);
            setHarga(data.data.response.upah_jasa)
          })
          .catch(function (error) {
            console.log(error);
          });
      };

    const updateHandler = async ()=>{

        // function CreateData() {
        //     let dataItems = [];
        //         savedItems.map((dataItem) => dataItems = [...dataItems, 
        //             { 
        //               item_id: dataItem.item_id, 
        //               code_rak : dataItem.code_rak,
        //               item_name:dataItem.item_name,
        //               qty: dataItem.qty, 
        //               reason:dataItem.reason,
        //               ket:dataItem.ket,
        //             }]);
        //     let data = {
        //       status_d : 3,
        //       warehouse_id : parseInt(warehouse),
        //       username : username,
        //       keterangan: keterangan ,
        //       items : dataItems
        //     };
        //     axios
        //         .post(
        //           `${process.env.REACT_APP_API_BASE_URL}/adjustment/update/${userid}`,
        //           data,
        //           {
        //             headers: {
        //               Authorization: `Bearer ${token}`,
        //             },
        //           }
        //         )
        //         .then(function (response) {
        //           setSuccessAlert(response.data.message);
        //           setTimeout(() => (history.push("/admin/stock-adjustment/page")), 2000);
        //           })
        //           .catch(function (error) {
        //             setGagalAlert(error.response.data.message);
        //           });
        //     }

        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/order-aplikator/save`,
                data:{warehouse_id: toko,
                    code_aplikator : kode,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('Pesan Aplikator Sukses')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  history.push("/aplikator/sukses-pesan")
            
        } catch (error) {
            console.log(error);
            toast.error('Pesan Aplikator Gagal')
            
        }
    }
    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>
            <div className="container">
            <div className="card-header">
                {/* <h5>Edit Profile</h5> */} 
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/accounts/dashboard">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div> */}
                <h3><center>Pesan Aplikator</center></h3>

                <div style={{ textAlign: 'right' }}>
                    <h3></h3>
                </div>
            </div>
            </div>
            {/* <div className="card-divider" /> */}
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Toko</label>
                            <Input
                                id="profile-first-name"
                                type="select"
                                className="form-control"
                                placeholder="Masukan Warehouse"
                                value={toko}
                                onChange={e => setToko(e.target.value)}
                            >
                                 <option value=""  selected>Pilih Toko</option>
                                  {tokos.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.id}>
                                          {toko.name} 
                                        </option>
                                    );
                                  })}
                                </Input>
                        </div> 
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Aplikator</label>
                            <Input
                                id="profile-last-name"
                                type="select"
                                className="form-control"
                                placeholder="Last Name"
                                value={kode}
                                onChange={e => {
                                  setKode(e.target.value);
                                  getAplikator(e.target.value)
                                }
                              }
                            >
                                 <option value=""  selected>Pilih Aplikator</option>
                                  {kodes.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.aplikator_code}>
                                          {toko.name} 
                                        </option>
                                    );
                                  })}
                            </Input>
                        </div>
                        <div className="form-group">
                            {kode === "" ? "" : <label htmlFor="profile-last-name">Estimasi harga Jasa adalah <b>{formatRupiah(harga)} - {formatRupiah(Estimasi)} / Meter Persegi</b></label>}
                        </div>
                        <div className="form-group mt-5 mb-0">
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Pesan</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}
