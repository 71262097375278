// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { 
    Table,
    Media,
    Card,
    Badge,
    Row,
    CardTitle,
    Form,
    Col,
    CardBody,
    CardHeader,
} from 'reactstrap';
// application
import Pagination from '../shared/Pagination';
import PageHeader from '../shared/PageHeader';
import { url } from '../../services/utils';
// data stubs
import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import axios from 'axios';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

 class AplikatorPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            page: 1,
        };
    }

    componentDidMount(){
        const{token} =this.props
        const getOnGoing= async () => {
            try {
                
                const result = await axios({
                    method: 'POST',
                    url: `${process.env.REACT_APP_HOST_API}/order-aplikator/page`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    data:{
                        page:this.state.page,
                        per_page: 10
                    }
                    
                  });
                  this.setState({ orders: result.data.response });
                    // console.log(this.state.orders);

            } catch (error) {
                // console.log(error);
        // toast.error('There was an error!');
            }
        }
        if(token){
            getOnGoing()
        }
    }
    // componentDidUpdate(){
    //     const{token} =this.props
    //     const getOnGoing= async () => {
    //         try {
    //             const result = await axios({
    //                 method: 'POST',
    //                 url: `${process.env.REACT_APP_HOST_API}/transaction/page`,
    //                 headers: {
    //                   Authorization: `Bearer ${token}`,
    //                 },
    //                 data:{
    //                     page:this.state.page,
    //                     per_page: 10
    //                 }
                    
    //               });
    //               this.setState({ orders: result.data?.response });
    //         } catch (error) {
    //             console.log(error);
    //     toast.error('There was an error!');
    //         }
    //     }
    //     if(this.state.page){
    //         getOnGoing()
    //     }
    // }

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
        // console.log(this.state.orders);
    };

    renderItems() {
        // const {  cartRemoveItem } = this.props;
        const {token, dispatch} =this.props
        const { page, orders } = this.state;

        const ordersList = orders.map((order) => (
            <tr key={order.id}>
                <td><Link to="/account/orders/5">{`#${order.transaction_code}`}</Link></td>
                <td>{order.transaction_date}</td>
                <td>{order.bank_name}</td>
                <td>{order.transaction_total}</td>
                <td>{order.active_flag}</td>
            </tr>
        ));
        return orders.map((item) => {
            let options;

            const formatRupiah = (money) => {
                return new Intl.NumberFormat('id-ID',
                    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
                ).format(money);
              }

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--kode">
                        <Link to={url.detailtransaksiaplikator(item.id)} className="cart-table__kode-name">
                           <b> {item.oa_code}</b>
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--tanggal" data-title="Tanggal">
                    {item.status === 1 ? "-":
                    item.status === 2 ? <b>{item.transaction_date}</b>:
                    item.status === 3 ? <b>{item.transaction_date}</b>:
                    item.status === 4 ? <b>{item.transaction_date}</b>:
                    item.status === 5 ? <b>{item.transaction_date}</b>:
                    item.status === 6 ? <b>{item.transaction_date}</b>:
                    <b>{item.transaction_date}</b>}
                    

                    </td>
                    <td className="cart-table__column cart-table__column--bank" data-title="Jasa">
                        <b>{item.aplikator_name}</b>
                    </td>
                    {/* <td className="cart-table__column cart-table__column--totalbayar" data-title="Total">
                    {formatRupiah(item.transaction_total)}

                    </td> */}
                    <td className="cart-table__column cart-table__column--status" data-title="Status">
                    {
                    item.status === 1 ? <Badge color="warning" pill> Update Alamat Survey </Badge>:
                    item.status === 2 ? <Badge color="warning" pill> Menunggu Jadwal Survey</Badge>:
                    item.status === 3 ? <Badge color="info" pill> Menunggu Hasil Observasi</Badge>:
                    item.status === 4 ? <Badge color="danger" pill> Validasi Kunjungan</Badge>:
                    item.status === 5 ? <Badge color="warning" pill> Pembayaran DP</Badge>:
                    item.status === 6 ? <Badge color="success" pill> Kirim Bukti Transfer </Badge>:
                    item.status === 7 ? <Badge color="warning" pill> Isikan Email Anda </Badge>:
                    item.status === 8 ? <Badge color="warning" pill> Menunggu Validasi Pembayaran </Badge>:
                    item.status === 9 ? <Badge color="info" pill> Menunggu Hasil RAB </Badge>:
                    item.status === 10 ? <Badge color="warning" pill> Cek Hasil RAB </Badge>:
                    item.status === 11 ? <Badge color="warning" pill> Menunggu Pengerjaan </Badge>:
                    item.status === 12 ? <Badge color="danger" pill> Proses Pengerjaan </Badge>:
                    item.status === 13 ? <Badge color="success" pill> Proses Selesai </Badge>:
                    item.status === 14 ? <Badge color="warning" pill> Menunggu Validasi Pelunasan </Badge>:
                    <Badge color="success" pill> Lunas </Badge>}
                    </td>
                    <td className="cart-table__column" data-title="">
                    {item.status === 1 ? 
                        <div className="cart__buttons">
                                <Link to={url.alamatsurvey(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Masukan Alamat</Link>
                        </div>:
                    item.status === 2 ? "":
                    item.status === 3 ?  
                        ""
                    :
                    item.status === 4 ? 
                    <div className="cart__buttons">
                            <Link to={url.detailobservasi(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Detail Observasi</Link>
                    </div>
                    :
                    item.status === 5 ?
                    <div className="cart__buttons">
                        <Link to={url.bayardp(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Pembayaran DP</Link>
                    </div>
                    :
                    item.status === 6 ? 
                    <div className="cart__buttons">
                        <Link to={url.buktibayardp(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti Transfer</Link>
                    </div> 
                    :
                    item.status === 7 ? 
                    <div className="cart__buttons">
                            <Link to={url.emailakses(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Masukan Email</Link>
                    </div>
                    :
                    item.status === 8 ? ""
                    // <div className="cart__buttons">
                    //         <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti Transfer</Link>
                    // </div> 
                    :
                    item.status === 9 ? ""
                    // <div className="cart__buttons">
                    //         <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti Transfer</Link>
                    // </div>
                    :
                    item.status === 10 ? 
                    // ""
                    <div className="cart__buttons">
                            <Link to={url.validasirab(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Cek Hasil RAB</Link>
                    </div>
                    :
                    item.status === 11 ? ""
                    // <div className="cart__buttons">
                    //         <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti Transfer</Link>
                    // </div>
                    :
                    
                    item.status === 12 ? ""
                    // <div className="cart__buttons">
                    //         <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti Transfer</Link>
                    // </div>
                    :
                    
                    item.status === 13 ? 
                    <div className="cart__buttons">
                            <Link to={url.buktipelunasan(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Pelunasan</Link>
                    </div>
                    // <div className="cart__buttons">
                    //         <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti Transfer</Link>
                    // </div>
                    :
                    
                    item.status === 14 ? ""
                    // <div className="cart__buttons">
                    //         <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti Transfer</Link>
                    // </div>
                    :
                    <div className="cart__buttons">
                            <Link to={url.revisiaplikator(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Revisi</Link>
                    </div>
                    // <div className="cart__buttons">
                    //         <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Detal Pesanan</Link>
                    // </div>}
                        }
                    </td>
                    {/* <td className="cart-table__column " data-title="">
                        {item.status === 1 ? 
                        <div className="cart__buttons">
                            <Link to={url.Pembayaran(item.id)} className="btn nav-bawah-mobile" style={{color:"white"}}>Kirim Bukti</Link>
                        </div> 
                                : item.status === 2 ? "" 
                                :""}

                    </td> */}
                </tr>
            );
        });
    }

    renderCart() {
        // const { cart, cartUpdateQuantities } = this.props;
        const { quantities, payment, cart_id, loadingCO} = this.state;

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--kode">Kode</th>
                                <th className="cart-table__column cart-table__column--tanggal">Tanggal</th>
                                <th className="cart-table__column cart-table__column--bank">Jasa Aplikator</th>
                                <th className="cart-table__column cart-table__column--status">Status</th>
                                <th className="cart-table__column "></th>

                                {/* <th className="cart-table__column cart-table__column--remove" aria-label="Remove" /> */}
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    render1() {
        const { page, orders } = this.state;

        const ordersList = orders.map((order) => (
            <tr key={order.id}>
                <td><Link to={url.detailtransaksiaplikator(item.id)}>{`#${order.transaction_code}`}</Link></td>
                <td>{order.transaction_date}</td>
                <td>{order.bank_name}</td>
                <td>{order.transaction_total}</td>
                <td>{order.active_flag}</td>
            </tr>
        ));

        return (
            <div className="card">
                <Helmet>
                    <title>{`Order History — ${theme.name}`}</title>
                </Helmet>

                <div className="card-header">
                    <h5>Histori</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        {this.state.orders === null ? (
                             <table>
                             <thead>
                                 <tr>
                                     <th>Kode</th>
                                     <th>Tanggal</th>
                                     <th>Bank</th>
                                     <th>Total</th>
                                     <th>Status</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 Data Kosong
                             </tbody>
                         </table>
                        ):(
                            <table>
                            <thead>
                                <tr>
                                    <th>Kode</th>
                                    <th>Tanggal</th>
                                    <th>Bank</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersList}
                            </tbody>
                        </table>
                        )
                        }
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    <Pagination current={page} onPageChange={this.handlePageChange} />
                </div>
            </div>
        );
    }

    render() {
        const { orders } = this.state;
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Keranjang', url: '' },
        ];

        let content;

        if (orders.length>0) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            {/* <div className="block-empty__message">Your shopping cart is empty!</div> */}
                            <div className="block-empty__message">Keranjangmu Kosong, yuk belanja dulu!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">Belanja</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
// console.log(this.state.quantities)
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Pesanan Jasa — ${theme.name}`}</title>
                </Helmet>
                <br></br>
                <PageHeader header="Pesanan Jasa" />
                {content}
            </React.Fragment>
        );
    }
    
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
  });
  
  const mapDispatchToProps = {};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AplikatorPageOrders)