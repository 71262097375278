// react
import axios from 'axios';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

// third-party
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// data stubs
import theme from '../../data/theme';
import { updateAction } from '../../store/auth/authActions';

export default function AccountPageProfile() {
    let history = useHistory()
    const userid = localStorage.user_id
    const [picture, setPicture] = useState(false)
    const [name, setName] = useState('')
    const [gender, setGender] = useState('')
    const {token, update}= useSelector((state) => state.auth)
    console.log(userid);
    // console.log(picture)
    const dispatch= useDispatch()

    useEffect(() => {
        getById()
    }, [])
    

    const getById = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        axios
          .get(
            `${process.env.REACT_APP_HOST_API}/users/get/${userid}`,{headers}
          )
          .then((data) => {
            // setEmail(data.data.response);
            setName(data.data.response);
            setGender(data.data.response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

    const updateHandler = async ()=>{

        // function CreateData() {
        //     let dataItems = [];
        //         savedItems.map((dataItem) => dataItems = [...dataItems, 
        //             { 
        //               item_id: dataItem.item_id, 
        //               code_rak : dataItem.code_rak,
        //               item_name:dataItem.item_name,
        //               qty: dataItem.qty, 
        //               reason:dataItem.reason,
        //               ket:dataItem.ket,
        //             }]);
        //     let data = {
        //       status_d : 3,
        //       warehouse_id : parseInt(warehouse),
        //       username : username,
        //       keterangan: keterangan ,
        //       items : dataItems
        //     };
        //     axios
        //         .post(
        //           `${process.env.REACT_APP_API_BASE_URL}/adjustment/update/${userid}`,
        //           data,
        //           {
        //             headers: {
        //               Authorization: `Bearer ${token}`,
        //             },
        //           }
        //         )
        //         .then(function (response) {
        //           setSuccessAlert(response.data.message);
        //           setTimeout(() => (history.push("/admin/stock-adjustment/page")), 2000);
        //           })
        //           .catch(function (error) {
        //             setGagalAlert(error.response.data.message);
        //           });
        //     }
        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/users/ganti-profil`,
                data:{profil: picture},
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              });
            //   console.log(result.data.response)
              toast.success('Update Success!')
                  dispatch(updateAction(false))
                  history.push("/account/dashboard")
            
        } catch (error) {
            console.log(error);
            toast.error('There was an error updating the picture')
            
        }
    }
    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>
           
            <div className="card-header">
                {/* <h5>Edit Profile</h5> */} 
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/accounts/dashboard">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div>
            </div>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        {/* <div className="form-group">
                            <label htmlFor="profile-first-name">Nama</label>
                            <input
                                id="profile-first-name"
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="profile-last-name">Last Name</label>
                            <input
                                id="profile-last-name"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="profile-email">Email Address</label>
                            <input
                                id="profile-email"
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                            />
                        </div> */}
                        {/* <div className="form-group">
                            <label htmlFor="profile-phone">Phone Number</label>
                            <input
                                id="profile-phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                            />
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="profile-picture">Profile Picture</label>
                            <input
                                id="profile-picture"
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                className="form-control"
                                placeholder="Profile Picture"
                                onChange={(e)=>setPicture(e.target.files[0])}
                            />
                        </div>
                        <div className="form-group mt-5 mb-0">
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
