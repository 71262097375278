// react
import axios from 'axios';
import React, { useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// data stubs
import addresses from '../../data/accountAddresses';
import allOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import { resetAuth } from '../../store/auth/authActions';

export default function AccountPageDashboard() {
    const [address, setAddress] = useState(false)
    const [userData, setUserData] = useState(false)
console.log(address)

    const dispatch = useDispatch();
  const { token, loading } = useSelector((state) => state.auth);

    useEffect(()=>{
        const getUser = async () =>{
          try {
            const result = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_HOST_API}/auth/info`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setUserData(result.data?.response)
          } catch (error) {
            // console.log(error);
            dispatch(resetAuth())
          }
        }
        const getAddress = async()=>{
            const user_id = localStorage.getItem('user_id')
            try {
                const result = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_HOST_API}/address/get/${user_id}`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                //   console.log(result.data?.response)
                  setAddress(result.data.response)
            } catch (error) {
                console.log(error);
                toast.error('There was an error!')
            }
        }
        if(token){
          getUser()
          getAddress()
        }
      },[token])

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`My Account — ${theme.name}`}</title>
            </Helmet>
            <CardHeader className="bg-white border-0 d-md-none d-lg-none d-xl-none">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/accounts">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div>
            </div>
            </CardHeader>
            <div className="dashboard__profile card profile-card">
                <div className="card-body profile-card__body">
                    <div className="profile-card__avatar">
                        <img src={userData.user_image ? userData.user_image :" images/avatars/avatar-3.jpg"} alt="" />
                    </div>
                    <div className="profile-card__name">{userData.name}</div>
                    <div className="profile-card__email">{userData.email}</div>
                    <div className="profile-card__edit">
                        <Link to="profile" className="btn btn-secondary btn-sm">Edit Profile</Link>  <Link to="/account/addresses" className="btn btn-secondary btn-sm">Edit Alamat</Link>
                    </div>
                </div>
            </div>
            <div className="dashboard__address card address-card address-card--featured">
                {address && <div className="address-card__badge">Default Address</div>}
                <div className="address-card__body">
                    <div className="address-card__name">{`${userData.name}`}</div>
                    <div className="address-card__row">
                        {address.address}
                        
                        ,
                        { address.city}
                       ,
                        { address.province}
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{address.phone}</div>
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{userData.email}</div>
                    </div>
                    {/* <div className="address-card__footer">
                        <Link to="/account/addresses/">Edit Alamat</Link>
                    </div> */}
                </div>
            </div>
            {/* <div className="dashboard__orders card">
                <div className="card-header">
                    <h5>Recent Orders</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
