// react
import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Row,
    Col,
    CardBody,
    CardFooter,
    CardHeader ,
    FormGroup,
    Label,
    Input,
    Container,
  } from "reactstrap";
// third-party
import { Helmet } from 'react-helmet-async';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { url } from '../../services/utils';
import { getAddressMap } from '../../api/getMapAddress';
import { Maps } from '../Maps';
import { useDispatch,useSelector } from 'react-redux';
import { updateAction } from '../../store/auth/authActions';
import { Link } from 'react-router-dom';
// data stubs
import theme from '../../data/theme';

export default function AccountAddressDua(props) {
    const { addressid} = props;
    let History = useHistory();
    const   dispatch= useDispatch()
    const [provinceData, setProvinceData] = useState('');
    const [cityData, setCityData] = useState([]);
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [citys, setCitys] = useState([]);
    const [province, setProvince] = useState("");
    const [provinces, setProvinces] = useState([]);
    const [loading, setloading] = useState(false);
    const [long, setLong] = useState("");
    const [lat, setLat] = useState("");
    const [selectAddress, setSelectAddress] = useState("");
    const [loadMap, setLoadMap] = useState();
    const [loadMaps, setLoadMaps] = useState();
    const successCallback = (position) => {
        // console.log(position);
      };
    
      const errorCallback = (error) => {
        // console.log(error);
      };
    
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    const [bodyNew, setBodyNew] = useState({
        address:"",
        city:"",
        province:"",
        phone:""
    });
    const [bodyUpdate, setBodyUpdate] = useState({
        address:"",
        active_flag:1,
        city:"",
        province:"",
        phone:""
    });
    const {addressId} = useParams()
    // const user_id = localStorage.getItem('user_id');
    // console.log(addressId)
    const {token} = useSelector((state) => state.auth)
    // console.log(bodyUpdate)

    const defaultProps = {
        center: {
          lat: lat ? lat : -7.3106144,
          lng: long ? long : 112.6841525
        },
        zoom: 14
      };
    
      const onClickMap = async (e) => {
        if (e) {
          setLong(e.lng)
          setLat(e.lat)
          const data = await getAddressMap(e.lat, e.lng);
    
          if (data.results.length > 2) {
            setSelectAddress(data.results[2].formatted_address)
            document.getElementById('pac-input').value = data.results[2].formatted_address;
            loadMapClick(loadMap, loadMaps);
          }
        }
      }
    
      const apiIsLoaded = (map, maps, center) => {
        setLoadMap(map);
        setLoadMaps(maps);
        loadMapClick(map, maps);
      };
    
      const loadMapClick = (map, maps, radius) => {
        const circle = new maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.3,
          map,
          center: defaultProps.center,
          radius: radius
        });
        console.log("radius ", radius);
      }

    useEffect(() => {
      getbyId()
      getProvinsi()
      getCity()
    }, [])
    

    const getbyId = async ()=>{
        try {
            const result = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_HOST_API}/address/get/${addressid}`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            //   setBanks(result.data.response)
              getProvinsi(result.data.response.province)
              getCity(result.data.response.province)
              setProvince(result.data.response.province)
              setCity(result.data.response.city)
              setPhone(result.data.response.phone)
              setLong(result.data.response.longitude)
              setLat(result.data.response.latitude)
              setSelectAddress(result.data.response.address)
        } catch (error) {
            // console.log(error);
            // toast.error('There was an error!');
        }
    }

    const getProvinsi = async (id)=>{
        try {
            const result = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_HOST_API}/province/list`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              setProvinces(result.data.response)
            //   setProvince(id)
            //   getCity(id)
        } catch (error) {
            // console.log(error);
            // toast.error('There was an error!');
        }
    }

    const getCity = async (id)=>{
        try {
            const result = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_HOST_API}/city/listing?province_id=${id}`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              setCitys(result.data.response)
            //   setCity(id)
        } catch (error) {
            // console.log(error);
            // toast.error('There was an error!');
        }
    }

    const   updateHandler = async ()=>{
        const id = localStorage.getItem('user_id');
        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/address/kedua`,
                data:{
                    latitude : lat,
                     longitude : long, 
                     address : selectAddress, 
                     city : city, 
                     province : province, 
                     phone : phone,
                    // rv_state : centang === "" ? 3 : 5 ,
                    // account_number : bank
                    // warehouse_id: toko,
                    // code_aplikator : kode,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('Tambah Alamat Sukses')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  History.push("/account/addresses")
            
        } catch (error) {
            toast.error('Tambah Alamat Gagal')
            
        }
    }

    // useEffect(() => {
    //     const getProvince = async (id) => {
    //       try {
    //         const result = await axios.get(
    //           `${process.env.REACT_APP_HOST_API}/city/listing?province_id=${id}`
    //         );
    //         // console.table(result.data.response);
    //         setProvinceData(result.data.response);
    //       } catch (error) {
    //         console.log(error);
    //         let errorMsg =
    //           error.code === 'ERR_NETWORK'
    //             ? 'network error'
    //             : error.response
    //             ? error.response.data.message || error.response.data.error
    //             : error;
    //         toast.error(errorMsg);
    //       }
    //     };
    //     const city = async () => {
    //       try {
    //         const result = await axios.get(
    //           `${process.env.REACT_APP_HOST_API}/city/listing?province_id=${bodyUpdate.province}`
    //         );
    //         // console.table(result.data.response);
    //         setCityData(result.data.response);
    //       } catch (error) {
    //         console.log(error);
    //         let errorMsg =
    //           error.code === 'ERR_NETWORK'
    //             ? 'network error'
    //             : error.response
    //             ? error.response.data.message || error.response.data.error
    //             : error;
    //         toast.error(errorMsg);
    //       }
    //     };
    //     getProvince();
    //     if (bodyUpdate.province) {
    //       city();
    //     }
    //   }, [bodyUpdate.province]);
    return (
        <div className="card">
            <Helmet>
                <title>{`Edit Address — ${theme.name}`}</title>
            </Helmet>
            <div className="container">
            <Card className="shadow">
                <CardHeader>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ textAlign: "left" }}>
                        <Link className="btn btn-link" to="/account/addresses">
                            <i className="fa fa-arrow-circle-left fa-2x" /> 
                        </Link>
                    </div>
                    {/* <div style={{ textAlign: "left" }}>
                        <h3>Edit Lokasi</h3>
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <h3></h3>
                    </div> */}
                  </div>
                </CardHeader>
                <CardBody>
                  <Row md="16">
                    <Col md="4">
                      <FormGroup row>
                       
                        <Col sm={9}>
                          <Input
                          hidden
                            // disabled
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            pattern='[0-9]{0,5}'
                            name="Phone"
                            placeholder="Masukan Latitude"
                            value={lat}
                            onChange={(e) => {
                              setLat(e.target.value);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup row>
                      
                        <Col sm={9}>
                          <Input
                          hidden
                            // disabled
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            pattern='[0-9]{0,5}'
                            name="Phone"
                            placeholder="Masukan Longitude"
                            value={long}
                            onChange={(e) => {
                              setLong(e.target.value);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Maps
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    onClickMap={onClickMap}
                    apiIsLoaded={apiIsLoaded}
                    isSearch={true}
                    long={long}
                    lat={lat}
                    address={selectAddress}
                    setLong={setLong}
                    setLat={setLat}
                  />
               
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">
                    <div className="form-group">
                            <label htmlFor="checkout-street-address">Alamat</label>
                            <input
                                type="text"
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Alamat"
                                value={selectAddress}
                                onChange={(e) => setSelectAddress(e.target.value)}
                            />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="checkout-country">Provinsi</label>
                            <select id="checkout-country" value={province} className="form-control form-control-select2" 
                            onChange={ (e) => {
                            setProvince(e.target.value)
                            getCity(e.target.value)}
                        }
                            >
                            <option value="">Pilih Provinsi</option>
                            {
                            provinces.map((province) => (
                            <option key={province.id} value={province.id}>
                              {province.name}
                            </option>
                          ))}
                            </select>
                        </div>
                        <div className='form-group'>
                      <label htmlFor='city' >Kota</label>
                      <select id="city"
                        className='form-control'
                        value={city}
                        onChange={ (e) => {
                            setCity(e.target.value)}
                        }
                      >
                            <option value="">Pilih Kota</option>

                        {citys.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    </div>
                        
                        <div className="form-group">
                                <label htmlFor="checkout-phone">Phone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-phone"
                                    placeholder="Phone"
                                    value={phone}
                                    onChange={(e)=> setPhone(e.target.value)}
                                />
                            </div>
                        

                        

                        <div className="form-group mt-3 mb-0">
                            {/* <button className={classNames("btn btn-primary",{'btn-loading': loading})} type="button" onClick={addressId === 'new-second'? newSecondAddress: addressId === 'new-third' ? newThirdAddress :updateAddress}>Save</button>
                             */}
                             <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': loading})} onClick={updateHandler}>Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
            </CardBody>
            </Card>
            </div>
        </div>
    );
}
