// react
import React, { useState, useEffect, useMemo } from "react";

// third-party
import { Helmet } from "react-helmet-async";
// import { FloatingWhatsApp } from 'react-floating-whatsapp-button';
// import FloatingWhatsApp from "react-floating-whatsapp";
import "react-floating-whatsapp-button/dist/index.css";
// import css from '../../scss/blocks/style-module.scss'

// application
import shopApi from "../../api/shop";
import { useDeferredData, useProductColumns, useProductTabs } from "../../services/hooks";

// blocks
// import BlockBanner from "../blocks/BlockBanner";
// import BlockBannerDown from "../blocks/BlockBannerDown";
import BlockBrands from "../blocks/BlockBrands";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockSlideShowVideo from "../blocks/BlockSlideShowVideo";
import BlockProducts from "../blocks/BlockProducts";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockProductsPromo from "../blocks/BlockProductsPromo";
import BlockSlideShow from "../blocks/BlockSlideShow";
import BlockKategori from "../blocks/BlockKategory";
import BlockMenu from "../blocks/BlockMenu";


// data stubs
import theme from "../../data/theme";
// import BlockCategory from "../blocks/BlockCategory";
import BlockBannerSlideShow1 from "../blocks/BlockBannerSlideShow1";
import BlockBannerSlideShow2 from "../blocks/BlockBannerSlideShow2";

function HomePageOne(props) {
	const {waktu} = props;
	

	// console.log(process.env.PUBLIC_URL);
	/**
	 * Featured products.
	 */
	const featuredProducts = useProductTabs(
		useMemo(() => [{ id: 1, categorySlug: undefined }], []),
		(tab) => shopApi.getPopularProducts({ limit: 20 })
	);

	// const featuredProducts = useProductTabs(() => shopApi.getPopularProducts({ limit: 20 })
	// );

	/**
	 * Bestsellers.
	 */
	const bestsellers = useDeferredData(() => shopApi.getPopularProducts({ limit: 3 }), []);

	/**
	 * Latest products.
	 */
	const latestProducts = useProductTabs(
		useMemo(
			() => [
				{ id: 1, name: "All", categorySlug: undefined },
				{ id: 2, name: "Power Tools", categorySlug: "power-tools" },
				{ id: 3, name: "Hand Tools", categorySlug: "hand-tools" },
				{ id: 4, name: "Plumbing", categorySlug: "plumbing" },
			],
			[]
		),
		(tab) => shopApi.getLatestProducts({ limit: 8, category: tab.categorySlug })
	);

	/**
	 * Product columns.
	 */
	const columns = useProductColumns(
		useMemo(
			() => [
				{
					title: "Top Rated Products",
					source: () => shopApi.getTopRatedProducts({ limit: 3 }),
				},
				{
					title: "Special Offers",
					source: () => shopApi.getDiscountedProducts({ limit: 3 }),
				},
				{
					title: "Bestsellers",
					source: () => shopApi.getBestsellerdown({ limit: 3 }),
				},
			],
			[]
		)
	);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${theme.name}`}</title>
			</Helmet>

			{useMemo(
				() => (
					<BlockSlideShowVideo withDepartments />
				),
				[]
			)}
			{useMemo(
				() => (
					<BlockMenu />
				),
				[]
			)}

			{/* {useMemo(
				() => (
					<BlockCategory />
				),
				[]
			)} */}

			{/* {useMemo(
				() => (
					<BlockKategori 
					// rows={2}
					/>
				),
				[]
			)} */}
			
			{useMemo(
				() => (
					<BlockSlideShow />
				),
				[]
			)}

			{useMemo(
				() => (
					<BlockBrands />
				),
				[]
			)}

			{useMemo(
				() => (
					<BlockProductsPromo
						title="Flash Sale"
						layout="grid-5"
						rows={2}
						waktu={waktu}
						products={featuredProducts.data}
						loading={featuredProducts.isLoading}
						// groups={featuredProducts.tabs}
						onGroupClick={featuredProducts.handleTabChange}
					/>
				),
				[featuredProducts]
			)}

			{useMemo(
				() => (
					<BlockKategori 
					// rows={2}
					/>
				),
				[]
			)}

			{/* {useMemo(
				() => (
					<BlockBanner />
				),
				[]
			)} */}

{useMemo(
				() => (
					<BlockBannerSlideShow1 />
				),
				[]
			)}

			{useMemo(
				() => (
					<BlockProducts title="Best Seller" layout="large-first" featuredProduct={bestsellers.data[0]} products={bestsellers.data.slice(1, 7)} />
				),
				[bestsellers.data]
			)}

			{/* {useMemo(
				() => (
					<BlockBannerDown />
				),
				[]
			)} */}
			{useMemo(
				() => (
					<BlockBannerSlideShow2 />
				),
				[]
			)}

			{useMemo(
				() => (
					<BlockProductsCarousel
						title="Semua Produk"
						// layout="horizontal"
						layout="grid-4"
						rows={2}
						products={featuredProducts.data}
						loading={featuredProducts.isLoading}
						// groups={featuredProducts.tabs}
						onGroupClick={featuredProducts.handleTabChange}
					/>
				),
				[featuredProducts]
			)}

			{useMemo(
				() => (
					<BlockFeatures />
				),
				[]
			)}

			{/* <nav className="navbar navbar-dark navbar-expand d-lg-block d-none">
				<FloatingWhatsApp
					phoneNumber="+6281217853300"
					accountName="Hokky Bangunan"
					statusMessage="Online 1 menit yang lalu"
					chatMessage="Selamat datang di Hokky Bangunan. Ada yang bisa saya Bantu ?"
					avatar="https://hbs-sby.s3.ap-southeast-1.amazonaws.com/logo_hokky.png"
					allowClickAway
				/>
			</nav> */}
		</React.Fragment>
	);
}

export default HomePageOne;
