// react
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    // ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    // DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
// third-party
import { Helmet } from 'react-helmet-async';
import PageHeader from '../shared/PageHeader';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

import { updateAction } from '../../store/auth/authActions';
// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';

export default function AplikatorInputemailakses(props) {
    let history = useHistory()
    const   { emailakses} = props;
    const   {token, update}= useSelector((state) => state.auth)
    const   [isLoading, setIsLoading] = useState(true);
    const   [orderInfo, setInfo] = useState(false)
    const   [kode, setKode] = useState("")
    const   [alamat, setAlamat] = useState("")
    const   [alamats, setAlamats] = useState([])
    const   [tanggal, setTanggal] = useState("")
    const   [Total, setTotal] = useState("")
    const   [email, setEmail] = useState("")
    const   [address, setAddress] = useState("")
    const   [kurir, setKurir] = useState("")
    const   [kurirs, setKurirs] = useState([])
    const   dispatch= useDispatch()
    const   updateHandler = async ()=>{

        try {
            dispatch(updateAction(true))
            const result = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_HOST_API}/order-aplikator/update-akses-email/${emailakses}`,
                data:{
                    email_akses : email,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                },
              });
            //   console.log(result.data.response)
              toast.success('Tambah Email Sukses')
                  dispatch(updateAction(false))
                  localStorage.setItem('CO-INFO', JSON.stringify(result.data.response));
                  history.push("/aplikator/order-jasa")
            
        } catch (error) {
            console.log(error);
            toast.error('Tambah Email Gagal')
            
        }
    }

    useEffect(() => {
        // getUser()
        // getKurir()
        // getAddress()
    }, [])
    

    const getUser = () =>{
        axios({
         method: "GET",
         url: `${process.env.REACT_APP_HOST_API}/auth/info`,
         headers: {
           Authorization: `Bearer ${token}`,
         },
       })
       .then(data => {
        setAddress(data.data.response.address_id)
         // setUserData(data.data.response);
         // setUsername(data.data.response.username)
        //  getUserCok(data.data.response.username)
     })
       .catch(function (error) {
       })
       
   }

   const getKurir = () =>{
    axios({
     method: "GET",
     url: `${process.env.REACT_APP_HOST_API}/kurir/aktif`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
    setKurirs(data.data.response)
     // setUserData(data.data.response);
     // setUsername(data.data.response.username)
    //  getUserCok(data.data.response.username)
 })
   .catch(function (error) {
   })
   
}

const getAddress = () =>{
    axios({
     method: "GET",
     url: `${process.env.REACT_APP_HOST_API}/address/list`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
    setAlamats(data.data.response)
     // setUserData(data.data.response);
     // setUsername(data.data.response.username)
    //  getUserCok(data.data.response.username)
 })
   .catch(function (error) {
   })
   
}

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

    const items = order.items.map((item) => {
        const options = (item.options || []).map((option) => (
            <li className="order-list__options-item">
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));

        useEffect(() => {
            // let canceled = false;
    
            setIsLoading(true);

            const getUserId = (id) =>{
                axios({
                 method: "get",
                 url: `${process.env.REACT_APP_HOST_API}/order-aplikator/get/${emailakses}`,
                 headers: {
                   Authorization: `Bearer ${token}`,
                 },
               })
               .then(data => {
                setKode(data.data.response)
             })
               .catch(function (error) {
               })
            }
    
            getUserId()
        }, [emailakses, setIsLoading]);

    });

    const additionalLines = order.additionalLines.map((line) => (
        <tr>
            <th className="order-list__column-label" colSpan="3">{line.label}</th>
            <td className="order-list__column-total"><Currency value={line.total} /></td>
        </tr>
    ));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Masukan Email — ${theme.name}`}</title>
            </Helmet>
            
            <div className="card">
            <div className="container">
            <div className="card-header">
                {/* <h5>Edit Profile</h5> */} 
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                    <Link className="btn btn-link" to="/aplikator/order-jasa">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                    </Link>
                </div>
                <h3><center></center></h3>

                <div style={{ textAlign: 'right' }}>
                    <h3></h3>
                </div>
            </div>
            </div>
            {/* <div className="card-divider" /> */}
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Email Akses</label>
                            <Input
                                id="profile-first-name"
                                type="text"
                                row = "20"
                                className="form-control"
                                placeholder="Masukan Email Akses"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div> 
                       
                        {/* <div className="form-group">
                            {kode === "" ? "" : <label htmlFor="profile-last-name">Estimasi harga Jasa adalah {formatRupiah(harga)} - {formatRupiah(Estimasi)}</label>}
                        </div> */}
                        {/* <div className="form-group mt-5 mb-0"> */}
                        <div className='cart__buttons'>
                            <button type="button" className={classNames("btn btn-primary",{ 'btn-loading': update})} onClick={updateHandler}>Kirim</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    );
}
